import { useState } from 'react';
import { animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import { Contact, Introduce2, SocialContacts } from '../styles';
import { socialContacts } from '../__mocks/scientistMintMockValues';
import { useSpringStyles } from './useSpringStyles';

export const ScientistSecondSession = () => {
  const [introView2, setIntroView2] = useState(false);
  const { stylesIntro2, stylesTechCityCover, stylesTechCityCoverFloating } = useSpringStyles(
    introView2
  );

  return (
    <>
      <Waypoint onEnter={() => setIntroView2(true)} onLeave={() => setIntroView2(false)}>
        <animated.div style={stylesIntro2}>
          <Introduce2>
            <animated.img
              style={{ ...stylesTechCityCover, ...stylesTechCityCoverFloating }}
              className="preview_tech_city__cover"
              src="/images/SimbiotesRockAbyssClipped.webp"
              alt="Simbiotes Tech City"
            />
          </Introduce2>
        </animated.div>
      </Waypoint>
    </>
  );
};

import { styled } from '@mui/material/styles';

export const DashboardModuleCardWrapper = styled('div')`
  .MuiTypography-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card__wrapper {
    width: ${(props) => (props.large ? '100%' : '248px')};
    background: #17181c;
    border: 1px solid #202228;
    border-radius: 14px;
    padding: 20px;
    .card__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &_name {
        max-width: 50%;
        width: 50%;
        .module_name {
          width: 100%;
          position: relative;
          text-overflow: clip;
          &:after {
            content: '';
            width: 100%;
            height: 24px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(to right, transparent 80px, #17181c);
          }
          &:hover {
            &:after {
              display: none;
            }
          }
        }
      }
      &_price {
        max-width: 50%;
        width: 50%;
        .module_id_text {
          justify-content: right;
          height: 23px;
          .module_id_label {
            height: 23px;
            display: flex;
            align-items: center;
            .edit_price__icon {
              margin-right: 7px;
              cursor: pointer;
            }
          }
        }
        .module_name {
          font-weight: 700;
          width: 100%;
          text-align: right;
        }
      }
    }
  }
  .cover__img {
    width: 100%;
    height: 129px;
    background: #000;
    border-radius: 14px;
    overflow: hidden;

    img {
      width: 129px;
      height: 129px;
    }
  }
  .module_id_text {
    margin-top: 20px;
    font-size: 13px;
    justify-content: left;
    width: 100%;
  }
  .module_id_label {
    color: #ffffff66;
  }
  .module_id {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 4px;
    padding: 4px 4px;
    margin-left: 8px;
    font-size: 13px;
    color: #fff;
  }
  .module_name {
    margin-top: 4px;
    font-size: 16px;
    justify-content: left;
    width: 100%;
    display: inline-block;
  }
  .abilities {
    margin-top: 3px;
    margin-bottom: 15px;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.4);
    justify-content: left;
    width: 100%;
    display: inline-block;
    .abilities_rarity {
      text-transform: capitalize;
      color: #7ebaff;
    }
  }
  .describe {
    color: #b9b9b9;
    white-space: normal;
    text-align: left;
    width: 100%;
    min-height: 40px;
    height: 40px;
    line-height: 13px;
    font-size: 11px;
    align-items: stretch;
    justify-content: left;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .line {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    margin-top: 17px;
    width: 100%;
    height: 1px;
  }
  .group__buttons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    &_single_item {
      justify-content: center;
    }
  }
`;

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LabTabStyled = styled(Button)`
  margin: 20px 0;
  width: 160px;
  height: 48px;
  border-width: 1.5px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  font-weight: 700;
  margin-right: 8%;
  &:last-of-type {
    margin-right: 0;
  }
  cursor: pointer;
  &:hover {
    border-color: #fff;
  }
  &:active {
    border-color: #fff;
  }
  &:disabled {
    border-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.2);
  }
  &.active {
    border-color: #09ebf6;
    &:hover {
      border-color: #09ebf6;
    }
    &:active {
      border-color: #09ebf6;
    }
    &:disabled {
      border-color: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.2);
    }
  }
`;

import { styled } from '@mui/material/styles';
import Popover from '@mui/material/Popover';

export const FireItUpTypography = styled('div')`
  padding: 2px 10px;
  font-size: 12px;
  background: transparent;
  font-family: 'Anybody';
`;

export const FireItUpItem = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 10px;
  .value {
    margin-left: auto;
    background: -webkit-linear-gradient(135deg, #ff81ff 0%, #1baae2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }
`;

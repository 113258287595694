/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
import {
  ConfirmSwitchNetworkModal,
  ErrorModal,
  GlobalLoadingModal,
  Header,
  InfoModal,
  LoginModal,
  NeonLightButton,
} from '@components/index';
import ChangeWalletModal from '@components/Modals/ChangeWalletModal';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  closeChangeWalletModal,
  closeErrorModal,
  closeLoginModal,
  openChangeWalletModal,
  openErrorModal,
  openLoginModal,
  openSwitchNetworkModal,
  setErrorMessage,
} from '@providers/ModalsProvider';
import { connectorByConstructorName, connectorsByName, supportedChainIds } from '@utils/connectors';
import useConnectors, { defaultConnectedWalletStatus } from '@utils/useConnectors';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { delay } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ITEM_NAME_STORAGE } from './shared';
import theme from './shared/theme';
import { useStore } from './store';

const disappearMenuPath = ['/scientist-mint', '/metacell-mint'];

function App() {
  const { account, chainId } = useWeb3React();
  const [, setShowSignIn] = useState(false);
  const [, setShowUserSettings] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { setUser } = useStore();
  const dispatch = useDispatch();

  const {
    loginModalStatus,
    changeWalletModalStatus,
    errorModalStatus,
    errorMessage,
    switchNetWorkStatus,
    connector: switchNetWorkConnector,
    connectorName: switchNetWorkConnectorName,
    loadingModalStatus,
    loadingMessage,
  } = useSelector((state) => state.modals);
  const location = useLocation();

  const { onConnectMetamaskWallet, onConnectWallet, disconnect, onConnectBitkeep } = useConnectors(
    (msg) => {
      dispatch(setErrorMessage(msg));
      dispatch(openErrorModal());
    },
    (status) => {
      // eslint-disable-next-line no-unused-expressions
      status ? dispatch(openChangeWalletModal()) : dispatch(closeChangeWalletModal());
    }
  );

  const hasConnector = useMemo(() => {
    return localStorage?.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)
      ? Object.values(
          JSON.parse(localStorage.getItem(ITEM_NAME_STORAGE.connectedWalletStatus))
        ).includes(true)
      : false;
  }, []);

  const injected = new InjectedConnector({
    supportedChainIds,
  });

  const isMetaMaskUnlocked = async () => {
    let isUnlocked = false;
    if (window.ethereum?.providers) {
      const metamaskProvider = window.ethereum.providers.find((provider) => provider?.isMetaMask);
      isUnlocked = await metamaskProvider._metamask.isUnlocked();
    } else if (window.ethereum?.isMetaMask) {
      isUnlocked = await window.ethereum._metamask.isUnlocked();
    } else isUnlocked = false;
    return isUnlocked;
  };

  const selectedAddressMetamask = () => {
    let selectedAddress = null;
    if (window.ethereum?.providers) {
      const metamaskProvider = window.ethereum.providers.find((provider) => provider?.isMetaMask);
      selectedAddress = metamaskProvider?.selectedAddress;
    } else {
      selectedAddress = window.ethereum?.selectedAddress;
    }
    return selectedAddress;
  };

  // useEffect(() => {
  //   if (account) signIn(library, account, setShowSignIn, setUser, setShowInfoModal);
  //   return () => {
  //     localStorage.removeItem('connectionError');
  //   };
  // }, [account, library, setUser]);

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      console.log('Loading Connect Wallet');

      const connectedStatus =
        JSON.parse(localStorage?.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)) ||
        defaultConnectedWalletStatus;

      const isConnected = Object.values(connectedStatus).includes(true);

      if (!isConnected) return;
      if (window.ethereum) {
        const isUnlocked = await isMetaMaskUnlocked();
        if (connectedStatus?.Injected && isUnlocked && selectedAddressMetamask()) {
          onConnectMetamaskWallet();
          return;
        }
      }
      if (connectedStatus?.WalletConnect) {
        onConnectWallet(connectorsByName.WalletConnect, 'WalletConnect');
        return;
      }
      if (connectedStatus?.WalletLink) {
        onConnectWallet(connectorsByName.WalletLink, 'WalletLink');
      }
      if (connectedStatus.Bitkeep) {
        onConnectBitkeep();
      }
    };

    connectWalletOnPageLoad();

    window.addEventListener('storage', (e) => {
      delay(connectWalletOnPageLoad, 1000);
    });

    return () => {
      window.removeEventListener('storage', () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showAccountPopover, setShowAccountPopover] = useState(false);
  const accountAnchorEl = useRef(null);

  const onAccountClick = (event) => {
    if (account) {
      setShowAccountPopover(true);
      accountAnchorEl.current = event.currentTarget;
    } else {
      dispatch(openLoginModal());
    }
  };

  const onLogoutClick = () => {
    disconnect().then(() => {
      setShowAccountPopover(false);
    });
  };

  const onHandleDisconnectWallet = () => {
    dispatch(closeChangeWalletModal());
    return disconnect();
  };

  const backgroundImage = useMemo(() => {
    let path = '';
    if (location.pathname.includes('/the-field')) {
      path = '/images/TheFieldBackground.webp';
    } else if (location.pathname.includes('/lab')) {
      path = '/images/TheLabBackground.webp';
    } else path = '/images/MyCollectionBackground.webp';

    return path;
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      if (!account && (!hasConnector || !window.ethereum?.selectedAddress)) {
        if (!switchNetWorkStatus) dispatch(openLoginModal());
      }
    }, 500);
  }, [account, dispatch, hasConnector, switchNetWorkStatus]);

  useEffect(() => {
    if (switchNetWorkStatus) dispatch(closeLoginModal());
  }, [dispatch, switchNetWorkStatus]);

  useEffect(() => {
    if (account && !supportedChainIds.includes(chainId)) {
      setTimeout(() => {
        if (localStorage?.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)) {
          const connectedWalletStatus = JSON.parse(
            localStorage.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)
          );
          const entries = Object.entries(connectedWalletStatus);
          let connectorName = '';
          entries.forEach((item) => {
            if (item[1]) connectorName = item[0];
          });
          const currConnector = connectorByConstructorName[connectorName].connector;
          const currConnectorName = connectorByConstructorName[connectorName].connectorName;
          dispatch(
            openSwitchNetworkModal({ connector: currConnector, connectorName: currConnectorName })
          );
        }
      }, 300);
    }
  }, [account, chainId, dispatch]);

  // useEffect(() => {
  //   if (localStorage?.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)) {
  //     const connectedWalletStatus = JSON.parse(
  //       localStorage.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)
  //     );
  //     const entries = Object.entries(connectedWalletStatus);
  //     console.log('item', connectedWalletStatus);
  //     let connectorName = '';
  //     entries.forEach((item) => {
  //       if (item[1]) connectorName = item[0];
  //     });
  //     dispatch(setWalletName(connectorName));
  //     console.log('app2', connectorName);
  //     if (connectorName) dispatch(setConnector(connectorsByName[connectorName]));
  //   }
  //   localStorage.setItem(ITEM_NAME_STORAGE.isLoadingConnect, undefined);
  // }, [account, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster
        toastOptions={{
          className: '',
          style: {
            padding: '16px',
            color: '#713200',
            background: 'rgba(0, 0, 0, 0.35)',
          },
        }}
      />
      <ConfirmSwitchNetworkModal
        open={switchNetWorkStatus}
        connector={switchNetWorkConnector}
        connectorName={switchNetWorkConnectorName}
      />
      <Box
        // id="widget"
        sx={{
          background:
            location.pathname === '/lab'
              ? 'transparent'
              : `${
                  location.pathname !== '/dashboard' && location.pathname !== '/the-field'
                    ? 'linear-gradient(180deg, rgba(12, 12, 14, 0) 0%, rgba(12, 12, 14, 0.48) 49.87%, rgba(12, 12, 14, 0.8) 100%), '
                    : ''
                } url("${backgroundImage}") center / cover no-repeat`,
          minHeight: '100vh',
          pb: disappearMenuPath.includes(location.pathname) ? '0' : '20px',
        }}>
        {location.pathname === '/lab' && (
          <>
            <video
              autoPlay
              muted
              loop
              style={{
                position: 'fixed',
                right: 0,
                minWidth: '100%',
                bottom: 0,
                height: '100vh',
                zIndex: -1,
              }}>
              <source
                src={`${process.env.REACT_APP_RESOURCE}/videos/the-lab-bg.mp4`}
                type="video/mp4"
              />
            </video>
          </>
        )}
        {!disappearMenuPath.includes(location.pathname) ? (
          <Header
            path={location.pathname}
            onAccountClick={onAccountClick}
            showAccountPopover={showAccountPopover}
            setShowAccountPopover={setShowAccountPopover}
            onLogoutClick={onLogoutClick}
            setShowUserSettings={setShowUserSettings}
            setShowSignIn={setShowSignIn}
            setShowInfoModal={setShowInfoModal}
          />
        ) : null}
        {!account ? (
          <>
            {disappearMenuPath.includes(location.pathname) ? (
              <>
                <Outlet />
                <LoginModal
                  open={loginModalStatus}
                  onCloseClick={() => dispatch(closeLoginModal())}
                  injected={injected}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    marginLeft: '50vw',
                    marginTop: '45vh',
                    transform: 'translate(-50%, -50%)',
                    top: 0,
                    left: 0,
                  }}>
                  <NeonLightButton handleClick={() => dispatch(openLoginModal())} />
                </div>

                <LoginModal
                  open={loginModalStatus}
                  onCloseClick={() => dispatch(closeLoginModal())}
                  injected={injected}
                />
              </>
            )}
          </>
        ) : (
          <Outlet />
        )}
        {/* <UserSettingsModal
            open={showUserSettings}
            setShowUserSettings={setShowUserSettings}
            account={account}
          />
          <EmailConfirmModal
            open={showEmailConfirm}
            setShowEmailConfirm={setShowEmailConfirm}
            account={account}
          /> */}
        {/* {disappearMenuPath.includes(location.pathname) ? null : (
            <NetworkSwitchModal
              open={
                Boolean(window.ethereum) &&
                Boolean(window.ethereum.selectedAddress) &&
                !supportedChainIds.includes(chainId)
              }
            />
          )} */}

        <InfoModal
          open={showInfoModal}
          setShowModal={setShowInfoModal}
          textContent={{
            title: 'Error!',
            message:
              'Cannot connect to game server. You can try to reconnect in your account pop-up.',
          }}
        />
        {false && (
          <ChangeWalletModal
            open={changeWalletModalStatus}
            onCloseClick={() => dispatch(closeChangeWalletModal())}
            connectMetamask={onConnectMetamaskWallet}
            onConnectBitkeep={onConnectBitkeep}
            connectCoinbase={() => {
              onConnectWallet(connectorsByName.WalletLink, 'WalletLink');
            }}
            connectWalletConnect={() => {
              onConnectWallet(connectorsByName.WalletConnect, 'WalletConnect');
            }}
            disConnectWallet={onHandleDisconnectWallet}
          />
        )}
        <ErrorModal
          open={errorModalStatus}
          setShowErrorModal={() => dispatch(closeErrorModal())}
          message={errorMessage}
        />

        <GlobalLoadingModal open={loadingModalStatus} text={loadingMessage} />
      </Box>
    </ThemeProvider>
  );
}

export default App;

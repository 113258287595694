import { useContracts } from '@providers/index';
import { last as _last } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export default function useUserScientist(account = '', ...deps) {
  const { Scientist } = useContracts();
  const [scientistId, setScientistId] = useState({
    scientistId: null,
    loading: true,
  });

  const getUserScientistId = useCallback(async (account) => {
    try {
      setScientistId({ scientistId: null, loading: true });
      const [scientistIds] = await Promise.all([Scientist.getUserMetascientistsIndexes(account)]);
      setScientistId({ scientistId: _last(scientistIds)?.toString() || null, loading: false });
      return [...scientistIds].map((o) => o?.toString());
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error', err);
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getScientistInfo = async () => {
      await getUserScientistId(account);
    };
    getScientistInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, getUserScientistId, ...deps]);

  return { ...scientistId, getUserScientistId };
}

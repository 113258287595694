import { useNavigate } from 'react-router-dom';
import {
  HeaderWrapper,
  Logo,
  LogoWrapper,
  ChooseCoinWrapper,
  TextMenuStyled,
  LogoContain,
  IconSwitchWrapper,
  ColWrapper,
} from './styles';
import AccountCard from '../AccountCard';
import { Account, Admin } from '../Icons';
import ListMenu from './ListMenu';

const HeaderDesktop = (props) => {
  const navigate = useNavigate();

  const onClickLogo = () => {
    navigate('/');
  };

  return (
    <HeaderWrapper>
      <ColWrapper className="left-menu">
        <LogoWrapper onClick={onClickLogo}>
          {/* <Logo src="/images/logo.svg" /> */}
          <LogoContain>
            <img src="/images/logo.png" alt="logo" />
          </LogoContain>
        </LogoWrapper>
      </ColWrapper>
      <ColWrapper className="right-menu">
        <ListMenu />
      </ColWrapper>
    </HeaderWrapper>
  );
};
export default HeaderDesktop;

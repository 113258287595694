import { useSpring, useChain, useSpringRef } from 'react-spring';

export const useSpringStyles = (introView3) => {
  const stylesIntro3 = useSpring({
    to: { opacity: introView3 ? 1 : 0 },
    from: { opacity: 0 },
    config: { duration: introView3 ? 1500 : 1 },
  });

  const stylesSmokeLeft = useSpring({
    to: {
      transform: `translateX(${introView3 ? 40 : 0}vw) scale(1.1)`,
      opacity: introView3 ? 0 : 1,
    },
    from: {
      transform: `translateX(${introView3 ? 0 : -40}vw) scale(1.1)`,
      opacity: introView3 ? 1 : 0,
    },
    config: introView3
      ? {
          duration: 4000,
        }
      : { duration: 0.1 },
  });

  const stylesSmokeRight = useSpring({
    to: {
      transform: `translateX(${introView3 ? -40 : 0}vw) scale(1.1)`,
      opacity: introView3 ? 0 : 1,
    },
    from: {
      transform: `translateX(${introView3 ? 0 : 40}vw) scale(1.1)`,
      opacity: introView3 ? 1 : 0,
    },
    config: introView3
      ? {
          duration: 4000,
        }
      : { duration: 0.1 },
  });
  const stylesSmokeSmallScreen = useSpring({
    to: {
      transform: `translateY(${introView3 ? 25 : 0}vh) scale(1.1)`,
      opacity: introView3 ? 0 : 1,
    },
    from: {
      transform: `translateY(${introView3 ? 0 : 25}vh) scale(1.1)`,
      opacity: introView3 ? 1 : 0,
    },
    config: introView3
      ? {
          duration: 3500,
        }
      : { duration: 0.1 },
  });

  const stylesSkillPicker = useSpring({
    to: {
      transform: `translateX(${introView3 ? 0 : -50}px)`,
      x: 0,
      opacity: introView3 ? 1 : 0,
    },
    from: {
      transform: `translateX(${introView3 ? -50 : 0}px)`,
      opacity: introView3 ? 0 : 1,
    },
    config: introView3
      ? {
          mass: 4.4,
          tension: 74,
          friction: 128,
          precision: 0.001,
          velocity: 0.002,
        }
      : { duration: 0.1 },
  });

  const stylesSkillPickerCover = useSpring({
    to: {
      transform: `translateY(${introView3 ? 0 : 50}px)`,
      x: 0,
      opacity: introView3 ? 1 : 0,
    },
    from: {
      transform: `translateY(${introView3 ? 50 : 0}px)`,
      opacity: introView3 ? 0 : 1,
    },
    config: introView3
      ? {
          mass: 4.4,
          tension: 74,
          friction: 128,
          precision: 0.001,
          velocity: 0.002,
        }
      : { duration: 0.1 },
  });

  const stylesMintedScientistCover = useSpring({
    loop: true,
    to: [{ transform: 'translateY(-15px)' }, { transform: 'translateY(0px)' }],
    from: {
      transform: 'translateY(0px)',
    },
    config: { duration: 2000 },
  });

  const stylesMintedScientistCard = useSpring({
    loop: true,
    to: [{ transform: 'translate(-50%, -60%)' }, { transform: 'translate(-50%, -50%)' }],
    from: {
      transform: 'translate(-50%, -50%)',
    },
    config: { duration: 3000 },
  });

  return {
    stylesMintedScientistCover,
    stylesSkillPickerCover,
    stylesSkillPicker,
    stylesIntro3,
    stylesSmokeLeft,
    stylesSmokeRight,
    stylesSmokeSmallScreen,
    stylesMintedScientistCard,
  };
};

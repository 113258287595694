import { styled } from '@mui/material/styles';
import { Button, Slider, Tooltip } from '@mui/material';

export const ScientistMintWrapper = styled('div')`
  background-color: #000;
  overflow: auto;
  width: 100%;
  height: 100vh;
  scroll-snap-type: y mandatory;
  font-family: 'Anybody';
  .glitch {
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .glitch .glitch__item {
    background: url('/images/SimbiotesRuinousCity.webp') no-repeat 50% 50% / cover;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  .glitch .glitch__item:nth-of-type(n + 2) {
    opacity: 0;
    animation-duration: 3s;
    animation-delay: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .glitch .glitch__item:nth-of-type(2) {
    background-color: transparent;
    background-blend-mode: none;
    animation-name: glitch-anim-2;
  }
  .glitch .glitch__item:nth-of-type(2) {
    background-color: transparent;
    background-blend-mode: none;
    animation-name: glitch-anim-3;
  }
  .glitch .glitch__item:nth-of-type(4) {
    background-color: transparent;
    background-blend-mode: none;
    animation-name: glitch-anim-4;
  }
  .glitch .glitch__item:nth-of-type(5) {
    background-color: #af4949;
    background-blend-mode: overlay;
    animation-name: glitch-anim-flash;
  }
  @keyframes glitch-anim-flash {
    0%,
    5% {
      opacity: 0.2;
      transform: translate3d(100vw, 100vh, 0);
    }
    5.5%,
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes glitch-anim {
    0% {
      clip: rect(15px, 9999px, 55px, 0);
    }
    5% {
      clip: rect(79px, 9999px, 8px, 0);
    }
    10% {
      clip: rect(121px, 9999px, 69px, 0);
    }
    15% {
      clip: rect(76px, 9999px, 98px, 0);
    }
    20% {
      clip: rect(20px, 9999px, 48px, 0);
    }
    25% {
      clip: rect(46px, 9999px, 95px, 0);
    }
    30% {
      clip: rect(102px, 9999px, 103px, 0);
    }
    35% {
      clip: rect(156px, 9999px, 35px, 0);
    }
    40% {
      clip: rect(45px, 9999px, 31px, 0);
    }
    45% {
      clip: rect(114px, 9999px, 86px, 0);
    }
    50% {
      clip: rect(2px, 9999px, 153px, 0);
    }
    55% {
      clip: rect(45px, 9999px, 28px, 0);
    }
    60% {
      clip: rect(89px, 9999px, 11px, 0);
    }
    65% {
      clip: rect(32px, 9999px, 20px, 0);
    }
    70% {
      clip: rect(76px, 9999px, 136px, 0);
    }
    75% {
      clip: rect(105px, 9999px, 164px, 0);
    }
    80% {
      clip: rect(75px, 9999px, 13px, 0);
    }
    85% {
      clip: rect(93px, 9999px, 5px, 0);
    }
    90% {
      clip: rect(67px, 9999px, 60px, 0);
    }
    95% {
      clip: rect(144px, 9999px, 78px, 0);
    }
    100% {
      clip: rect(94px, 9999px, 145px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    0% {
      opacity: 1;
      transform: translate3d(10px, 0, 0);
      -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
    2% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    4% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
      clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }
    6% {
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
      clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }
    8% {
      -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    10% {
      -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
      clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    12% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
      clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    14% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
      clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    16% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
      clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    18% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    20% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
      clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(10px, 0, 0);
    }
    22%,
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  @keyframes glitch-anim-3 {
    0% {
      opacity: 1;
      transform: translate3d(calc(-1 * 10px), 0, 0);
      -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
      clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }
    3% {
      -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
      clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }
    5% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
      clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }
    7% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    9% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
      clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }
    11% {
      -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
      clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }
    13% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    15% {
      -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
      clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }
    17% {
      -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
      clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }
    19% {
      -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
      clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }
    20% {
      -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
      clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(calc(-1 * 10px), 0, 0);
    }
    22%,
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  @keyframes glitch-anim-4 {
    0% {
      opacity: 1;
      transform: translate3d(0, calc(-1 * 5px), 0) scale3d(-1, -1, 1);
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
      clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }
    1.5% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
      clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }
    2% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
      clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }
    2.5% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    3% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
      clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }
    5% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
      clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }
    5.5% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
      clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }
    7% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
      clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }
    8% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    9% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
      clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }
    10.5% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
      clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }
    11% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
      clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }
    13% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
      clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }
    14% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
      clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }
    14.5% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
      clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }
    15% {
      -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
      clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }
    16% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    18% {
      -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
      clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }
    20% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
      clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(0, calc(-1 * 5px), 0) scale3d(-1, -1, 1);
    }
    22%,
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
`;
export const Introduce1 = styled('div')`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  background-image: url('/images/ScientistMintBg.webp');
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  margin-bottom: 1px;
  .preview_tech_city__cover {
    margin-top: 250px;
    width: 355px;
    filter: brightness(60%);
    @media (max-width: 1600px) {
      margin-top: 120px;
    }
    @media (max-width: 1024px) {
      margin-top: 200px;
    }
    @media (max-width: 420px) {
      margin-top: 100px;
    }
  }
  .preview_section3 {
    @media (max-width: 1600px) {
      margin-bottom: 50px;
    }
    @media (max-width: 1024px) {
      margin-top: 80px;
      margin-bottom: 0;
    }
    @media (max-width: 420px) {
      margin-bottom: 50px;
    }
  }
  .preview_rock_abyss__cover {
    width: 444px;
    filter: brightness(60%);
    clip-path: inset(50% 0 15% 0);
    margin-top: -150px;
    @media (max-width: 420px) {
      width: 360px;
    }
  }
`;
export const PreviewAwakeSection = styled('div')`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -50px;
  width: fit-content;
`;
export const AwakeNavigateButton = styled('div')`
  margin-bottom: ${(props) => (props.afterlogin ? '28px' : '52px')};
  margin-top: ${(props) => (props.afterlogin ? '30px' : '0')};
  cursor: pointer;
  font-family: 'Anybody';
  width: fit-content;
  .wake_up__button {
    margin-right: 10px;
  }
  .wake_up__amount {
    opacity: 0.6;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
  }
  .mad_unit {
    font-weight: 250;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    font-family: 'Anybody';
  }
`;
export const CreateSection = styled('div')`
  display: flex;
  padding-left: 86px;
  padding-top: 102px;
  align-items: center;
  width: 100%;
  @media (max-width: 420px) {
    padding-left: 0;
    flex-direction: column;
  }
  .created__cover {
    width: 587px;
    height: 331px;
    position: relative;
    @media (max-width: 420px) {
      width: 350px;
      height: 197.5px;
      margin-top: 50px;
    }
  }
`;
export const Introduce2 = styled('div')`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  background-image: url('/images/IntroduceMintSectionBg.webp');
  background-position: center;
  position: relative;
  min-height: 100vh;
  margin-top: 10px;
  overflow: hidden;
  .preview_tech_city__cover {
    display: block;
    margin-left: auto;
    margin-right: 0;
    margin-top: 100px;
    right: 0;
    top: 100px;
    width: 1086px;
    @media (max-width: 1024px) {
      width: 90%;
    }
  }
`;
export const SocialContacts = styled('div')`
  position: absolute;
  bottom: ${(props) => (props.leftalign ? '52px' : '44px')};
  right: ${(props) => (props.leftalign ? 'unset' : '100px')};
  left: ${(props) => (props.leftalign ? '95px' : 'unset')};

  @media (max-width: 1600px) {
    bottom: ${(props) => (props.leftalign ? '32px' : '44px')};
  }
  @media (max-width: 1024px) {
    left: ${(props) => (props.leftalign ? '35px' : 'unset')};
  }

  .social_contact__title {
    font-family: 'Anybody';
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.09em;
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
  }
`;
export const Contact = styled('span')`
  display: inline-block;
  position: relative;
  margin: 0 5px 0 5px;
  .social__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
  }
`;

export const WakeUpScientistWrapper = styled('div')`
  background-image: url('/images/MintSectionBg.webp');
  min-height: 100vh;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  .wake_up_animations__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    z-index: 1000;
    background: transparent;
    overflow: hidden;
  }
  .animate__bg,
  .animate_flash_light,
  .animate_open_eyes,
  .animate_crazy_scientist,
  .animate_white_light {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #000;
    z-index: 1;
    pointer-events: none;
  }
  .animate_open_eyes {
    background: url('images/openEyesGif.gif');
    background-size: 70vw;
    background-position: center;
    background-repeat: no-repeat;
  }
  .animate_flash_light {
    background-color: #fff;
  }
  .animate_white_light {
    background-color: #fff;
    z-index: 3;
  }
  .animate_crazy_scientist {
    overflow: hidden;
    background-color: unset;
    .animate__content {
      height: 100vh;
      background: url('https://i0.wp.com/phenomenex.blog/wp-content/uploads/2017/10/mad-scientist-blog.png?fit=810%2C450&ssl=1');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-blend-mode: multiply;
      -webkit-animation: spazz 7s steps(1) infinite;
      animation: spazz 7s steps(1) infinite;
      -webkit-animation-duration: 17s;
      animation-duration: 17s;
    }
  }
  @-webkit-keyframes spazz {
    1% {
      filter: hue-rotate(50.4deg);
      background-color: #d4ff66;
      transform: translate(-3px, -12px) scale(1.08);
    }
    2% {
      filter: hue-rotate(324deg);
      background-color: #ff668f;
      transform: translate(2px, -9px) scale(1.15);
    }
    3% {
      filter: hue-rotate(306deg);
      background-color: #66ffe0;
      transform: translate(-1px, -10px) scale(1.1);
    }
    5% {
      background-color: #66ffbd;
      transform: translate(-3px, 11px) scale(1.2);
    }
    7% {
      filter: hue-rotate(54deg);
      background-color: #edff66;
      transform: translate(3px, 5px) scale(1.06);
    }
    8% {
      filter: hue-rotate(349.2deg);
      background-color: #7866ff;
      transform: translate(-6px, -5px) scale(1.11);
    }
    9% {
      filter: hue-rotate(18deg);
      background-color: #ffc466;
      transform: translate(-3px, -4px) scale(1.22);
    }
    10% {
      filter: hue-rotate(93.6deg);
      background-color: #66dbff;
      transform: translate(1px, -6px) scale(1.15);
    }
    11% {
      filter: hue-rotate(223.2deg);
      background-color: #ff66b0;
      transform: translate(-6px, 0px) scale(1.09);
    }
    13% {
      filter: saturate(7.3) hue-rotate(10.8deg);
      background-color: #8766ff;
      transform: translate(-8px, 12px) scale(1.07);
    }
    14% {
      filter: saturate(8.2) hue-rotate(122.4deg) contrast(3.52);
      background-color: #66adff;
      transform: translate(-1px, 2px) scale(1.1);
    }
    16% {
      filter: hue-rotate(298.8deg);
      background-color: #bf66ff;
      transform: translate(2px, -10px) scale(1.2);
    }
    17% {
      filter: hue-rotate(10.8deg);
      background-color: #66ffe0;
      transform: translate(8px, 7px) scale(1.14);
    }
    18% {
      filter: hue-rotate(14.4deg);
      background-color: #66d1ff;
      transform: translate(-7px, -6px) scale(1.23);
    }
    20% {
      filter: hue-rotate(306deg);
      background-color: #ff66a8;
      transform: translate(7px, 14px) scale(1.1);
    }
    21% {
      filter: hue-rotate(64.8deg);
      background-color: #66ff70;
      transform: translate(-13px, 5px) scale(1.13);
    }
    22% {
      background-color: #6670ff;
      transform: translate(-11px, 13px) scale(1.09);
    }
    23% {
      filter: hue-rotate(104.4deg);
      background-color: #ff668f;
      transform: translate(-13px, -9px) scale(1.25);
    }
    24% {
      filter: saturate(7.84) hue-rotate(86.4deg);
      background-color: #66e8ff;
      transform: translate(-1px, -5px) scale(1.15);
    }
    25% {
      filter: hue-rotate(39.6deg) contrast(4.51);
      background-color: #ff6691;
      transform: translate(-10px, -2px) scale(1.07);
    }
    26% {
      background-color: #ffab66;
      transform: translate(6px, 2px) scale(1.14);
    }
    27% {
      filter: hue-rotate(93.6deg);
      background-color: #e666ff;
      transform: translate(-12px, -9px) scale(1.08);
    }
    28% {
      filter: saturate(6.94) hue-rotate(334.8deg);
      background-color: #c266ff;
      transform: translate(-10px, 2px) scale(1.07);
    }
    29% {
      filter: hue-rotate(187.2deg);
      background-color: #ff9e66;
      transform: translate(5px, -2px) scale(1.24);
    }
    30% {
      filter: hue-rotate(97.2deg);
      background-color: #66ffff;
      transform: translate(11px, -3px) scale(1.23);
    }
    31% {
      filter: hue-rotate(39.6deg);
      background-color: #7566ff;
      transform: translate(-8px, 14px) scale(1.12);
    }
    32% {
      filter: hue-rotate(46.8deg);
      background-color: #ffb566;
      transform: translate(-6px, -4px) scale(1.11);
    }
    33% {
      filter: hue-rotate(147.6deg);
      background-color: #66ffd1;
      transform: translate(-13px, -14px) scale(1.12);
    }
    34% {
      filter: saturate(6.31) hue-rotate(54deg);
      background-color: #eb66ff;
      transform: translate(-5px, 3px) scale(1.22);
    }
    35% {
      filter: hue-rotate(194.4deg);
      background-color: #66a8ff;
      transform: translate(-9px, -6px) scale(1.21);
    }
    36% {
      filter: hue-rotate(97.2deg);
      background-color: #a866ff;
      transform: translate(-8px, 12px) scale(1.19);
    }
    37% {
      filter: hue-rotate(82.8deg);
      background-color: #ff66a3;
      transform: translate(12px, 0px) scale(1.09);
    }
    38% {
      filter: hue-rotate(259.2deg) contrast(3.7);
      background-color: #66fff7;
      transform: translate(9px, -10px) scale(1.11);
    }
    39% {
      filter: hue-rotate(201.6deg) contrast(4.96);
      background-color: #b0ff66;
      transform: translate(-1px, 11px) scale(1.24);
    }
    40% {
      filter: hue-rotate(151.2deg);
      background-color: #66ffdb;
      transform: translate(1px, 10px) scale(1.19);
    }
    42% {
      filter: hue-rotate(140.4deg);
      background-color: #ff666b;
      transform: translate(12px, -9px) scale(1.08);
    }
    44% {
      filter: hue-rotate(252deg) invert(1);
      background-color: #ff66c7;
      transform: translate(-5px, 11px) scale(1.2);
    }
    45% {
      filter: hue-rotate(219.6deg);
      background-color: #e3ff66;
      transform: translate(-9px, -12px) scale(1.21);
    }
    46% {
      filter: hue-rotate(208.8deg) contrast(5.32);
      background-color: #ff669c;
      transform: translate(-1px, -9px) scale(1.15);
    }
    52% {
      filter: hue-rotate(50.4deg);
      background-color: #66d6ff;
      transform: translate(-5px, -9px) scale(1.18);
    }
    53% {
      filter: hue-rotate(338.4deg);
      background-color: #deff66;
      transform: translate(14px, 10px) scale(1.11);
    }
    54% {
      filter: saturate(1.18) hue-rotate(50.4deg);
      background-color: #ff66fc;
      transform: translate(12px, -2px) scale(1.19);
    }
    55% {
      filter: hue-rotate(64.8deg);
      background-color: #f5ff66;
      transform: translate(-14px, -10px) scale(1.07);
    }
    56% {
      filter: hue-rotate(36deg) brightness(40.5%);
      background-color: #f266ff;
      transform: translate(-3px, -5px) scale(1.2);
    }
    57% {
      filter: hue-rotate(259.2deg);
      background-color: #f7ff66;
      transform: translate(-1px, 5px) scale(1.14);
    }
    58% {
      filter: hue-rotate(306deg);
      background-color: #ff66a6;
      transform: translate(11px, 0px) scale(1.12);
    }
    59% {
      filter: hue-rotate(154.8deg);
      background-color: #66e6ff;
      transform: translate(4px, -12px) scale(1.22);
    }
    60% {
      filter: hue-rotate(237.6deg);
      background-color: #66ff80;
      transform: translate(-7px, 15px) scale(1.13);
    }
    62% {
      filter: saturate(2.8) hue-rotate(126deg);
      background-color: #69ff66;
      transform: translate(-5px, -4px) scale(1.14);
    }
    63% {
      background-color: #66ff82;
      transform: translate(13px, -14px) scale(1.17);
    }
    64% {
      filter: hue-rotate(187.2deg);
      background-color: #9466ff;
      transform: translate(14px, 11px) scale(1.15);
    }
    65% {
      filter: hue-rotate(18deg);
      background-color: #66ffe0;
      transform: translate(9px, -4px) scale(1.14);
    }
    68% {
      filter: hue-rotate(3.6deg) brightness(32%);
      background-color: #ff6666;
      transform: translate(12px, 14px) scale(1.1);
    }
    73% {
      filter: hue-rotate(54deg);
      background-color: #66ff87;
      transform: translate(9px, -6px) scale(1.22);
    }
    75% {
      filter: hue-rotate(266.4deg);
      background-color: #66ffd4;
      transform: translate(-9px, 5px) scale(1.22);
    }
    76% {
      filter: hue-rotate(298.8deg) invert(1);
      background-color: #a6ff66;
      transform: translate(-1px, 0px) scale(1.1);
    }
    77% {
      filter: hue-rotate(316.8deg);
      background-color: #66ffb8;
      transform: translate(11px, -13px) scale(1.09);
    }
    78% {
      filter: hue-rotate(126deg);
      background-color: #ebff66;
      transform: translate(-14px, 7px) scale(1.15);
    }
    79% {
      filter: hue-rotate(162deg) contrast(9.55);
      background-color: #668cff;
      transform: translate(2px, -5px) scale(1.12);
    }
    80% {
      filter: hue-rotate(298.8deg);
      background-color: #66ff75;
      transform: translate(8px, 1px) scale(1.16);
    }
    81% {
      filter: saturate(6.4) hue-rotate(288deg);
      background-color: #ffba66;
      transform: translate(-1px, 9px) scale(1.17);
    }
    82% {
      filter: hue-rotate(86.4deg);
      background-color: #66ffab;
      transform: translate(8px, 5px) scale(1.15);
    }
    83% {
      filter: hue-rotate(327.6deg) invert(1);
      background-color: #66ff99;
      transform: translate(-11px, 8px) scale(1.08);
    }
    84% {
      filter: saturate(6.94) hue-rotate(244.8deg);
      background-color: #66a3ff;
      transform: translate(10px, 3px) scale(1.13);
    }
    86% {
      filter: saturate(6.67) hue-rotate(165.6deg);
      background-color: #ffff66;
      transform: translate(-12px, -1px) scale(1.07);
    }
    87% {
      filter: hue-rotate(313.2deg) invert(1);
      background-color: #9966ff;
      transform: translate(-12px, 9px) scale(1.07);
    }
    88% {
      filter: saturate(4.42) hue-rotate(342deg);
      background-color: #ff9e66;
      transform: translate(-1px, 14px) scale(1.22);
    }
    89% {
      filter: hue-rotate(50.4deg);
      background-color: #f5ff66;
      transform: translate(-9px, -6px) scale(1.17);
    }
    91% {
      filter: hue-rotate(259.2deg);
      background-color: #d966ff;
      transform: translate(0px, 3px) scale(1.16);
    }
    92% {
      filter: hue-rotate(46.8deg) contrast(6.94);
      background-color: #ff669c;
      transform: translate(-12px, 15px) scale(1.11);
    }
    93% {
      filter: hue-rotate(165.6deg) contrast(8.47);
      background-color: #ffb866;
      transform: translate(-12px, -11px) scale(1.23);
    }
    95% {
      filter: hue-rotate(219.6deg);
      background-color: #66ccff;
      transform: translate(6px, 8px) scale(1.06);
    }
    96% {
      filter: hue-rotate(151.2deg);
      background-color: #ff9666;
      transform: translate(3px, 13px) scale(1.09);
    }
    97% {
      filter: hue-rotate(349.2deg) brightness(64%);
      background-color: #66ffa3;
      transform: translate(-13px, -3px) scale(1.09);
    }
    98% {
      filter: hue-rotate(273.6deg);
      background-color: #66ffff;
      transform: translate(-8px, 15px) scale(1.07);
    }
    100% {
      filter: hue-rotate(241.2deg);
      background-color: #ffde66;
      transform: translate(-10px, -7px) scale(1.06);
    }
  }

  @keyframes spazz {
    1% {
      filter: hue-rotate(50.4deg);
      background-color: #d4ff66;
      transform: translate(-3px, -12px) scale(1.08);
    }
    2% {
      filter: hue-rotate(324deg);
      background-color: #ff668f;
      transform: translate(2px, -9px) scale(1.15);
    }
    3% {
      filter: hue-rotate(306deg);
      background-color: #66ffe0;
      transform: translate(-1px, -10px) scale(1.1);
    }
    5% {
      background-color: #66ffbd;
      transform: translate(-3px, 11px) scale(1.2);
    }
    7% {
      filter: hue-rotate(54deg);
      background-color: #edff66;
      transform: translate(3px, 5px) scale(1.06);
    }
    8% {
      filter: hue-rotate(349.2deg);
      background-color: #7866ff;
      transform: translate(-6px, -5px) scale(1.11);
    }
    9% {
      filter: hue-rotate(18deg);
      background-color: #ffc466;
      transform: translate(-3px, -4px) scale(1.22);
    }
    10% {
      filter: hue-rotate(93.6deg);
      background-color: #66dbff;
      transform: translate(1px, -6px) scale(1.15);
    }
    11% {
      filter: hue-rotate(223.2deg);
      background-color: #ff66b0;
      transform: translate(-6px, 0px) scale(1.09);
    }
    13% {
      filter: saturate(7.3) hue-rotate(10.8deg);
      background-color: #8766ff;
      transform: translate(-8px, 12px) scale(1.07);
    }
    14% {
      filter: saturate(8.2) hue-rotate(122.4deg) contrast(3.52);
      background-color: #66adff;
      transform: translate(-1px, 2px) scale(1.1);
    }
    16% {
      filter: hue-rotate(298.8deg);
      background-color: #bf66ff;
      transform: translate(2px, -10px) scale(1.2);
    }
    17% {
      filter: hue-rotate(10.8deg);
      background-color: #66ffe0;
      transform: translate(8px, 7px) scale(1.14);
    }
    18% {
      filter: hue-rotate(14.4deg);
      background-color: #66d1ff;
      transform: translate(-7px, -6px) scale(1.23);
    }
    20% {
      filter: hue-rotate(306deg);
      background-color: #ff66a8;
      transform: translate(7px, 14px) scale(1.1);
    }
    21% {
      filter: hue-rotate(64.8deg);
      background-color: #66ff70;
      transform: translate(-13px, 5px) scale(1.13);
    }
    22% {
      background-color: #6670ff;
      transform: translate(-11px, 13px) scale(1.09);
    }
    23% {
      filter: hue-rotate(104.4deg);
      background-color: #ff668f;
      transform: translate(-13px, -9px) scale(1.25);
    }
    24% {
      filter: saturate(7.84) hue-rotate(86.4deg);
      background-color: #66e8ff;
      transform: translate(-1px, -5px) scale(1.15);
    }
    25% {
      filter: hue-rotate(39.6deg) contrast(4.51);
      background-color: #ff6691;
      transform: translate(-10px, -2px) scale(1.07);
    }
    26% {
      background-color: #ffab66;
      transform: translate(6px, 2px) scale(1.14);
    }
    27% {
      filter: hue-rotate(93.6deg);
      background-color: #e666ff;
      transform: translate(-12px, -9px) scale(1.08);
    }
    28% {
      filter: saturate(6.94) hue-rotate(334.8deg);
      background-color: #c266ff;
      transform: translate(-10px, 2px) scale(1.07);
    }
    29% {
      filter: hue-rotate(187.2deg);
      background-color: #ff9e66;
      transform: translate(5px, -2px) scale(1.24);
    }
    30% {
      filter: hue-rotate(97.2deg);
      background-color: #66ffff;
      transform: translate(11px, -3px) scale(1.23);
    }
    31% {
      filter: hue-rotate(39.6deg);
      background-color: #7566ff;
      transform: translate(-8px, 14px) scale(1.12);
    }
    32% {
      filter: hue-rotate(46.8deg);
      background-color: #ffb566;
      transform: translate(-6px, -4px) scale(1.11);
    }
    33% {
      filter: hue-rotate(147.6deg);
      background-color: #66ffd1;
      transform: translate(-13px, -14px) scale(1.12);
    }
    34% {
      filter: saturate(6.31) hue-rotate(54deg);
      background-color: #eb66ff;
      transform: translate(-5px, 3px) scale(1.22);
    }
    35% {
      filter: hue-rotate(194.4deg);
      background-color: #66a8ff;
      transform: translate(-9px, -6px) scale(1.21);
    }
    36% {
      filter: hue-rotate(97.2deg);
      background-color: #a866ff;
      transform: translate(-8px, 12px) scale(1.19);
    }
    37% {
      filter: hue-rotate(82.8deg);
      background-color: #ff66a3;
      transform: translate(12px, 0px) scale(1.09);
    }
    38% {
      filter: hue-rotate(259.2deg) contrast(3.7);
      background-color: #66fff7;
      transform: translate(9px, -10px) scale(1.11);
    }
    39% {
      filter: hue-rotate(201.6deg) contrast(4.96);
      background-color: #b0ff66;
      transform: translate(-1px, 11px) scale(1.24);
    }
    40% {
      filter: hue-rotate(151.2deg);
      background-color: #66ffdb;
      transform: translate(1px, 10px) scale(1.19);
    }
    42% {
      filter: hue-rotate(140.4deg);
      background-color: #ff666b;
      transform: translate(12px, -9px) scale(1.08);
    }
    44% {
      filter: hue-rotate(252deg) invert(1);
      background-color: #ff66c7;
      transform: translate(-5px, 11px) scale(1.2);
    }
    45% {
      filter: hue-rotate(219.6deg);
      background-color: #e3ff66;
      transform: translate(-9px, -12px) scale(1.21);
    }
    46% {
      filter: hue-rotate(208.8deg) contrast(5.32);
      background-color: #ff669c;
      transform: translate(-1px, -9px) scale(1.15);
    }
    52% {
      filter: hue-rotate(50.4deg);
      background-color: #66d6ff;
      transform: translate(-5px, -9px) scale(1.18);
    }
    53% {
      filter: hue-rotate(338.4deg);
      background-color: #deff66;
      transform: translate(14px, 10px) scale(1.11);
    }
    54% {
      filter: saturate(1.18) hue-rotate(50.4deg);
      background-color: #ff66fc;
      transform: translate(12px, -2px) scale(1.19);
    }
    55% {
      filter: hue-rotate(64.8deg);
      background-color: #f5ff66;
      transform: translate(-14px, -10px) scale(1.07);
    }
    56% {
      filter: hue-rotate(36deg) brightness(40.5%);
      background-color: #f266ff;
      transform: translate(-3px, -5px) scale(1.2);
    }
    57% {
      filter: hue-rotate(259.2deg);
      background-color: #f7ff66;
      transform: translate(-1px, 5px) scale(1.14);
    }
    58% {
      filter: hue-rotate(306deg);
      background-color: #ff66a6;
      transform: translate(11px, 0px) scale(1.12);
    }
    59% {
      filter: hue-rotate(154.8deg);
      background-color: #66e6ff;
      transform: translate(4px, -12px) scale(1.22);
    }
    60% {
      filter: hue-rotate(237.6deg);
      background-color: #66ff80;
      transform: translate(-7px, 15px) scale(1.13);
    }
    62% {
      filter: saturate(2.8) hue-rotate(126deg);
      background-color: #69ff66;
      transform: translate(-5px, -4px) scale(1.14);
    }
    63% {
      background-color: #66ff82;
      transform: translate(13px, -14px) scale(1.17);
    }
    64% {
      filter: hue-rotate(187.2deg);
      background-color: #9466ff;
      transform: translate(14px, 11px) scale(1.15);
    }
    65% {
      filter: hue-rotate(18deg);
      background-color: #66ffe0;
      transform: translate(9px, -4px) scale(1.14);
    }
    68% {
      filter: hue-rotate(3.6deg) brightness(32%);
      background-color: #ff6666;
      transform: translate(12px, 14px) scale(1.1);
    }
    73% {
      filter: hue-rotate(54deg);
      background-color: #66ff87;
      transform: translate(9px, -6px) scale(1.22);
    }
    75% {
      filter: hue-rotate(266.4deg);
      background-color: #66ffd4;
      transform: translate(-9px, 5px) scale(1.22);
    }
    76% {
      filter: hue-rotate(298.8deg) invert(1);
      background-color: #a6ff66;
      transform: translate(-1px, 0px) scale(1.1);
    }
    77% {
      filter: hue-rotate(316.8deg);
      background-color: #66ffb8;
      transform: translate(11px, -13px) scale(1.09);
    }
    78% {
      filter: hue-rotate(126deg);
      background-color: #ebff66;
      transform: translate(-14px, 7px) scale(1.15);
    }
    79% {
      filter: hue-rotate(162deg) contrast(9.55);
      background-color: #668cff;
      transform: translate(2px, -5px) scale(1.12);
    }
    80% {
      filter: hue-rotate(298.8deg);
      background-color: #66ff75;
      transform: translate(8px, 1px) scale(1.16);
    }
    81% {
      filter: saturate(6.4) hue-rotate(288deg);
      background-color: #ffba66;
      transform: translate(-1px, 9px) scale(1.17);
    }
    82% {
      filter: hue-rotate(86.4deg);
      background-color: #66ffab;
      transform: translate(8px, 5px) scale(1.15);
    }
    83% {
      filter: hue-rotate(327.6deg) invert(1);
      background-color: #66ff99;
      transform: translate(-11px, 8px) scale(1.08);
    }
    84% {
      filter: saturate(6.94) hue-rotate(244.8deg);
      background-color: #66a3ff;
      transform: translate(10px, 3px) scale(1.13);
    }
    86% {
      filter: saturate(6.67) hue-rotate(165.6deg);
      background-color: #ffff66;
      transform: translate(-12px, -1px) scale(1.07);
    }
    87% {
      filter: hue-rotate(313.2deg) invert(1);
      background-color: #9966ff;
      transform: translate(-12px, 9px) scale(1.07);
    }
    88% {
      filter: saturate(4.42) hue-rotate(342deg);
      background-color: #ff9e66;
      transform: translate(-1px, 14px) scale(1.22);
    }
    89% {
      filter: hue-rotate(50.4deg);
      background-color: #f5ff66;
      transform: translate(-9px, -6px) scale(1.17);
    }
    91% {
      filter: hue-rotate(259.2deg);
      background-color: #d966ff;
      transform: translate(0px, 3px) scale(1.16);
    }
    92% {
      filter: hue-rotate(46.8deg) contrast(6.94);
      background-color: #ff669c;
      transform: translate(-12px, 15px) scale(1.11);
    }
    93% {
      filter: hue-rotate(165.6deg) contrast(8.47);
      background-color: #ffb866;
      transform: translate(-12px, -11px) scale(1.23);
    }
    95% {
      filter: hue-rotate(219.6deg);
      background-color: #66ccff;
      transform: translate(6px, 8px) scale(1.06);
    }
    96% {
      filter: hue-rotate(151.2deg);
      background-color: #ff9666;
      transform: translate(3px, 13px) scale(1.09);
    }
    97% {
      filter: hue-rotate(349.2deg) brightness(64%);
      background-color: #66ffa3;
      transform: translate(-13px, -3px) scale(1.09);
    }
    98% {
      filter: hue-rotate(273.6deg);
      background-color: #66ffff;
      transform: translate(-8px, 15px) scale(1.07);
    }
    100% {
      filter: hue-rotate(241.2deg);
      background-color: #ffde66;
      transform: translate(-10px, -7px) scale(1.06);
    }
  }
  .smokes {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100vh;
    z-index: 1;
    pointer-events: none;
    @media (max-width: 440px) {
      height: 100%;
    }
    .ag-smoke_img {
      width: 100%;
      height: 100%;
      @media (max-width: 1024px) {
        object-fit: cover;
      }
      &.ag-smoke_img__left {
        margin-bottom: 70px;
        position: absolute;
      }
      &.ag-smoke_img__right {
        margin-bottom: 250px;
        position: absolute;
        @media (max-width: 1024px) {
          bottom: 50px;
          right: -50px;
        }
        @media (max-width: 440px) {
          right: 0;
        }
      }
    }
  }
  .wake_up__section {
    flex-direction: row;
    padding-left: 100px;
    @media (max-width: 1024px) {
      padding: 0 40px;
      flex-direction: column;
    }
    .wake_up__form {
      z-index: 1;
      order: 1;
      @media (max-width: 1024px) {
        order: 2;
        margin-top: 130px;
      }
      @media (max-width: 899px) {
        order: 2;
        transform: translateY(-130px);
      }
      @media (max-width: 450px) {
        margin-bottom: 120px;
      }
    }
    .wake_up__cover_section {
      z-index: 10;
      order: 2;
      @media (max-width: 1024px) {
        order: 1;
        margin-top: 90px;
      }
    }
    .skill_picker__wrapper {
      z-index: 1;
      display: flex;
      flex-direction: column;
      @media (max-width: 1024px) {
        align-items: center;
      }
    }
    .wake_up_cover__section {
      z-index: 0;
      display: flex;
      flex-direction: column;
    }
  }
  .wake_up_form__button__wrapper {
    width: 200px;
    height: 48px;
    padding: 0;
    border: 1px solid #646668;
    border-radius: 0;
    transform: skew(-20deg);
    position: relative;
    background: #121416;
    padding-left: 30px;
    font-family: 'Anybody';
    line-height: 1.4;

    &.disabled_activate__button {
      opacity: 0.3;
      pointer-events: none;
      svg {
        pointer-events: auto;
        cursor: pointer;
        fill: rgba(255, 255, 255, 0.6);
      }
      @media (max-width: 1024px) {
        margin-top: 180px;
      }
    }

    &.submit_awake__button__wrapper {
      pointer-events: auto;
      margin-top: 80px;
      visibility: hidden;
      opacity: 0;
      @media (max-width: 1600px) {
        margin-top: 0;
      }
      @media (max-width: 1024px) {
        margin-top: 60px;
        margin-bottom: 20px;
      }
      @media (max-width: 420px) {
        margin-top: 0px;
        margin-bottom: 0px;
      }
      transition: all 0.2s ease-in-out;
      &.wakeUpAble {
        visibility: visible;
        opacity: 1;
      }
    }

    span {
      transform: skew(20deg);
      color: #fff;
      text-transform: capitalize;
    }

    .wake_up_form__icon_wrapper {
      position: absolute;
      width: 62px;
      background: #121416;
      height: 58px;
      left: -20px;
      bottom: -5px;
      border: 1px solid #646668;
      display: flex;
      justify-content: center;
      align-items: center;
      .wake_up_form__icon_wrapper_bg {
        transform: skew(20deg);
      }
    }
    .wake_up_form__icon_wrapper_active {
      background: linear-gradient(
        to bottom right,
        #00abe2,
        #45a0ea,
        #9493f3,
        #ce89f9,
        #f183fd,
        #ff81ff,
        #f982fe,
        #e785fc,
        #ca8af9,
        #a190f4,
        #6d99ee,
        #2fa3e7,
        #00abe2
      );
      border-color: #000;
    }
    .wake_up_form__icon_item {
      position: absolute;
      top: -6px;
      right: 0;
      height: 3px;
      width: 33px;
      background: #646668;
    }
    .wrapper_item_dot {
      display: flex;
      position: absolute;
      bottom: 2px;
      right: 10px;
      gap: 2px;
      transform: skew(20deg);
      .dot {
        width: 3px;
        height: 3px;
        background: #ffffff;
      }
    }
  }
  .wake_up_form__submit__wrapper {
    width: 189px;
    height: 48px;
    padding: 0;
    border: 1px solid #646668;
    border-radius: 0;
    position: relative;
    background: #121416;
    padding-left: 30px;
    margin-bottom: 10px;
    &.disabled_activate__button {
      opacity: 0.3;
      pointer-events: none;
    }

    &.submit_awake__button__wrapper {
      pointer-events: auto;
      margin-top: 80px;
      visibility: hidden;
      opacity: 0;
      @media (max-width: 1600px) {
        margin-top: 0;
      }
      @media (max-width: 1024px) {
        margin-top: 60px;
      }
      @media (max-width: 420px) {
        margin-top: 0px;
      }
      transition: all 0.2s ease-in-out;
      &.wakeUpAble {
        visibility: visible;
        opacity: 1;
      }
    }

    .content {
      color: #fff;
      text-transform: uppercase;
      height: 48px;
      width: calc(100% - 48px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #000;
      padding: 4px;
      font-family: 'Anybody';
    }

    .wake_up_form__icon_wrapper {
      position: absolute;
      width: 62px;
      background: #121416;
      height: 58px;
      left: -20px;
      bottom: -5px;
      border: 1px solid #646668;
      display: flex;
      justify-content: center;
      align-items: center;
      .wake_up_form__icon_wrapper_bg {
      }
    }
    .wake_up_form__icon_wrapper_active {
      background: linear-gradient(
        to bottom right,
        #00abe2,
        #45a0ea,
        #9493f3,
        #ce89f9,
        #f183fd,
        #ff81ff,
        #f982fe,
        #e785fc,
        #ca8af9,
        #a190f4,
        #6d99ee,
        #2fa3e7,
        #00abe2
      );
      border-color: #000;
    }
    .wake_up_form__icon_item {
      position: absolute;
      top: -6px;
      right: 0;
      height: 3px;
      width: 33px;
      background: #646668;
    }
    .wrapper_item_dot {
      display: flex;
      position: absolute;
      bottom: 2px;
      right: 10px;
      gap: 2px;
      .dot {
        width: 3px;
        height: 3px;
        background: #ffffff;
      }
    }
    .wake_up_form__button {
      background: linear-gradient(0deg, #f94409, #f94409);
    }
  }
  .wake_up_form__button__wrapper_active {
    border-image-slice: 1;
    border-width: 1px;
    border-image-source: linear-gradient(
      to right,
      #00abe2,
      #45a0ea,
      #9493f3,
      #ce89f9,
      #f183fd,
      #ff81ff,
      #f982fe,
      #e785fc,
      #ca8af9,
      #a190f4,
      #6d99ee,
      #2fa3e7,
      #f982fe
    );
    .wake_up_form__icon_item {
      background: linear-gradient(276.28deg, #ff81ff 5.74%, #1baae2 100%);
    }
  }
  .wake_up_form__button {
    position: absolute;
    right: 0;

    &.wake_up_form_submit__button {
      span {
        background: linear-gradient(0deg, #f94409, #f94409);
        line-height: 20px;
      }
    }
    &.isMinted {
      visibility: visible;
      opacity: 0.3;
    }

    &--active {
      span {
        background-color: #00abe2;
        color: #121317;
        font-weight: 500;
      }
    }
  }
  .wake_up__cover__section {
    flex-wrap: nowrap;
    height: calc(100vh - 130px);
    @media (max-width: 1024px) {
      height: 350px;
    }
    &--minted {
      @media (max-width: 1024px) {
        height: 450px;
      }
    }
    .wake_up__cover {
      width: 100%;
      height: 55%;
      top: 107px;
      @media (max-width: 1024px) {
        height: auto;
      }

      &.glitch .glitch__item {
        width: 80%;
        left: 10%;
        @media (max-width: 1024px) {
          width: 100%;
          left: 0;
        }
        background: url('/images/SimbiotesRockAbyssClipped.webp') no-repeat 50% 50% / cover;
      }
    }
  }
  .icon_info {
    pointer-events: auto !important;
    cursor: pointer !important;
    color: rgba(255, 255, 255, 0.6);
  }
`;
export const ScientistAvaWrapper = styled('div')`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.progress ? '#00ABE2' : 'rgba(255, 255, 255, 0.04)')};
  transition: all 0.3s ease-in-out;
  transform: skew(-20deg);
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;
export const LightningIconWrapper = styled(ScientistAvaWrapper)`
  background: linear-gradient(90deg, #f18303 0%, #f94409 100%);
  transform: skew(0deg);
`;
export const WakeUpStatus = styled('div')`
  position: relative;
  width: 100%;
  margin-top: 30px;
  height: ${(props) => `${props.distance}px` || 'unset'};
  @media screen and (max-width: 1024px) and (min-width: 900px) {
    margin-top: 40px;
  }
  .grid_line,
  .wake_up_status__text {
    width: fit-content;
    font-family: 'Anybody';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    &--warning {
      font-weight: 600;
      color: #f94409;
    }
  }
  .recently_minted_scientist {
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 1024px) {
      margin-top: 80px;
    }

    .scientist_card__wrapper {
      width: fit-content;
      height: 100%;
      background: transparent;
      position: relative;
      display: flex;
      align-items: flex-end;
      .scientist__cover {
        height: 150px;
        width: 150px;
        object-fit: cover;
        -webkit-box-shadow: 0px 0px 110px -12px #09ebf6;
        box-shadow: 0px 0px 110px -12px #09ebf6;
        margin-right: 20px;
        border-radius: 5px;
        @media (max-width: 1024px) {
          height: 100px;
          width: 100px;
        }
      }
      .created_card__title {
        font-family: 'Anybody';
        color: '#fff';
        font-weight: 500;
        font-size: 22px;
        line-height: 21px;
        margin-top: 20px;
        margin-bottom: 2px;
      }
      .created_card__id {
        margin-top: 0;
        margin-bottom: 10px;
      }
      .spec {
        font-weight: 400;
        font-size: 10px;
        line-height: 9px;
        margin-top: 8px;
        span:nth-of-type(1) {
          width: 150px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .content--highlight {
          color: #00abe2;
        }
      }
      &:before {
        content: '';
        background-color: #09ebf6;
        opacity: 0.7;
        width: 100%;
        height: 1px;
        filter: blur(1px);
        position: absolute;
        z-index: -1;
        bottom: -10px;
        left: 0;
      }
    }
  }
  .recently_minted_scientist_firefox {
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 1024px) {
      margin-top: 80px;
    }
    @media (min-height: 700px) {
      top: 65%;
    }

    .scientist_card__wrapper {
      width: fit-content;
      height: 100%;
      background: transparent;
      position: relative;
      display: flex;
      align-items: flex-end;
      .scientist__cover {
        height: 150px;
        width: 150px;
        object-fit: cover;
        -webkit-box-shadow: 0px 0px 110px -12px #09ebf6;
        box-shadow: 0px 0px 110px -12px #09ebf6;
        margin-right: 20px;
        border-radius: 5px;
        @media (max-width: 1024px) {
          height: 100px;
          width: 100px;
        }
      }
      .created_card__title {
        font-family: 'Anybody';
        color: '#fff';
        font-weight: 500;
        font-size: 22px;
        line-height: 21px;
        margin-top: 20px;
        margin-bottom: 2px;
      }
      .created_card__id {
        margin-top: 0;
        margin-bottom: 10px;
      }
      .spec {
        font-weight: 400;
        font-size: 10px;
        line-height: 9px;
        margin-top: 8px;
        span:nth-of-type(1) {
          width: 150px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .content--highlight {
          color: #00abe2;
        }
      }
      &:before {
        content: '';
        background-color: #09ebf6;
        opacity: 0.7;
        width: 100%;
        height: 1px;
        filter: blur(1px);
        position: absolute;
        z-index: -1;
        bottom: -10px;
        left: 0;
      }
    }
  }
`;

export const SelectFormItemsWrapper = styled('div')`
  margin-top: 70px;
  @media (max-width: 1600px) {
    margin-top: 30px;
  }
  @media (max-width: 1024px) {
    margin-top: 70px;
  }
  @media (max-width: 420px) {
    margin-top: 40px;
  }
  button:last-of-type {
    margin-bottom: 0;
  }
`;
export const SelectFormItemWrapper = styled('div')`
  margin-bottom: 10px;
  @media (max-width: 1600px) {
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;
export const SelectFormItemButton = styled(Button)`
  display: flex;
  position: relative;
  align-items: center;
  font-family: 'Anybody';
  color: #fff !important;
  opacity: ${(props) => (props.isdisabled ? 0.3 : 1)};
  width: fit-content;
  text-transform: none;
  padding: 14px 10px;
  margin-bottom: 40px;
  border-radius: 0;
  border: ${(props) => (props.isopenpopover ? '1px solid gray' : '')};
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(
    to right,
    #00abe2,
    #45a0ea,
    #9493f3,
    #ce89f9,
    #f183fd,
    #ff81ff,
    #f982fe,
    #e785fc,
    #ca8af9,
    #a190f4,
    #6d99ee,
    #2fa3e7
  );
  transform: skew(-20deg);
  .checkbox__icon {
    transform: skew(20deg);
    margin-right: 10px;
    @media (max-width: 1024px) {
      margin-right: 20px;
      width: 20px;
      height: 20px;
    }
  }
  .checkbox__label {
    position: relative;
    transform: skew(20deg);

    @media (max-width: 1024px) {
      font-size: 16px;
    }
    &:before {
      position: absolute;
      content: '';
      width: ${(props) => (props.isopenpopover ? `${props.linelength - 40}px` : '0px')};
      opacity: ${(props) => (props.isopenpopover ? 1 : 0)};
      height: 1px;
      background-color: #00abe2;
      top: 50%;
      left: calc(100% + 10px);
      z-index: 1;
      transition: all 0.3s ease-in-out;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    &:after {
      position: absolute;
      content: '';
      width: 10px;
      opacity: ${(props) => (props.isopenpopover ? 1 : 0)};
      height: 10px;
      background-color: #fff;
      top: 50%;
      transform: translateY(-50%);
      left: ${(props) => (props.isopenpopover ? `calc(100% + ${props.linelength - 32}px)` : '0px')};
      z-index: 1;
      transition: all 0.3s ease-in-out;
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
  &:hover {
    @media (min-width: 1025px) {
      border: 2px solid rgba(255, 255, 255, 0.16);
    }
    background: #000;
    opacity: 1;
  }
`;

export const CoverBackground = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #000;
  &:hover {
    background: #000;
    opacity: 1;
  }
  &:focus {
    background: #000;
    opacity: 1;
  }
`;

export const BorderWrapper = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;
  bottom: -7px;
  right: -7px;
  border: 1px solid rgba(255, 255, 255, 0.16);
`;

export const SelectableValuesWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 357px;
  flex-wrap: wrap;
  background-color: #1a1a1a;
  border-radius: 12px;
  padding: 20px;
  gap: 5px;
  font-family: 'Anybody';
  &:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 15px;
    left: -20px;
    top: calc(50% - 12px);
    background: transparent;
    transform: rotate(-90deg);
    z-index: 1;
    border: 10px solid transparent;
    border-bottom: 15px solid #191919;
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;
export const SpecItem = styled(Button)`
  position: relative;
  flex-basis: 100px;
  width: 100px;
  height: 100px;
  color: #fff;
  background: #0f0f0f;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  overflow: hidden;
  border: none;
  font-family: 'Anybody';
  filter: blur(${(props) => (props.blur ? '2px' : '0')});
  cursor: ${(props) => (props.blur ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.blur ? 'none' : 'auto')};
  .spec__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.4;
    object-fit: cover;
  }
  .spec__icon {
    margin-bottom: 10px;
    z-index: 1;
    width: 24px;
    height: 24px;
  }
  span {
    display: inline-block;
    font-family: 'Anybody';
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-transform: none;
    z-index: 1;
    transform: scaleY(1.2);
  }
  z-index: 2;
`;
export const SelectableRangeValuesWrapper = styled('div')`
  width: 350px;
  background-color: #1a1a1a;
  border-radius: 12px;
  padding: 20px;
  @media (max-width: 400px) {
    width: 310px;
    margin-left: 20px;
  }
  &:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 15px;
    left: -20px;
    top: calc(50% - 12px);
    background: transparent;
    transform: rotate(-90deg);
    z-index: 1;
    border: 10px solid transparent;
    border-bottom: 15px solid #191919;
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;
export const SpecRangeItem = styled('div')`
  margin-bottom: 13px;
  &:last-of-type {
    margin-bottom: 4px;
  }
  .spec__title {
    font-weight: 350;
    font-size: 14px;
    line-height: 13px;
    margin: 0;
    font-family: 'Anybody';
  }
`;

export const TooltipCustom = styled(Tooltip)`
  & .MuiTooltip-tooltip {
    margin-bottom: -5px !important;
  }
`;

export const SpecSlider = styled(Slider)`
  color: rgba(255, 255, 255, 0.06);
  height: 8;
  & .MuiSlider-rail {
    height: 10px;
    border-radius: unset;
    opacity: 1;
  }
  & .MuiSlider-mark {
    height: 10px;
    width: 2px;
    background: #000;
  }
  & .MuiSlider-markActive {
    visibility: visible;
    height: 10px;
    opacity: 1;
    background-color: #000;
    border-radius: unset;
  }
  & .MuiSlider-track {
    border: none;
    height: 10px;
    border-radius: unset;
    background-color: #00abe2;
  }
  & .MuiSlider-thumb {
    position: relative;
    height: 14px;
    width: 14px;
    background-color: #00abe2;
    border: 4px solid #191919;
    &:focus,
    &:hover,
    &.Mui-active,
    &.Mui-focusVisible {
      box-shadow: inherit;
    }
    &:before {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.16);
      z-index: 1;
    }
  }
  & .MuiSlider-valueLabel {
    display: ${(props) => (props.hidevaluelabel ? 'none' : 'flex')};
    color: #fff;
    font-family: 'Anybody';
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    background-color: #00abe2;
    padding: 6px 12px;
    border-radius: 4px;
    transform: translateY(-100%) scale(1);
  }
`;

export const LightingWrapper = styled('div')`
  pointer-events: none;
  width: 100%;
  height: 100vh;
  background: '#000';
  position: absolute;
  top: 0;
  left: 0;
  .ag-lightning {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .ag-lightning:before {
    content: '';
    opacity: 0;
    will-change: transform;
    position: absolute;
  }
  .ag-lightning:before {
    width: 550px;
    height: 850px;
    background-image: url('/images/Lightning.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: -8.75em;
    left: 50%;
    transform: translateX(-50%);
    -webkit-animation: an-lightning-first 0.3s linear 2.6s 1;
    -moz-animation: an-lightning-first 0.3s linear 2.6s 1;
    -o-animation: an-lightning-first 0.3s linear 2.6s 1;
    animation: an-lightning-first 0.3s linear 2.6s 1;
    /* animationHere */
  }
  @-webkit-keyframes an-lightning-first {
    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }
    40% {
      height: 55.375em;
      opacity: 1;
    }
    80% {
      height: 55.375em;
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes an-lightning-first {
    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }
    40% {
      height: 55.375em;
      opacity: 1;
    }
    80% {
      height: 55.375em;
    }
    100% {
      opacity: 0;
    }
  }
  @-o-keyframes an-lightning-first {
    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }
    40% {
      height: 55.375em;
      opacity: 1;
    }
    80% {
      height: 55.375em;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes an-lightning-first {
    0%,
    6.75% {
      height: 0;

      opacity: 0;
    }
    40% {
      height: 55.375em;
      opacity: 1;
    }
    80% {
      height: 55.375em;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const ScrollBottomGuide = styled('div')`
  .scroll_bottom_guide__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
  }

  position: fixed;
  bottom: 20px;
  left: 100px;
  z-index: 1;
  @media (max-width: 440px) {
    min-width: 105px;
    left: calc(50% - 52.5px);
  }
  p {
    font-family: 'Anybody';
    font-size: 16px;
    margin: 0;
  }
  .scroll_bottom__icon {
    transform: rotate(180deg) translateY(5px);
  }
`;

export const Trapezoid = styled('div')`
  width: 78px;
  height: 58px;
  transform: skew(-20deg);
  background: linear-gradient(
    138.9deg,
    #00abe2 13.46%,
    #45a0ea 22.18%,
    #9493f3 32.34%,
    #ce89f9 41.06%,
    #f183fd 47.59%,
    #ff81ff 50.5%,
    #f982fe 54.13%,
    #e785fc 58.48%,
    #ca8af9 63.57%,
    #a190f4 69.37%,
    #6d99ee 75.18%,
    #2fa3e7 81.72%,
    #00abe2 85.35%
  );
`;

export const ItemBorder = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.active
      ? 'background: linear-gradient(138.9deg,#00abe2 13.46%,#45a0ea 22.18%,#9493f3 32.34%,#ce89f9 41.06%,#f183fd 47.59%,#ff81ff 50.5%,#f982fe 54.13%,#e785fc 58.48%,#ca8af9 63.57%,#a190f4 69.37%,#6d99ee 75.18%,#2fa3e7 81.72%,#00abe2 85.35%);'
      : ''}
  padding: 1px;
  border-radius: 4px;
  z-index: 1;
  &:hover {
    background: transparent;
  }
`;

export const WrapperSpecItem = styled('div')`
  position: relative;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 4px;
  }
`;

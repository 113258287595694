import { Popover, Avatar, useMediaQuery } from '@mui/material';
import useGetScreenWidth from '@utils/useGetScreenWidth';
import { forwardRef, useCallback, useState } from 'react';
import { SelectableRangeValuesWrapper, SpecRangeItem, SpecSlider, TooltipCustom } from '../styles';

const PickRangeItemPopover = forwardRef(
  (
    {
      openSelectionTable = () => {},
      setOpenSelectionTable = () => {},
      onChangeRangeValue = () => {},
      selectableValues,
      name,
      value,
      modalLeftDistance,
    },
    ref
  ) => {
    const isSmallDevice = useMediaQuery('( max-width: 1024px)');
    const isSmallDeviceNotRotate = useMediaQuery('( max-width: 1024px) and ( min-height: 451px )');
    const isSmallDeviceRotateMode = useMediaQuery('( max-width: 1024px) and ( max-height: 450px )');
    const [titleSpec, setTitleSpec] = useState('');
    const valueLabelFormat = (value, slideItems) => {
      let formattedValue;
      if (value >= 0) {
        formattedValue = slideItems[value];
      } else formattedValue = null;
      return formattedValue;
    };

    const handleChangeRangeValue = useCallback((newValue, rangeName) => {
      onChangeRangeValue(name, rangeName, newValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSpecHover = (txt) => {
      if (!txt) {
        setTitleSpec('');
      } else setTitleSpec(txt);
    };
    return (
      <Popover
        id={name}
        open={openSelectionTable}
        anchorEl={ref.current}
        onClose={() => setOpenSelectionTable(false)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            overflow: 'visible',
            background: 'none',
            top: isSmallDeviceNotRotate ? '40% !important' : 'unset',
            bottom: isSmallDeviceRotateMode ? '10% !important' : 'unset',
            left: isSmallDevice
              ? 'calc(50vw - 175px) !important'
              : `${modalLeftDistance + 20}px !important`,
          },
        }}>
        <SelectableRangeValuesWrapper>
          {selectableValues.map((item) => {
            return (
              <SpecRangeItem key={item.name}>
                {item?.title ? <p className="spec__title">{item.title}</p> : null}
                {!item?.title && (
                  <Avatar
                    sx={{ position: 'absolute', left: '45%', top: '-24px' }}
                    alt="scientist-stress-avatar"
                    src="/images/scientist-stress-avatar.png"
                  />
                )}
                <TooltipCustom title={titleSpec} placement="top">
                  <SpecSlider
                    value={value[item.name] || 0}
                    aria-label={`${item.name}Slider`}
                    defaultValue={-1}
                    min={-1}
                    step={1}
                    max={item.max - 1}
                    getAriaValueText={(value) => valueLabelFormat(value, item.slideItems)}
                    valueLabelFormat={(value) => valueLabelFormat(value, item.slideItems)}
                    valueLabelDisplay="auto"
                    marks
                    onChange={(_, newValue) => {
                      if (newValue !== value[item.name])
                        handleChangeRangeValue(newValue, item.name);
                    }}
                    hidevaluelabel={value[item.name] === -1 ? 'hide' : ''}
                    onMouseOver={(_) => {
                      handleSpecHover(item.slideItems[_?.target?.getAttribute('data-index') - 1]);
                    }}
                  />
                </TooltipCustom>
              </SpecRangeItem>
            );
          })}
        </SelectableRangeValuesWrapper>
      </Popover>
    );
  }
);
PickRangeItemPopover.displayName = 'PickRangeItemPopover';

export default PickRangeItemPopover;

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const MetacellNameWrapper = styled('div')`
  position: absolute;
  bottom: 10%;
  left: 5%;
  text-align: center;
  justify-content: center;
  font-family: ${(p) => p?.isMintMetacell && "'Elemental End', Arial, sans-serif"};
  text-transform: lowercase;
  width: 100%;
  .MuiTypography-cardName {
    @media (max-width: 1445px) {
      justify-content: flex-start !important;
    }
  }
  .slide-left {
    @media (max-width: 1445px) {
      animation: slide-left 10s linear infinite;
    }
  }
  @keyframes slide-left {
    from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
`;

export const MintedShareBtnWrapper = styled('div')`
  position: absolute;
  bottom: 2%;
  left: calc(50% - 70px);

  .minted_share {
    opacity: 0.7;
    border-radius: 8px;
    padding: 0;
    width: 9rem;
    border: 1px solid #00ccff;
    span {
      font-family: ${(p) => p?.isMintMetacell && "'Elemental End', Arial, sans-serif"};
      text-transform: lowercase;
      font-size: 14px;
      color: #00ccff;
    }
    :hover {
      opacity: 1;
    }
  }

  .share {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px !important;
    justify-content: center;
    span {
      margin-left: 8px;
    }
  }
`;

export const ConnectWalletWrapper = styled('div')`
  position: fixed;
  top: 40%;
  left: calc(50% - 130px);
  color: #00ccff;
  * {
    font-family: ${(p) => p?.isMintMetacell && "'Elemental End', Arial, sans-serif"};
    text-transform: lowercase;
  }
`;

export const CollectButtonWrapper = styled('button')`
  background-image: url('/images/metacellMintButton.png') !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 246px;
  height: 64px;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;
  align-items: center;
  font-family: ${(p) => p?.isMintMetacell && "'Elemental End', Arial, sans-serif"};
  text-transform: lowercase;
  box-shadow: none;
  color: #ffffff;
`;
export const MetacellMintWrapper = styled('div')`
  .wrapper {
    width: 100vw;
    height: 100vh;
    background: #000;
    position: relative;
    overflow: hidden;
  }

  .wrapper__bg {
    background: transparent;
  }
  .in_whitelist {
    img {
      width: 20rem;
      height: 5rem;
      @media screen and (max-width: 576px) {
        width: 15rem;
      }
    }
  }

  .not_whitelist {
    img {
      width: 23rem;
      @media screen and (max-width: 576px) {
        width: 20rem;
      }
    }
    .start__button__text {
      font-size: 1rem;
      left: 50%;
      @media screen and (max-width: 576px) {
        font-size: 0.7rem;
      }
    }
  }
  .start__button {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: unset;
    padding: 0;
    cursor: pointer;
    outline: none;
    border: none;
    transition: all 0.3s ease-in-out;
    align-items: center;

    &:hover {
      transform: translate(-50%, -50%) scale(1.1);
    }

    &--hide {
      opacity: 0;
      pointer-events: none;
    }

    &__text {
      font-family: ${(p) => p?.isMintMetacell && "'Elemental End', Arial, sans-serif"};
      text-transform: lowercase;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 18px;
      @media screen and (max-width: 576px) {
        font-size: 14px;
      }
    }
  }

  .connect__button {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .bg_metacell_mint {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .minted_meta_cell__wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .bg_player__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: auto;
    overflow: hidden;
    background-size: cover !important;

    .bg_player {
      display: flex;
      align-items: center;
      video {
        object-fit: cover;
        height: auto !important;
      }
    }
  }
`;

export const ModalCountdownWrapper = styled('div')`
  .modal_login__wrapper {
    background-color: rgba(0, 0, 0, 0.6);
  }

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    .modal_login__wrapper {
      background-color: rgba(255, 255, 255, 0.1);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
    }
  }
`;

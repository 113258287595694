import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSpring, animated } from 'react-spring';
import { useDispatch } from 'react-redux';
import { ethers } from 'ethers';
import { Button, ClickAwayListener } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import { useLocation } from 'react-router-dom';
import { Metamask, ScrollTopIcon } from '@components/Icons';
import Wallet from '@components/Icons/Wallet';
import useConnectors from '@utils/useConnectors';
import useGetWalletIcon from '@utils/useGetWalletIcon';
import { formatPrice } from '@utils';
import { openLoginModal } from '@providers/ModalsProvider';
import {
  ButtonConnect,
  ScientistMintHeaderWrapper,
  WrapperImage,
  WrapperTextButton,
} from './styles';
import { useStore } from '../../store';

const ScientistMint = ({ handleRedirectHome = () => {}, handleSetErrorMessage = () => {} }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { account, connector } = useWeb3React();
  const [open, setOpen] = useState(false);
  const [activatingConnector, setActivatingConnector] = useState();
  const { disconnect } = useConnectors();
  const { walletIcon } = useGetWalletIcon();
  const { usersBalance = 0 } = useStore();

  const headerStyles = useSpring({
    delay: 500,
    to: { y: 0, opacity: 1 },
    from: { y: -100, opacity: 0 },
    config: {
      mass: 8,
      tension: 166,
      friction: 52,
      precision: 0.001,
      velocity: 0.002,
    },
  });

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const slicedAccount = (account) => {
    let slicedAcc = '';
    if (account) {
      const arr = account.split('');
      slicedAcc = arr.slice(0, 5).concat('....', arr.slice(-4)).join('');
    }
    return slicedAcc;
  };

  const onRedirectHome = useCallback(() => {
    handleRedirectHome();
  }, [handleRedirectHome]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  return (
    <ScientistMintHeaderWrapper isMintMetacell={location.pathname?.includes('metacell-mint')}>
      <animated.div style={headerStyles} className="wrapper">
        <Button className="scroll__icon" onClick={onRedirectHome}>
          {/* <ScrollTopIcon /> */}
          <img src="/images/logo.png" alt="text_logo" className="text__logo" />
        </Button>
        {account ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div style={{ position: 'relative' }}>
              {/* <Button
                variant="contained"
                startIcon={walletIcon}
                className="connected__button"
                onClick={handleClick}>
                
              </Button> */}
              <ButtonConnect>
                <WrapperImage>{walletIcon}</WrapperImage>
                <WrapperTextButton
                  isMintMetacell={location.pathname?.includes('metacell-mint')}
                  onClick={handleClick}>
                  <span>{slicedAccount(account).toLowerCase()}</span>
                  <img
                    src="icons/arrow-down-icon.svg"
                    alt="arrow_down"
                    className="connected__button_arrow__icon"
                  />
                </WrapperTextButton>
              </ButtonConnect>
              {open ? (
                <div className="popup__wrapper">
                  <div className="popup__content">
                    <Button className="popup__button balance">
                      {`${formatPrice(ethers.utils.formatEther(usersBalance), 4)} BIOMETA`}
                    </Button>
                    <Button className="popup__button" onClick={disconnect}>
                      Disconnect wallet
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
        ) : (
          <ButtonConnect>
            <WrapperImage>
              <Wallet width={24} />
            </WrapperImage>
            <WrapperTextButton
              isMintMetacell={location.pathname?.includes('metacell-mint')}
              onClick={() => dispatch(openLoginModal())}>
              Connect Power
            </WrapperTextButton>
          </ButtonConnect>
        )}
      </animated.div>
    </ScientistMintHeaderWrapper>
  );
};

export default ScientistMint;

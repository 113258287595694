import { useState } from 'react';
// import AccountCard from '../AccountCard';
// import { Logo, Account, Admin } from '../Icons';
import { useMediaQuery } from '@mui/material';
// import ListMenu from './ListMenu';
import HeaderMobile from './HeaderMobile';
import HeaderDesktop from './HeaderDesktop';

const Header = (props) => {
  const isMobile = useMediaQuery('(max-width:1100px)');
  return <>{isMobile ? <HeaderMobile /> : <HeaderDesktop />}</>;
};
export default Header;

import React from 'react';

export const useEffectOnMount = (cb, deps) => {
  const ref = React.useRef(true);

  React.useEffect(() => {
    if (!ref.current) {
      cb();
      return;
    }
    ref.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, ...deps]);
};

import AWS, { S3 } from 'aws-sdk';
import { environment } from '../environment';

const region = environment.AWS_REGION;
export const awsLink = `${region}.amazonaws.com`;

const spacesEndpoint = new AWS.Endpoint(awsLink);
export const s3Instance = new S3({
  endpoint: spacesEndpoint,
  accessKeyId: environment.SPACES_KEY,
  secretAccessKey: environment.SPACES_SECRET,
});

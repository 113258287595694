import { openErrorModal, closeErrorModal, setErrorMessage } from '@providers/ModalsProvider';
import { environment } from '../environment';

const setupNetwork = async (externalProvider, dispatch = () => {}, disconnect) => {
  const provider = externalProvider || window.ethereum;

  if (provider) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${Number(environment.CURRENT_NETWORK_ID).toString(16)}` }],
      });
      dispatch(setErrorMessage(''));
      dispatch(closeErrorModal());
      return true;
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${Number(environment.CURRENT_NETWORK_ID).toString(16)}`,
                chainName: environment.CURRENT_NETWORK_NAME,
                nativeCurrency: {
                  name: 'ETH',
                  symbol: environment.CURRENT_NETWORK_SYMBOL,
                  decimals: Number(environment.CURRENT_NETWORK_DECIMALS),
                },
                rpcUrls: [environment.CURRENT_NETWORK_RPC_URL],
                blockExplorerUrls: [environment.CURRENT_NETWORK_BLOCK_EXPLORER],
              },
            ],
          });
          dispatch(setErrorMessage(''));
          dispatch(closeErrorModal());
          return true;
        } catch (error) {
          disconnect();
          dispatch(setErrorMessage(error.message));
          dispatch(openErrorModal());
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      }
      //   else if (switchError.code === 4100) {
      //     dispatch(
      //       setErrorMessage('Your switch ethereum chain request already pending. Please wait.')
      //     );
      //     dispatch(openErrorModal());
      // }
      else {
        if (switchError?.message?.includes('isApproved')) {
          dispatch(setErrorMessage('User Cancel Switch Network'));
        } else {
          dispatch(setErrorMessage(switchError.message));
        }
        disconnect();
        dispatch(openErrorModal());
      }
    }
  } else {
    console.error("Can't setup the network on metamask because window.ethereum is undefined");
    return false;
  }
};

export default setupNetwork;

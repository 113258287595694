import { Divider } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { ScientistDefaultBg } from './default';
import { ScientistAnimation } from './ScientistAnimation';

export const ScientistMintAnimation = ({ wakeUpCoverRef }) => {
  const { fieldValue, fieldName } = useSelector((state) => state.scientist);

  return (
    <>
      {' '}
      <Divider
        sx={{
          zIndex: 15,
          color: 'red',
          width: '100%',
          position: 'absolute',
          top: '0%',
        }}
        light
      />
      <div className="glitch wake_up__cover" ref={wakeUpCoverRef}>
        {!fieldName || fieldValue < 0 ? (
          <ScientistDefaultBg />
        ) : (
          <ScientistAnimation {...{ fieldName, fieldValue }} />
        )}
      </div>
    </>
  );
};

/* eslint-disable no-nested-ternary */
import { useMetacellStatus } from '@hooks/metacell/useMetacellStatus';
import { getMetaCellProof, resetState } from '@providers/MetaCellProvider';
import { useWeb3React } from '@web3-react/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffectOnMount } from './useEffectOnMount';

const MintMetacellContext = React.createContext();

export const useMintMetacellContext = () => {
  const context = React.useContext(MintMetacellContext);

  if (!context) {
    throw new Error('This component cannot be used outside Context');
  }

  return context;
};

export const MintMetacellWrapper = ({ children }) => {
  const [openMintedModal, setOpenMintedModal] = useState(false);
  const [isMinted, setIsMinted] = useState(false);

  const [openCountdownModal, setOpenCountdownModal] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [messageNotify, setMessageNotify] = useState({ show: false, message: '', type: 'confirm' });
  const { account, chainId } = useWeb3React();
  const dispatch = useDispatch();

  const {
    listAccountMetacell,
    getOwnerMetacell,
    setListAccountMetacell,
    claimProofIdx,
    remainingAmount,
  } = useMetacellStatus({
    account,
    updated: openMintedModal,
  });
  const cleanup = useCallback(() => {
    dispatch(resetState());
    setIsMinted(false);
  }, [dispatch]);

  useEffectOnMount(() => {
    cleanup();
  }, [account, chainId, cleanup]);

  const handleGetMetaCell = () => {
    setOpenMintedModal(false);
    setIsMinted(true);
  };

  useEffectOnMount(() => {
    if (account && !openCountdownModal) {
      dispatch(
        getMetaCellProof({
          address: account,
          chainId,
        })
      );
    }
  }, [account, chainId, dispatch, openCountdownModal]);

  const value = useMemo(
    () => ({
      wallet: {
        account,
        chainId,
      },
      modal: {
        setShowConfirmModal,
        handleGetMetaCell,
        showConfirmModal,
        setMessageNotify,
        messageNotify,
        setOpenMintedModal,
        openMintedModal,
        openCountdownModal,
        setOpenCountdownModal,
      },
      metacell: {
        isMinted,
        claimProofIdx,
        remainingAmount,
        setIsMinted,
        listAccountMetacell,
        getOwnerMetacell,
        setListAccountMetacell,
      },
      cleanup,
    }),
    [
      account,
      chainId,
      showConfirmModal,
      messageNotify,
      openMintedModal,
      isMinted,
      claimProofIdx,
      remainingAmount,
      listAccountMetacell,
      getOwnerMetacell,
      setListAccountMetacell,
      cleanup,
      openCountdownModal,
    ]
  );

  return <MintMetacellContext.Provider value={value}>{children}</MintMetacellContext.Provider>;
};

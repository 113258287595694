import { styled } from '@mui/material/styles';

export const UserSettingsWrapper = styled('div')`
  .user_nickName {
    width: auto;
    margin-top: 40px;
    padding-bottom: 7.12px;

    &__text {
      display: inline-block;
      max-width: 250px;
      justify-content: flex-start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
      &:hover {
        direction: rtl;
      }
    }
  }
  .userName__input {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-color: unset !important;
    }
  }
  .user_confirmed_email {
    input {
      -webkit-text-fill-color: unset;
    }
  }
  .game_banner {
    width: 100%;
    border-radius: 10px;
  }
  .game_link {
    margin-left: 5px;
    color: #09ebf6;
  }
`;

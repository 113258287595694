import { MintedScientistModal, ScientistMintHeader } from '@components';
import { useDebounce } from '@hooks/useDebounce';
import { ScientistSlice, useSelectScientist } from '@providers/ScientistProvider';
import { ITEM_NAME_STORAGE } from '@shared/itemNameLocalStorage';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ScientistContextWrapper } from './contexts/ScientistProvider';
import { ScientistAwake } from './ScientistAwake';
import ScientistCountdownModal from './ScientistCountdownModal';
import { ScientistNotifyModal } from './ScientistNotifyModal';
import { ScientistSecondSession } from './ScientistSecondSession';
import { Contact, ScientistMintWrapper, SocialContacts } from './styles';
import { socialContacts } from './__mocks/scientistMintMockValues';

const ScientistMint = () => {
  const { account, chainId } = useWeb3React();
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const {
    deadline,
    preWertOrders = [],
    mintingTime,
    proofs = [],
    isLoading,
  } = useSelectScientist();
  const dispatch = useDispatch();

  const isNotify = useDebounce(
    !isLoading && (deadline > Date.now() || mintingTime > Date.now() || proofs.length === 0),
    0
  );

  useEffect(() => {
    document.title = 'Mad Metaverse - Scientist Mint';
  }, []);
  useEffect(() => {
    if (preWertOrders.length > 0)
      toast.custom(`You have ${preWertOrders.length} mint transactions, Please check it`);
  }, [preWertOrders.length]);

  useEffect(() => {
    if (account) {
      dispatch(
        ScientistSlice.actions.getScientistIdProof({
          address: account,
          chainId,
        })
      );
    }
    if (account)
      dispatch(
        ScientistSlice.actions.getWertOrders({
          address: account,
          chainId,
        })
      );
  }, [account, dispatch, chainId]);

  const isConnectPower = useMemo(() => {
    const walletConnect = localStorage.getItem(ITEM_NAME_STORAGE.connectedWalletStatus);

    Object.values(JSON.parse(walletConnect || '{}')).forEach((val) => Boolean(val));

    if (account) return true;

    return false;
  }, [account]);

  const [errorMessage, setErrorMessage] = useState('');

  const [showErrorModal, setShowErrorModal] = useState(false);

  const onRedirectHome = () => {
    navigate('/scientist-mint');
  };

  const onHandleSetErrorMessage = (err) => {
    setErrorMessage(err);
    setShowErrorModal(true);
  };
  console.log({ isLoading, isConnectPower });
  return (
    <ScientistMintWrapper ref={wrapperRef}>
      <ScientistMintHeader
        handleRedirectHome={onRedirectHome}
        handleSetErrorMessage={onHandleSetErrorMessage}
      />
      {!isConnectPower && (
        <>
          <ScientistSecondSession />
          <SocialContacts>
            <p className="social_contact__title">[ JOIN SOCIAL ]</p>
            {socialContacts.map((item) => (
              <Contact key={item.icon}>
                <a href={item.link} target="_blank" rel="noreferrer">
                  <img src={`/icons/${item.icon}`} alt={item.link} className="social__icon" />
                  <img src="/icons/folder-icon.svg" alt={item.link} />
                </a>
              </Contact>
            ))}
          </SocialContacts>
        </>
      )}
      {!isLoading && isConnectPower && (
        <>
          {!isNotify ? (
            <>
              <ScientistContextWrapper>
                <ScientistAwake wrapperRef={wrapperRef} />
              </ScientistContextWrapper>
              <MintedScientistModal
                open={showErrorModal}
                showConfirmModal={false}
                showErrorModal={showErrorModal}
                setShowErrorModal={setShowErrorModal}
                message={errorMessage}
                mining={false}
              />
            </>
          ) : (
            <>
              <ScientistSecondSession />
              <ScientistNotifyModal />
              <ScientistCountdownModal />
            </>
          )}
        </>
      )}
    </ScientistMintWrapper>
  );
};

export default ScientistMint;

import { environment } from '@environment/';
import { createAction } from '@reduxjs/toolkit';
import request from '@utils/request';
import _omit from 'lodash/omit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { asyncTransactionHashFailed, asyncTransactionHashSuccess, transactionSlice } from '.';

export const sendTransactionInfoAction = createAction('transactions/asyncTransactionHash');

function* sendTransactions({ payload }) {
  try {
    console.log(transactionSlice.actions.asyncTransactionHash.name);
    const params = _omit(payload, ['isUser', 'userAddress']);

    yield call(
      request,
      `${environment.BE_SERVER_URL}/api/transactions/transaction`,
      { ...params },
      { method: 'Post' }
    );

    yield put(asyncTransactionHashSuccess());
  } catch (e) {
    console.log(e);
    yield put(asyncTransactionHashFailed());
  }
}

export function* transactionWatcher() {
  yield takeEvery(sendTransactionInfoAction, sendTransactions);
}

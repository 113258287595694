import { useWeb3React } from '@web3-react/core';
import { Contract } from 'ethers';
import ScientistMarketplaceABI from '../abis/Ethereum/MarketplaceScientist.json';
import RepositoryABI from '../abis/Ethereum/Repository.json';
import MADABI from '../abis/MDMA.json';
import MultiCallABI from '../abis/MultiCall.json';
import LaboratoryABI from '../abis/Polygon/Laboratory.json';
import MetacellMarketplaceABI from '../abis/Polygon/Marketplace.json';
import MetaCellABI from '../abis/Polygon/MetaCell.json';
import ModuleABI from '../abis/Polygon/Module.json';
import NanoCellABI from '../abis/Polygon/NanoCell.json';
import ScientistABI from '../abis/Scientist.json';
import { environment } from '../environment';

const getSigner = (library, account) => library.getSigner(account);

const getProviderOrSigner = (library, account) => (account ? getSigner(library, account) : library);

const useGetContracts = () => {
  const { library, account, chainId } = useWeb3React();

  if (!library || !account) return {};

  const providerOrSigner = getProviderOrSigner(library, account);

  if (chainId === Number(environment.CURRENT_NETWORK_ID)) {
    const MetacellMarketplace = new Contract(
      environment.ETH_MARKETPLACE_ADDRESS,
      MetacellMarketplaceABI,
      providerOrSigner
    );

    const Laboratory = new Contract(
      environment.LABORATORY_ADDRESS,
      LaboratoryABI,
      providerOrSigner
    );

    const MarketplaceScientist = new Contract(
      environment.SCIENTIST_MARKETPLACE_ADDRESS,
      ScientistMarketplaceABI,
      providerOrSigner
    );

    const Repository = new Contract(
      environment.REPOSITORY_ADDRESS,
      RepositoryABI,
      providerOrSigner
    );

    const Scientist = new Contract(
      environment.PROXY_SCIENTIST_ADDRESS,
      ScientistABI,
      providerOrSigner
    );

    const MDMA = new Contract(environment.BIOMETA_ADDRESS, MADABI, providerOrSigner);

    const MetaCell = new Contract(environment.METACELL_ADDRESS, MetaCellABI, providerOrSigner);

    const NanoCell = new Contract(environment.NANOCELL_ADDRESS, NanoCellABI, providerOrSigner);

    const Module = new Contract(environment.MODULE_ADDRESS, ModuleABI, providerOrSigner);

    const MultiCall = new Contract(environment.MULTICALL_ADDRESS, MultiCallABI, providerOrSigner);

    const MetaCellAirDrop = new Contract(
      environment.METACELL_ADDRESS,
      MetaCellABI,
      providerOrSigner
    );

    const handleAggregateCall = async (data = []) => {
      const tuple = [];
      data.forEach((call) => {
        const item = [call.target, call.callData];
        tuple.push(item);
      });
      const { returnData } = await MultiCall.callStatic.aggregate(tuple);
      return returnData;
    };

    return {
      PolygonMarketplace: MetacellMarketplace,
      Laboratory,
      MDMA,
      MetaCell,
      NanoCell,
      Module,
      MarketplaceScientist,
      Repository,
      Scientist,
      MultiCall,
      MetaCellAirDrop,
      handleAggregateCall,
    };
  }

  return {};
};

export default useGetContracts;

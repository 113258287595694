import useGetInfoScientist from '@hooks/useGetInfoScientist';
import { Box, useMediaQuery } from '@mui/material';
import { TwitterIcon, TwitterShareButton } from 'react-share';
import { animated } from 'react-spring';
import { WakeUpStatus } from '../styles';
import { specsAvailableValues } from '../__mocks/scientistMintMockValues';
import { useSpringStyles } from './useSpringStyles';

export const ScientistMintStatus = ({
  isMinted,
  mintedScientist,
  isOpenModalMintedScientist,
  checkFormStatus,
  distanceFromWakeUpCoverToEndPage,
  introView,
  inScientistWhiteList,
  scientistId,
  account,
  isInAnimating,
}) => {
  const { stylesMintedScientistCard } = useSpringStyles(introView);
  const info = useGetInfoScientist(account, isMinted, isInAnimating);
  const isSmallDevice = useMediaQuery('( max-width: 1024px )');
  const isTabletDevice = useMediaQuery('( max-width: 900px )');
  const isFirefox = typeof InstallTrigger !== 'undefined';

  return (
    <>
      <WakeUpStatus
        distance={isSmallDevice ? (isMinted ? 350 : 150) : `${distanceFromWakeUpCoverToEndPage}`}>
        {!isOpenModalMintedScientist && isMinted && mintedScientist.isShow ? (
          <animated.div
            className={`recently_minted_scientist${!isFirefox ? '' : '_firefox'}`}
            style={stylesMintedScientistCard}>
            <Box className="scientist_card__wrapper">
              <img
                src={`${process.env.REACT_APP_SCIENTIST_IMAGES_URL}/${scientistId}.gif`}
                alt=""
                className="scientist__cover"
              />
              <div>
                <p className="created_card__title">
                  {scientistId ? `Scientist #${scientistId}` : 'Scientist undefined'}
                </p>
                <div className="spec">
                  <span>Token ID </span>
                  <span className="content--highlight">{scientistId || 'undefined'}</span>
                </div>
                <div className="spec">
                  <span>Scientist Field </span>
                  <span className="content--highlight">
                    {specsAvailableValues.scientist[mintedScientist?.scientificField]?.title ||
                      'undefine'}
                  </span>
                </div>
                <div className="spec">
                  <span>Personality Disorders </span>
                  <span className="content--highlight">
                    {specsAvailableValues.personality[mintedScientist?.personalityDiorders]
                      ?.title || 'undefined'}
                  </span>
                </div>
                <div className="spec">
                  <span>Obedience To Authority </span>
                  <span className="content--highlight">
                    {specsAvailableValues.psychological[0]?.slideItems[
                      mintedScientist?.obedienceToAuthority
                    ] || 'undefined'}
                  </span>
                </div>
                <div className="spec">
                  <span>Obedience To Conventions </span>
                  <span className="content--highlight">
                    {specsAvailableValues.psychological[1]?.slideItems[
                      mintedScientist?.obedienceToConventions
                    ] || 'undefined'}
                  </span>
                </div>
                <div className="spec">
                  <span>Tendency Toward Violence </span>
                  <span className="content--highlight">
                    {specsAvailableValues.psychological[2]?.slideItems[
                      mintedScientist?.tendencyTowardViolence
                    ] || 'undefined'}
                  </span>
                </div>
                <div className="spec">
                  <span>Vice </span>
                  <span className="content--highlight">
                    {specsAvailableValues.vices[mintedScientist?.vice]?.title || 'undefined'}
                  </span>
                </div>
                <div className="spec">
                  <span>Magnitude Of Stress </span>
                  <span className="content--highlight">
                    {specsAvailableValues.magnitude[0]?.slideItems[
                      mintedScientist?.magnitudeOfStress
                    ] || 'undefined'}
                  </span>
                </div>
              </div>
            </Box>
            {!isOpenModalMintedScientist && isMinted && mintedScientist.isShow && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',

                  '.twitter-share': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #00ccff !important',
                    padding: '5px 10px !important',
                    borderRadius: '8px',
                    width: '116px',
                    gap: '8px',
                    opacity: 0.7,
                  },
                  '.twitter-share:hover': {
                    opacity: 1,
                  },
                }}>
                <TwitterShareButton
                  className="twitter-share"
                  url={`${process.env.REACT_APP_BE_SERVER_URL}/scientists/video/${scientistId}`}
                  title={`Here is my MAD Metascientist! A Unique Video-Generative NFT from @MADMetaverse\n #EvolvingNFTs\n`}>
                  <TwitterIcon size={32} round />
                  <span>Tweet</span>
                </TwitterShareButton>
              </Box>
            )}
          </animated.div>
        ) : (
          <>
            <span
              className={`wake_up_status__text ${
                inScientistWhiteList === false ? 'wake_up_status__text--warning' : ''
              }`}>
              {inScientistWhiteList === false
                ? 'YOU ARE NOT IN WHITELIST'
                : info?.remainingAmount === 0
                ? 'SOLD OUT'
                : ''}
            </span>
            {(inScientistWhiteList === false || info?.remainingAmount === 0) && (
              <img className="grid_line" src="/images/grid-line.svg" alt="Grid Line" />
            )}
          </>
        )}
      </WakeUpStatus>
    </>
  );
};

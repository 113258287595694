import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { environment } from '@environment/';
import request from '@utils/request';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { getMetaCellProofSuccess, getMetaCellProofFailure } from '.';

export const getMetaCellProofAction = createAction('metaCell/getMetaCellProof');

function* getProof(action) {
  try {
    const address = _get(action, 'payload.address');
    const chainId = _get(action, 'payload.chainId', 42);
    const data = yield call(
      request,
      `${environment.BE_SERVER_URL}/api/meta-cells/proofs/${address}?chainId=${chainId}`,
      {},
      { method: 'GET' }
    );
    yield put(getMetaCellProofSuccess(data));
  } catch (error) {
    yield put(getMetaCellProofFailure(error));
  }
}

export function* MetaCellWatcher() {
  yield takeLatest(getMetaCellProofAction, getProof);
}

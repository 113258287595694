import { Button, Grid, Modal, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useMintMetacellContext } from './MintMetacellWrapper';
import { CollectButtonWrapper } from './styles';

const MetacellCollectedModal = () => {
  const location = useLocation();
  const {
    modal: { openMintedModal, setOpenMintedModal, handleGetMetaCell },
  } = useMintMetacellContext();

  const stylesMintedMetaCellCover = useSpring({
    loop: true,
    to: [{ transform: 'translateY(-15px)' }, { transform: 'translateY(0px)' }],
    from: {
      transform: 'translateY(0px)',
    },
    config: { duration: 2000 },
  });

  return (
    <>
      <Modal
        open={openMintedModal}
        onClose={() => {
          setOpenMintedModal(false);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          className="modal__content"
          sx={{
            height: '100vh',
          }}>
          <Typography
            sx={{
              fontSize: '14px',
              mb: '20px',
              fontFamily:
                location?.pathname === '/metacell-mint' && `${'Elemental End'}, Arial, sans-serif`,
              textTransform: 'lowercase',
              textAlign: 'center',
              whiteSpace: 'normal',
            }}>
            A METACELL HAS BEEN MINTED SUCCESSFULLY !
          </Typography>
          <CollectButtonWrapper
            isMintMetacell={location.pathname?.includes('metacell-mint')}
            variant="contained"
            disabled={false}
            className="modal_confirm__button"
            onClick={handleGetMetaCell}>
            Collect
          </CollectButtonWrapper>
        </Grid>
      </Modal>
    </>
  );
};

export default MetacellCollectedModal;

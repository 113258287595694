import { Button, Grid, Modal, Typography } from '@mui/material';
import { animated } from 'react-spring';

export const CollectedScientistModal = ({
  isOpenModalMintedScientist,
  setIsOpenModalMintedScientist,
  stylesMintedScientistCover,
  handleGetScientist,
}) => {
  return (
    <>
      <Modal
        open={isOpenModalMintedScientist}
        onClose={() => {
          setIsOpenModalMintedScientist(false);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          className="modal__content"
          sx={{
            height: '100vh',
          }}>
          <Typography
            sx={{
              fontSize: '14px',
              mb: '20px',
            }}>
            A SCIENTIST HAS BEEN MINTED SUCCESSFULLY !
          </Typography>
          <animated.img
            src="images/WakeUpScientistLogo.png"
            alt="scientist_logo"
            height={200}
            style={stylesMintedScientistCover}
          />

          <Button
            variant="contained"
            disabled={false}
            className="modal_confirm__button"
            sx={{
              outline: '1px solid rgba(255, 255, 255, 0.2)',
              outlineOffset: '2px',
              background: '#00ABE2',
              borderRadius: '20px',
              width: '154px',
              height: '36px',
              fontSize: '15px',
              lineHeight: '17.89px',
              fontWeight: 600,
              mt: '20px',
              '&:hover': {
                outlineColor: '#B9B9B9',
                background: '#09EBF6',
              },
              '&:active': {
                outlineColor: '#B9B9B9',
                background: '#09EBF6',
              },
              '&:disabled': {
                color: 'rgba(255, 255, 255, 0.2)',
                background: '#444547',
              },
            }}
            onClick={handleGetScientist}>
            Collect
          </Button>
        </Grid>
      </Modal>
    </>
  );
};

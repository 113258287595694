/* eslint-disable no-unsafe-finally */
import { ethers } from 'ethers';

export default async (tokenId, marketplace, nanocell) => {
  let response;
  let metadata;
  let price;

  try {
    price = await marketplace.getNanoCellPrice(tokenId);
    price = ethers.utils.formatEther(price);
    const uri = await nanocell.tokenURI(tokenId);
    response = await fetch(uri);

    metadata = response.status === 200 ? await response.json() : {};
  } catch (error) {
    metadata = {};
  } finally {
    const { name, image, attributes = [] } = metadata;

    const data = {
      id: tokenId.toNumber(),
      type: 'nanocell',
      price,
      onSale: Boolean(price),
      name,
      image,
    };

    attributes?.forEach((element) => {
      data[element.trait_type] = element.value;
    });

    return data;
  }
};

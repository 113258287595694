import { useContracts } from '@providers/ContractsProvider';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useScientistStatus = () => {
  const { proofs = [], scientistAccountId: account } = useSelector((state) => state.scientist);
  const { Scientist, handleAggregateCall } = useContracts();
  const [claimSignatureIdx, setClaimSignatureIdx] = useState(-2);

  useEffect(() => {
    const handleMultiCallForClaimable = async (signatureList = [], scientistAccountId) => {
      setClaimSignatureIdx(-2);
      const proofExpireTuple = signatureList.map((signature, idx) => ({
        target: Scientist.address,
        callData: Scientist.interface.encodeFunctionData('claimable', [
          scientistAccountId,
          signature,
          idx,
        ]),
      }));

      const aggregateCallData = [...(await handleAggregateCall(proofExpireTuple))].map(
        (o) => Scientist.interface.decodeFunctionResult('claimable', o)[0]
      );
      const signatureIdx = aggregateCallData.findIndex((o) => o);

      setClaimSignatureIdx(signatureIdx);
    };

    if (account && proofs?.length > 0) handleMultiCallForClaimable(proofs, account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proofs, account]);
  return {
    claimSignatureIdx,
    isLoading: claimSignatureIdx === -2,
  };
};

import { Button, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import useGetWalletIcon from '@utils/useGetWalletIcon';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LoginContentWrapper } from '../LoginModal/styles';
import { ConnectWalletIcon } from '../LoginModal/WalletButton';
import { ButtonOptionDisableStyled, ButtonOptionStyled } from './style';

export default function MinOptionModal({
  open,
  onCloseClick,
  onHandleFireWakeUp,
  onHandleFireWithWert,
  isMintFree,
}) {
  const mobile = useMediaQuery('(max-width: 678px)');
  const location = useLocation();
  const [isMintMetacell, setIsMintMetacell] = useState(false);
  const { walletIcon } = useGetWalletIcon(40);
  const handleClose = () => {
    onCloseClick();
  };
  useEffect(() => {
    if (location?.pathname !== '/metacell-mint') {
      setIsMintMetacell(false);
    } else {
      setIsMintMetacell(true);
    }
  }, [location]);
  return (
    <Modal
      open={open}
      BackdropProps={{ sx: { background: 'rgba(0,0,0,0.7)' } }}
      sx={
        isMintMetacell
          ? {
              overflow: 'auto',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': { display: 'none' },
              fontFamily: 'Elemental End, Arial, sans-serif',
              textTransform: 'lowercase',
            }
          : {
              overflow: 'auto',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': { display: 'none' },
              fontFamily: 'Anybody',
            }
      }
      onClose={handleClose}>
      <LoginContentWrapper>
        <Grid
          container
          direction="column"
          alignItems="center"
          className="modal_login__wrapper"
          sx={{
            maxWidth: mobile ? '90vw' : 'fit-content',
            background: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(20px)',
            borderRadius: '14px',
            p: '20px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '&:focus-visible': {
              outline: 'none',
            },
          }}>
          <Typography
            sx={{
              ml: mobile ? '10px' : '30px',
              height: '24px',
              fontSize: mobile ? '18px' : '22px',
              fontWeight: 600,
            }}>
            Choose Option Payment
          </Typography>
          <Box
            display="flex"
            marginTop="1rem"
            gap="1rem"
            sx={{ flexDirection: mobile ? 'column' : 'row' }}>
            <ButtonOptionStyled
              onClick={onHandleFireWakeUp}
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding="10px 22px">
              {walletIcon}
              <Typography
                variant="modalPrice"
                sx={{
                  ml: mobile ? '10px' : '30px',
                  height: '24px',
                  justifyContent: 'flex-start',
                  fontSize: mobile ? '14px' : '18px',
                  fontWeight: 500,
                  paddingTop: '7px',
                }}>
                Mint By Wallet
              </Typography>
            </ButtonOptionStyled>
            {!isMintFree ? (
              <ButtonOptionStyled
                onClick={onHandleFireWithWert}
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding="10px 22px">
                <img
                  style={{ width: '40px', height: '40px' }}
                  src="/images/wert.jpg"
                  alt="wallet connect icon"
                />
                <Typography
                  variant="modalPrice"
                  sx={{
                    ml: mobile ? '10px' : '30px',
                    height: '24px',
                    justifyContent: 'flex-start',
                    fontSize: mobile ? '14px' : '18px',
                    fontWeight: 500,
                    paddingTop: '7px',
                  }}>
                  Mint By Card
                </Typography>
              </ButtonOptionStyled>
            ) : (
              <ButtonOptionDisableStyled
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding="10px 22px">
                <img
                  style={{ width: '40px', height: '40px' }}
                  src="/images/wert.jpg"
                  alt="wallet connect icon"
                />
                <Typography
                  variant="modalPrice"
                  sx={{
                    ml: mobile ? '10px' : '30px',
                    height: '24px',
                    justifyContent: 'flex-start',
                    fontSize: mobile ? '14px' : '18px',
                    fontWeight: 500,
                    paddingTop: '7px',
                  }}>
                  Mint By Card
                </Typography>
              </ButtonOptionDisableStyled>
            )}
          </Box>
        </Grid>
      </LoginContentWrapper>
    </Modal>
  );
}

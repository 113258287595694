import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTabs = styled(Tabs)`
  position: relative;
  overflow: visible !important;
  border-bottom: solid 1px #b9b9b9;
  .MuiTabs-scroller {
    position: unset;
  }
  .MuiTab-root {
    color: #b9b9b9;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-transform: unset;
    padding: 13px 35px;
    &.Mui-selected {
      color: #fff;
    }
    @media (max-width: 767px) {
      padding: 13px 17px;
      font-size: 16px;
    }
  }
  .MuiTabs-indicator {
    height: 3px;
    border-radius: 8px;
    z-index: 10;
    bottom: -1.5px;
    background: #09ebf6;
    box-shadow: 0px 0px 10px 4px rgba(9, 235, 246, 0.25);
    -webkit-box-shadow: 0px 0px 10px 4px rgba(9, 235, 246, 0.25);
    -moz-box-shadow: 0px 0px 10px 4px rgba(9, 235, 246, 0.25);
  }
`;

import { ethers } from 'ethers';

export default (token) => {
  const id = token.tokenId.toNumber();
  const level = token.level.toNumber();
  const owner = token.user;
  const name = `Mad Scientist ${id}`;
  const image = '/images/Scientist.png';
  const { onSale = false, mintable = false } = token;
  const price = ethers.utils.formatEther(token.price);

  return {
    id,
    level,
    owner,
    name,
    image,
    onSale,
    price,
    mintable,
  };
};

import { configureStore, applyMiddleware, compose } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import marketPlace from '@providers/MarketPlaceProvider';
import NFT from '@providers/NFTProvider';
import cellModules from '@providers/CellModuleProvider';
import scientist from '@providers/ScientistProvider';
import metaCell from '@providers/MetaCellProvider';
import modals from '@providers/ModalsProvider';
import wallet from '@providers/WalletProvider';
import transactions from '@providers/Transaction';
import rootSaga from './rootSaga';
import { environment } from '../environment';

// Reducers

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: { marketPlace, NFT, cellModules, scientist, metaCell, modals, wallet, transactions },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(sagaMiddleware);
  },
  devTools: environment.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

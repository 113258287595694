import { Grid, styled } from '@mui/material';

export const ButtonOptionStyled = styled(Grid)`
  border: 2px solid transparent;
  cursor: pointer;
  border-color: rgba(255, 255, 255, 0.16);
  border-radius: 12px;
  &:hover {
    border-color: #00abe2;
    span {
      color: #00abe2;
    }
  }
`;

export const ButtonOptionDisableStyled = styled(Grid)`
  border: 2px solid transparent;
  cursor: not-allowed;
  opacity: 0.5;
  border-color: rgba(255, 255, 255, 0.16);
  border-radius: 12px;
`;

import { styled } from '@mui/material/styles';

export const RecentlyCreatedCardWrapper = styled('div')`
  width: 270px;

  .created_card__title {
    font-family: 'Anybody';
    color: '#fff';
    font-weight: 500;
    font-size: 22px;
    line-height: 21px;
    margin-top: 20px;
    margin-bottom: 2px;
    @media (max-width: 1024px) {
      font-size: 20px;
    }
  }
  .spec {
    font-weight: 400;
    font-size: 10px;
    line-height: 9px;
    margin-top: 8px;
    @media (max-width: 1024px) {
      font-size: 8px;
    }
    span:nth-of-type(1) {
      width: 118px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 1024px) {
        width: 98px;
      }
    }
    .content--highlight {
      color: #90ff04;
    }
  }
`;

/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Grid } from '@mui/material';
import useGetInfoScientist from '@hooks/useGetInfoScientist';
import { LightningIconWrapper } from '../styles';

export function FireItUpButton({
  isMinted,
  onHandleFireWakeUp,
  account,
  checkFormStatus,
  isInAnimating,
}) {
  const info = useGetInfoScientist(account, isMinted, isInAnimating);
  const isCannotMint = isMinted;

  return (
    <>
      <Button
        className={`wake_up_form__submit__wrapper submit_awake__button__wrapper ${
          checkFormStatus.hasAllValue ? 'wakeUpAble' : ''
        } ${isMinted ? 'isMinted' : ''}`}
        onClick={onHandleFireWakeUp}
        disabled={isCannotMint}>
        <Grid
          container
          className="wake_up_form__button wake_up_form_submit__button"
          alignItems="center">
          <LightningIconWrapper>
            <img src="/icons/lightning-icon.svg" alt="lightning icon" />
          </LightningIconWrapper>

          <div className="content">
            <span>
              {isCannotMint ? (info?.remainingAmount ? 'Minted' : 'Sold Out') : 'Fire it up'}
            </span>
          </div>
        </Grid>
      </Button>
    </>
  );
}

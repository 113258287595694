/* eslint-disable prettier/prettier */
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MarketPlaceHeaderContainer = styled(Grid)`
  margin: 36px 0;
  .banner-container {
    background: linear-gradient(90deg, rgba(23, 24, 28, 0.5) 0%, rgba(23, 24, 28, 0) 33.3%) #17181c;
    border-radius: 14px;
    min-height: 98px;
    display: flex;
    width: 100%;
    margin-left: auto;
    max-width: 818px;
    @media (max-width: 767px) {
      flex-direction: column;
      margin-top: 10px;
    }
    .banner-container__timer {
      width: 100%;
      max-width: 571px;
      border-radius: 14px 0 0 14px;
      background: url('/images/Scientists-bg.png') no-repeat 0 / cover;
      .MuiTypography-root {
        line-height: 27px;
        flex-grow: 1;
        white-space: normal;
        font-size: 22px;
        padding: 20px 25px 20px 28px;
      }
    }
    .banner-container__action {
      min-width: 246px;
      text-align: center;
      @media (max-width: 767px) {
        padding: 20px 0;
        width: 100%;
        border-top: solid 0.5px #00f3ff;
      }
      .MuiButton-root {
        height: 50px;
        background: #2081e2;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 27px;
        font-size: 15px;
        line-height: 18.38px;
        font-weight: 800;
      }
      &:hover {
        background: '#3F99F3';
      }
      &:active {
        background: '#3F99F3';
      }
      &:disabled {
        background: rgba(63, 153, 243, 0.3);
        color: rgba(255, 255, 255, 0.2);
      }
    }
  }
`;

import { NeonLightButton, ScientistMintHeader } from '@components';
import { Box } from '@mui/material';
import { openLoginModal } from '@providers/ModalsProvider';
import { LoadingFullScreenCompound } from '@shared/components/LoadingFullScreenCompound';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { MessageNotifyModal } from './MessageNotifyModal';
import MetacellCollectedModal from './MetacellCollectedModal';
import MetacellCountdownModal from './MetacellCountdownModal';
import { MintedMetacellSession } from './MintedMetacellSession';
import { MintLabSession } from './MintLabSession';
import { MintMetacellWrapper, useMintMetacellContext } from './MintMetacellWrapper';
import { ConnectWalletWrapper, MetacellMintWrapper as MintWrapperStyled } from './styles';
import { useEffectOnMount } from './useEffectOnMount';

const WrapConnected = ({ children }) => {
  const location = useLocation();
  const {
    wallet: { account },
  } = useMintMetacellContext();
  const dispatch = useDispatch();

  const stylesOpenBg = useSpring({
    to: { filter: 'brightness(1)' },
    from: { filter: 'brightness(0)' },
    config: { duration: 3000 },
  });

  return (
    <>
      <animated.div className={account ? 'wrapper' : 'wrapper wrapper__bg'}>
        {account ? (
          children
        ) : (
          <ConnectWalletWrapper
            isMintMetacell={location.pathname?.includes('metacell-mint')}
            className="connect__button">
            <NeonLightButton handleClick={() => dispatch(openLoginModal())} text="CONNECT POWER" />
          </ConnectWalletWrapper>
        )}
      </animated.div>
    </>
  );
};

const AtMintedMetacell = () => {
  const { proofs = [] } = useSelector((state) => state.metaCell);

  const {
    metacell: { isMinted = null, claimProofIdx = null },
    cleanup,
  } = useMintMetacellContext();

  useEffect(() => {
    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isShowStartScreen = useMemo(
    // not minted metaCell && (can mint or not in whitelist)
    () => !isMinted && (Boolean(claimProofIdx) === true || proofs === null),
    [claimProofIdx, isMinted, proofs]
  );

  return (
    <LoadingFullScreenCompound isLoading={claimProofIdx === null}>
      {isShowStartScreen ? (
        <>
          <MintLabSession />
        </>
      ) : (
        <MintedMetacellSession />
      )}
    </LoadingFullScreenCompound>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const {
    modal: { setMessageNotify },
  } = useMintMetacellContext();

  const onRedirectHome = () => {
    navigate('/metacell-mint');
  };

  const onHandleSetErrorMessage = (err) => {
    setMessageNotify({
      show: true,
      message: err,
      type: 'error',
    });
  };
  return (
    <ScientistMintHeader
      handleRedirectHome={onRedirectHome}
      handleSetErrorMessage={onHandleSetErrorMessage}
    />
  );
};

export const MintMetacell = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = 'Mad Metaverse - Metacell Mint';
  }, []);

  return (
    <Box
      sx={{
        background: `url("/images/MyCollectionBackground.webp") center / cover no-repeat`,
        minHeight: '100vh',
        pb: '0',
      }}>
      <MintMetacellWrapper>
        <Header />
        <MintWrapperStyled isMintMetacell={location.pathname?.includes('metacell-mint')}>
          <WrapConnected>
            <AtMintedMetacell />
          </WrapConnected>
          <MetacellCollectedModal />
          <MessageNotifyModal />
          <MetacellCountdownModal />
        </MintWrapperStyled>
      </MintMetacellWrapper>
    </Box>
  );
};

import axios from 'axios';
import _isObject from 'lodash/isObject';
import _get from 'lodash/get';

const getAxiosInstance = () => {
  const headers = {
    accept: '*/*',
  };

  const axiosInstance = axios.create({
    baseURL: process.env.BE_SERVER_URL,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if ([200, 201].includes(response.status)) {
        const result = response.data;
        if (_isObject(result.isObject)) {
          result.statusCode = response.status;
        }
        return response.data;
      }
      return Promise.reject(response);
    },
    (error) => {
      if (window.navigator.onLine) {
        const code = _get(error.response, 'data.code', error.response.status);
        if (code) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error.response.data);
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        code: 500,
        data: 'data',
        error: 'noNetwork',
      });
    }
  );

  return axiosInstance;
};

function request(url, data = {}, { method = 'POST', token }) {
  try {
    const API = getAxiosInstance(token);
    switch (method) {
      case 'GET':
        return API.get(url, { params: data });
      case 'PUT':
        return API.put(url, data);
      case 'DELETE':
        return API.delete(url, { params: data });
      default:
        return API.post(url, data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default request;

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  Lab,
  TheField,
  AdminPanel,
  Dashboard,
  UserSettings,
  ResetPassword,
  ScientistMint,
  MetacellMint,
} from '@pages';
import { useEffect } from 'react';
import App from '../App';
import { useStore } from '../store';

const redirectPage = '/scientist-mint';
const availableRoute = ['/metacell-mint', '/scientist-mint'];

const RedirectAppLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!availableRoute.includes(location.pathname)) navigate(redirectPage);
  }, [location.pathname, navigate]);

  return <App />;
};
const AppRoutes = () => {
  // const { renderAdminPanel } = useStore();

  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to={redirectPage} replace />} />
        <Route path="/" element={<RedirectAppLayout />}>
          <Route index path="/" element={<Navigate to={redirectPage} replace />} />
          {/* <Route path="dashboard" element={<Dashboard />} />
         
          <Route path="the-field">
            <Route index element={<TheField />} />
            <Route path=":tab" element={<TheField />} />
          </Route>
          <Route
            path="admin-panel"
            element={renderAdminPanel ? <AdminPanel /> : <Navigate replace to="/dashboard" />}
          />
          {renderAdminPanel ? <Route path="admin-panel" element={<AdminPanel />} /> : null}
          <Route path="user-settings" element={<UserSettings />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="lab" element={<Lab />} />
           */}
          <Route path="metacell-mint" element={<MetacellMint />} />
          <Route path="scientist-mint" element={<ScientistMint />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;

import { useSpring } from 'react-spring';

export const useSpringStyles = (introView2) => {
  const stylesIntro2 = useSpring({
    to: { opacity: introView2 ? 1 : 0 },
    from: { opacity: 0 },
    config: { duration: introView2 ? 2000 : 1 },
  });

  const stylesTechCityCover = useSpring({
    to: {
      scale: introView2 ? 1 : 0.35,
      transform: `translate(${introView2 ? 0 : -150}vw,${introView2 ? 0 : 135}vh )`,
    },
    from: {
      scale: introView2 ? 0.35 : 1,
      transform: `translate(${introView2 ? -150 : 0}vw,${introView2 ? 135 : 0}vh )`,
    },
    config: introView2
      ? {
          mass: 7.3,
          tension: 135,
          friction: 52,
          precision: 0.001,
          velocity: 0.002,
        }
      : { duration: 0.1 },
  });

  const stylesTechCityCoverFloating = useSpring({
    loop: true,
    to: [{ marginTop: '80px' }, { marginTop: '100px' }],
    from: {
      marginTop: '100px',
    },
    config: { duration: 2000 },
  });

  return { stylesIntro2, stylesTechCityCoverFloating, stylesTechCityCover };
};

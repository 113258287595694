import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { useContracts } from '../providers';
import { formatNanocellData } from '../utils';

const loadingStatus = {
  usersModules: true,
  usersBoxes: true,
  usersMetaCells: true,
  usersNanoCells: true,
  usersEnhancers: true,
  usersScientists: true,
};

export const useGetNanocellState = () => {
  const [usersNanocells, setUsersNanocells] = useState([]);
  const [loading, setLoading] = useState(true);
  const { account } = useWeb3React();
  const { NanoCell, PolygonMarketplace } = useContracts();
  const updateUsersNanocells = async () => {
    try {
      setLoading((loading) => ({ ...loading, usersNanoCells: true }));
      const usersNanocellsIndexes = await NanoCell.totalOfOwner(account);
      const formatUsersNanocells = await Promise.all(
        usersNanocellsIndexes.map((nanoCellId) =>
          formatNanocellData(nanoCellId, PolygonMarketplace, NanoCell)
        )
      );
      setUsersNanocells(formatUsersNanocells);
    } catch (error) {
      console.log('Error from updateUsersNanocells', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (NanoCell) {
  //     updateUsersNanocells();
  //   }
  // }, [NanoCell]);

  return {
    usersNanocells,
    loading,
    updateUsersNanocells,
  };
};

import { s3Instance } from './config';
import { environment } from '../environment';

const bucket = environment.BUCKET_NAME;

// function for adding the cell to storage
export const addCellToS3 = async (cellData, cellId) => {
  // in case we are using mainnet or testnet and want to store in AWS
  const params = {
    Bucket: bucket,
    Key: `metadata/${cellId}.json`,
    Body: JSON.stringify(cellData),
    ACL: 'private',
    Metadata: {},
  };

  await s3Instance
    .putObject(params)
    .promise()
    .then(undefined, (err) => {
      console.error('Project upload failed', err);
    });
};

export const getCellFromS3 = async (tokenId) => {
  // in case we are using mainnet or testnet and want to store in AWS
  const params = {
    Bucket: bucket,
    Key: `metadata/${tokenId}.json`,
  };
  const data = {
    description: 'Cell NFT + S3 || test_1',
    external_url: 'https://cell.nft/',
    image: 'https://simbiotes.s3.us-east-2.amazonaws.com/pictures/0/0.png',
    name: `Cell ${tokenId}`,
  };
  s3Instance.config.endpoint.protocol = 'http';
  const cellData = await s3Instance
    .getObject(params)
    .promise()
    .then(
      (data) => {
        const cell = JSON.parse(data.Body.toString());
        return cell;
      },
      async (err) => {
        await addCellToS3(data, tokenId);
        console.log(err, err.stack);
      }
    );
  return cellData;
};

// function for updating in property in project store on S3
export const updateCellProperty = async (propName, newValue, tokenId) => {
  // we have to delete existing file and replace it with new data
  const deleteParams = {
    Bucket: bucket,
    Key: `${tokenId}.json`,
  };

  const project = await getCellFromS3(tokenId);

  if (project[propName] === newValue) {
    return;
  }

  const projectWithFilename = {
    ...project,
    [propName]: newValue,
  };

  s3Instance.deleteObject(deleteParams);

  const updateParams = {
    Bucket: bucket,
    Key: `metadata/${tokenId}.json`,
    Body: JSON.stringify(projectWithFilename),
    ACL: 'private',
    Metadata: {},
  };

  await s3Instance
    .putObject(updateParams)
    .promise()
    .then(undefined, (err) => {
      console.error('Project upload failed', err);
    });
};

export const addEnhancerImage = async (id, file) => {
  const params = {
    Bucket: bucket,
    Key: `enhancers/${id}.png`,
    ContentType: 'image/png',
    Body: file,
    ACL: 'public-read',
  };

  await s3Instance
    .putObject(params)
    .promise()
    .then(undefined, (err) => {
      console.error('Image upload failed', err);
    });
};

import { createContext, useContext, useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useContracts } from '../providers';
import {
  formatMetacellData,
  formatEnhancerData,
  formatScientistData,
  formatNanocellData,
  formatBoxData,
} from '../utils';
import { useGetNanocellState } from './useNanocellContract';

const GlobalStore = createContext();

const loadingStatus = {
  usersModules: true,
  usersBoxes: true,
  usersMetaCells: true,
  usersNanoCells: true,
  usersEnhancers: true,
  usersScientists: true,
};

export const Store = ({ children }) => {
  const [usersScientists, setUsersScientists] = useState([]);
  const [usersMetacells, setUsersMetacells] = useState([]);
  const [usersEnhancers, setUsersEnhancers] = useState([]);
  const [usersBoxes, setUsersBoxes] = useState([]);
  const [usersModules, setUsersModules] = useState([]);
  const [latestCreatedModule, setLatestCreatedModule] = useState(null);
  const [marketplaceScientists, setMarketplaceScientists] = useState([]);
  const [marketplaceCells, setMarketplaceCells] = useState([]);
  const [marketplaceNanocells, setMarketplaceNanocells] = useState([]);
  const [marketplaceEnhancers, setMarketplaceEnhancers] = useState([]);
  const [loading, setLoading] = useState(() => loadingStatus);
  const { library, account, chainId } = useWeb3React();
  const contracts = useContracts();
  const {
    PolygonMarketplace,
    Laboratory,
    MDMA,
    NanoCell,
    Repository,
    MarketplaceScientist,
    MetaCell,
    Scientist,
    Module,
    handleAggregateCall,
  } = contracts;

  const [tokenPrice, setTokenPrice] = useState(0);
  const [boostPerBlockPrice, setBoostPerBloackPrice] = useState(0);
  const [usersBalance, setUsersBalance] = useState(0);
  const [usersBalanceOfNativeToken, setUsersBalanceOfNativeToken] = useState('0');
  const [user, setUser] = useState({});
  const [feeQuota, setFeeQuota] = useState(0);
  const [feeWallet, setFeeWallet] = useState('');
  const [accessRights, setAccessRights] = useState({ ownerOf: [] });
  const [renderAdminPanel, setRenderAdminPanel] = useState(false);
  const [mintUnavailableScientists, setMintUnavailableScientists] = useState([]);
  const { usersNanocells, updateUsersNanocells } = useGetNanocellState();

  // useEffect(() => {
  //   const getRights = async () => {
  //     try {
  //       const owners = await Promise.all(
  //         Object.values(contracts).map((contract) => contract?.owner && contract.owner())
  //       );

  //       const admins = await Promise.all(
  //         Object.values(contracts).map(async (contract) => {
  //           let res;
  //           try {
  //             if (contract?.getAdmins) res = await contract.getAdmins();
  //           } catch (error) {
  //             console.log({
  //               contract,
  //             });
  //           }
  //           return res;
  //         })
  //       );

  //       const names = Object.keys(contracts);

  //       const ownerOf = [];
  //       owners.forEach((owner, index) => owner === account && ownerOf.push(names[index]));

  //       const rights = {
  //         ownerOf,
  //       };

  //       names.forEach((name, index) => {
  //         rights[name] = admins[index].value;
  //       });

  //       setAccessRights(rights);
  //       setRenderAdminPanel(
  //         rights.ownerOf.length ||
  //           admins
  //             .map((e) => e.value)
  //             .some((arr) => arr && arr.some((address) => address === account))
  //       );
  //     } catch (error) {
  //     }
  //       console.log(error);
  //   };
  //   if (account)
  //     if (Object.keys(contracts).length) {
  //       getRights();

  //       Object.values(contracts).forEach((contract) => {
  //         if (contract.addAdmin) {
  //           contract.on('AdminAdded', getRights);
  //           contract.on('AdminRemoved', getRights);
  //         }
  //       });

  //       return () => {
  //         Object.values(contracts).forEach((contract) => {
  //           if (contract.addAdmin) {
  //             contract.off('AdminAdded', getRights);
  //             contract.off('AdminRemoved', getRights);
  //           }
  //         });
  //       };
  //     }
  // }, [contracts, account]);

  useEffect(() => {
    const updateUsersBalanceOfNativeToken = async () => {
      try {
        const balanceOfNative = await library.getBalance(account);
        setUsersBalanceOfNativeToken(balanceOfNative);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error from updateUsersBalanceOfNativeToken', error);
      }
    };
    if (account) {
      updateUsersBalanceOfNativeToken();
    }
  }, [account, library]);

  const updateUsersBalance = async () => {
    try {
      const balance = await MDMA.balanceOf(account);
      setUsersBalance(balance);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error from updateUsersBalance', error);
    }
  };
  // useEffect(() => {
  //   if (MDMA) {
  //     updateUsersBalance();

  //     MDMA.on('Transfer', updateUsersBalance);
  //     return () => {
  //       MDMA.removeAllListeners();
  //     };
  //   }
  // }, [MDMA, account]);

  const updateUsersScientists = async () => {
    try {
      setLoading((loading) => ({ ...loading, usersScientists: true }));
      const usersScientistsIndexes = await Scientist.getUserMetascientistsIndexes(account);
      const tupleScientist = usersScientistsIndexes.map((index) => {
        const callData = Scientist.interface.encodeFunctionData('getScientist', [index]);
        return {
          target: Scientist.address,
          callData,
        };
      });
      const tubleScientistMintable = usersScientistsIndexes.map((index) => {
        const callData = Laboratory.interface.encodeFunctionData('isCanCreateMetaCell', [index]);
        return {
          target: Laboratory.address,
          callData,
        };
      });
      const [returnScientistData = [], returnScientistMintableData = []] = await Promise.all([
        handleAggregateCall(tupleScientist),
        handleAggregateCall(tubleScientistMintable),
      ]);
      const parseScientistData = returnScientistData.map((result) => {
        return Scientist.interface.decodeFunctionResult('getScientist', result)[0];
      });
      const parseScientistMintableData = returnScientistMintableData.map((result) => {
        return Laboratory.interface.decodeFunctionResult('isCanCreateMetaCell', result)[0];
      });
      const formatedUsersScientists = parseScientistData.map((scientist, index) =>
        formatScientistData({ ...scientist, mintable: parseScientistMintableData[index] || false })
      );
      setUsersScientists(formatedUsersScientists);
      setLoading((loading) => ({ ...loading, usersScientists: false }));
    } catch (error) {
      setLoading((loading) => ({ ...loading, usersScientists: false }));
      // eslint-disable-next-line no-console
      console.log('Error from updateUsersScientists', error);
    }
  };

  // useEffect(() => {
  //   if (Repository && Scientist) {
  //     updateUsersScientists();
  //   }
  // }, [Repository, Scientist]);

  const updateMarketplaceScientists = async () => {
    try {
      const derivedMarketplaceScientistsOwnersAndIds = await MarketplaceScientist.getOnSaleScientists();
      const derivedScientistsByIdAndOwner = await Promise.all(
        derivedMarketplaceScientistsOwnersAndIds[1].map((scientistId, index) => {
          const ownerAddress = derivedMarketplaceScientistsOwnersAndIds[0][index];
          const scientist = Scientist.getScientistByID(scientistId, ownerAddress);
          return scientist;
        })
      );
      const formatedUsersScientists = derivedScientistsByIdAndOwner.map((scientist) =>
        formatScientistData(scientist)
      );
      setMarketplaceScientists(formatedUsersScientists);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error from updateMarketplaceCells', error);
    }
  };

  const updateUsersMetacells = async () => {
    try {
      if (!MetaCell) return;
      setLoading((loading) => ({ ...loading, usersMetaCells: true }));
      setUsersMetacells([]);
      const usersMetacellsIndexes = await MetaCell.getUserMetaCellsIndexes(account);

      const listRequest = usersMetacellsIndexes.map((o) => {
        const callData = MetaCell.interface.encodeFunctionData('getMetaCell', [o]);
        return {
          target: MetaCell.address,
          callData,
        };
      });

      const multiCallRaw = await handleAggregateCall(listRequest);

      const derivedUsersMetacells = multiCallRaw.map(
        (raw) => MetaCell.interface.decodeFunctionResult('getMetaCell', raw)[0]
      );

      // const derivedUsersMetacells = await Promise.all(
      //   usersMetacellsIndexes.map(async (id) => {
      //     try {
      //       const tokenInfo = await MetaCell.getMetaCell(id);
      //       return tokenInfo;
      //     } catch (error) {
      //       return null;
      //     }
      //   })
      // );

      const { blockNumber } = library;
      const formatedUsersMetacells = derivedUsersMetacells
        .filter((o) => o)
        .map((metaCell) => formatMetacellData(metaCell, blockNumber));

      setUsersMetacells(formatedUsersMetacells);
      setLoading((loading) => ({ ...loading, usersMetaCells: false }));
    } catch (error) {
      setLoading((loading) => ({ ...loading, usersMetaCells: false }));
      // eslint-disable-next-line no-console
      console.log('Error from updateUsersMetacells', error);
    }
  };

  const updateMarketplaceNanocells = async () => {
    try {
      const usersNanocellsIndexes = await NanoCell.totalOfOwner(account);
      const derivedMarketplaceIndexes = await PolygonMarketplace.getNanoCellsOnSale();
      const filteredMarketplaceIndexes = derivedMarketplaceIndexes.filter(
        (index) => !usersNanocellsIndexes.some((id) => id._hex === index._hex)
      );
      const formatedMarketplaceNanocells = await Promise.all(
        filteredMarketplaceIndexes.map((nanoCellId) =>
          formatNanocellData(nanoCellId, PolygonMarketplace, NanoCell)
        )
      );
      setMarketplaceNanocells(formatedMarketplaceNanocells);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error from updateMarketplaceNanocells', error);
    }
  };

  // useEffect(() => {
  //   if (NanoCell) {
  //     updateUsersNanocells();
  //   }
  // }, [NanoCell]);

  const updateUsersEnhancers = async () => {
    try {
      setLoading((loading) => ({ ...loading, usersEnhancers: true }));
      const derivedUsersEnhancersIds = await Laboratory.getUserEnhancers(account);
      const derivedUsersEnhancers = await Promise.all(
        derivedUsersEnhancersIds.map((id) => Laboratory.getEnhancerInfo(id))
      );
      const formatedUsersEnhancers = await Promise.all(
        derivedUsersEnhancers.map((enhancer) =>
          formatEnhancerData(enhancer, Laboratory.getEnhancersAmount, account)
        )
      );
      setUsersEnhancers(formatedUsersEnhancers);
      setLoading((loading) => ({ ...loading, usersEnhancers: false }));
    } catch (error) {
      setLoading((loading) => ({ ...loading, usersEnhancers: false }));
      // eslint-disable-next-line no-console
      console.log('Error from updateUsersEnhancers', error);
    }
  };

  const updateUsersBoxes = async () => {
    try {
      setLoading((loading) => ({ ...loading, usersBoxes: true }));
      const userBoxes = await PolygonMarketplace.getUserBoxes(account);
      setLoading((loading) => ({ ...loading, usersBoxes: false }));
      const formattedBoxes = userBoxes.map((box) => formatBoxData(box));
      setUsersBoxes(formattedBoxes);
    } catch (error) {
      setLoading((loading) => ({ ...loading, usersBoxes: false }));
      // eslint-disable-next-line no-console
      console.log('Error from get user boxes from marketplace', error);
    }
  };

  const updateUsersModules = async () => {
    try {
      setLoading((loading) => ({ ...loading, usersModules: true }));
      const modules = await Module.totalOfOwner(account);
      setLoading((loading) => ({ ...loading, usersModules: false }));
      const formattedModules = modules.map((module) => formatBoxData(module));
      setUsersModules(formattedModules);
    } catch (error) {
      setLoading((loading) => ({ ...loading, usersModules: false }));
      // eslint-disable-next-line no-console
      console.log('Error from get user boxes from marketplace', error);
    }
  };

  const updateLatestCreatedModule = (_, to, tokenID) => {
    if (account === to) {
      setLatestCreatedModule(tokenID);
    }
  };

  const updateUsersAssets = async () => {
    await updateUsersMetacells();
    // await updateUsersNanocells();
    // await updateUsersEnhancers();
    // await updateUsersBalance();
  };

  const updateBoostPerBlockPrice = async () => {
    try {
      const boostPerBlockPrice = await Laboratory.getBoostPerBlockPrice();

      setBoostPerBloackPrice(boostPerBlockPrice);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (Laboratory && MetaCell) {
  //     updateUsersMetacells();
  //     updateUsersEnhancers();
  //     updateBoostPerBlockPrice();

  //     Laboratory.on('NewEvolutionCompleted', updateUsersAssets);
  //     Laboratory.on('EvolutionTimeReduced', updateUsersAssets);
  //     Laboratory.on('BoostPricePerBlockChanged', updateBoostPerBlockPrice);
  //     return () => {
  //       Laboratory.removeAllListeners();
  //     };
  //   }
  // }, [Laboratory, MetaCell]);

  const updateMarketplaceCells = async () => {
    try {
      if (!PolygonMarketplace) return;
      const derivedMarketplaceCellsOwnersAndIds = await PolygonMarketplace.getOnSaleMetaCells();

      const derivedCellsByIdAndOwner = await Promise.all(
        derivedMarketplaceCellsOwnersAndIds[1].map((cellId, index) => {
          const cell = MetaCell.getMetaCell(cellId);
          return cell;
        })
      );
      const blockNumber = await library.getBlockNumber();
      const formatedMarketplaceCells = await Promise.all(
        derivedCellsByIdAndOwner.map((cell) => formatMetacellData(cell, blockNumber))
      );
      setMarketplaceCells(formatedMarketplaceCells);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error from updateMarketplaceCells', error);
    }
  };

  const updateMarketplaceEnhancers = async () => {
    try {
      const derivedMarketplaceEnhancers = await PolygonMarketplace.getAllEnhancers();
      const formatedMarketplaceEnhacners = await Promise.all(
        derivedMarketplaceEnhancers.map((enhancer) =>
          formatEnhancerData(enhancer, PolygonMarketplace.getEnhancersAmount)
        )
      );
      setMarketplaceEnhancers(formatedMarketplaceEnhacners);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error from updateMarketplaceEnhancers', error);
    }
  };

  const updateTokenPrice = async (marketplace) => {
    try {
      const tokenPrice = await marketplace.getBiometaTokenPrice();
      setTokenPrice(tokenPrice);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const updateFeeQuota = async (marketplace) => {
    try {
      const feeQuota = await marketplace.getFeeQuote();
      setFeeQuota(feeQuota);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Func<updateFeeQuota> Marketplace: ', error);
    }
  };

  const updateFeeWallet = async (marketplace) => {
    try {
      const feeWallet = await marketplace.getFeeWallet();
      setFeeWallet(feeWallet);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const updateUsersAndMarketplaceCells = async () => {
    await updateUsersMetacells();
    await updateUsersNanocells();
    await updateUsersEnhancers();
    await updateMarketplaceCells();
    await updateMarketplaceNanocells();
    await updateMarketplaceEnhancers();
    await updateUsersBalance();
  };

  // useEffect(() => {
  //   const handleUpdateAsync = async () => {
  //     if (!PolygonMarketplace) return;
  //     await updateMarketplaceCells();
  //     await updateMarketplaceNanocells();
  //     await updateMarketplaceEnhancers();
  //     await updateTokenPrice(PolygonMarketplace);
  //     await updateFeeQuota(PolygonMarketplace);
  //     await updateFeeWallet(PolygonMarketplace);
  //     await updateUsersBoxes();
  //     await updateUsersModules();
  //   };
  //   handleUpdateAsync();
  //   if (PolygonMarketplace) {
  //     PolygonMarketplace.on('NanoCellAddedToMarketPlace', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('MetaCellAddedToMarketplace', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('MetaCellRemovedFromMarketPlace', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('NanoCellRemovedFromMarketPlace', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('MetaCellSold', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('NanoCellSold', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('MetaCellPriceUpdated', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('NanoCellPriceUpdated', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('EnhancerBought', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('EnhancerCreated', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('EnhancerModified', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('EnhancersAmountIncreased', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('EnhancersRemoved', updateUsersAndMarketplaceCells);
  //     PolygonMarketplace.on('TokenPriceChanged', () => {
  //       updateTokenPrice(PolygonMarketplace);
  //     });
  //     PolygonMarketplace.on('FeeQuoteAmountUpdated', () => {
  //       updateFeeQuota(PolygonMarketplace);
  //     });
  //     PolygonMarketplace.on('WalletAddressChanged', () => {
  //       updateFeeWallet(PolygonMarketplace);
  //     });
  //     PolygonMarketplace.on('ModuleBoxOpened', updateUsersBoxes);
  //     PolygonMarketplace.on('ModuleBoxOpened', updateUsersModules);
  //     PolygonMarketplace.on('ModuleBoxCreated', updateUsersBoxes);
  //     PolygonMarketplace.on('ModuleSold', updateUsersModules);
  //     PolygonMarketplace.on('ModuleAddedToMarketPlace', updateUsersModules);
  //     PolygonMarketplace.on('ModulePriceUpdated', updateUsersModules);
  //     PolygonMarketplace.on('ModuleRemovedFromMarketPlace', updateUsersModules);
  //     return () => {
  //       PolygonMarketplace.removeAllListeners();
  //     };
  //   }
  // }, [PolygonMarketplace]);

  const updateTransferCreateModules = (from, to, tokenId) =>
    updateLatestCreatedModule(from, to, tokenId);

  // useEffect(() => {
  //   if (Module) {
  //     Module.on('Transfer', (from, to, tokenId) => updateLatestCreatedModule(from, to, tokenId));
  //     return () => {
  //       Module.removeAllListeners();
  //     };
  //   }
  // }, [Module]);

  // useEffect(() => {
  //   if (MarketplaceScientist) {
  //     MarketplaceScientist.on('ScientistSold', updateUsersScientists);
  //     return () => {
  //       MarketplaceScientist.removeAllListeners();
  //     };
  //   }
  // }, [MarketplaceScientist]);

  const updateUsersAndMarketplaceScientists = async () => {
    await updateUsersScientists();
    await updateMarketplaceScientists();
    await updateUsersBalance();
  };

  // useEffect(() => {
  //   if (MarketplaceScientist) {
  //     updateMarketplaceScientists();
  //     updateFeeQuota(MarketplaceScientist);
  //     updateFeeWallet(MarketplaceScientist);

  //     MarketplaceScientist.on('ScientistAddedToMarketplace', updateUsersAndMarketplaceScientists);
  //     MarketplaceScientist.on('RemovedFromSale', updateUsersAndMarketplaceScientists);
  //     MarketplaceScientist.on('ScientistSold', updateUsersAndMarketplaceScientists);
  //     MarketplaceScientist.on('ScientistPriceUpdated', updateUsersAndMarketplaceScientists);
  //     MarketplaceScientist.on('TokenPriceChanged', () => {});
  //     MarketplaceScientist.on('FeeQuoteAmountUpdated', () => {
  //       updateFeeQuota(MarketplaceScientist);
  //     });
  //     MarketplaceScientist.on('FeeWalletChanged', () => {
  //       updateFeeWallet(MarketplaceScientist);
  //     });

  //     return () => {
  //       MarketplaceScientist.removeAllListeners();
  //     };
  //   }
  // }, [MarketplaceScientist]);

  return (
    <GlobalStore.Provider
      value={{
        usersMetacells,
        usersScientists,
        setUsersScientists,
        // usersNanocells,
        usersEnhancers: [],
        // usersBoxes,
        // usersModules,
        // latestCreatedModule,
        // marketplaceScientists,
        // marketplaceCells,
        // marketplaceNanocells,
        // marketplaceEnhancers,
        tokenPrice,
        // boostPerBlockPrice,
        usersBalance,
        // usersBalanceOfNativeToken,
        user,
        // mintUnavailableScientists,
        // setMintUnavailableScientists,
        setUser,
        // feeQuota,
        // feeWallet,
        // accessRights,
        // renderAdminPanel,
        // loading,
        methodContract: {
          // updateUsersEnhancers,
          updateUsersAssets,
          updateBoostPerBlockPrice,
          // updateUsersScientists,
          // updateUsersAndMarketplaceScientists,
          // updateTransferCreateModules,
          // updateUsersAndMarketplaceCells,
          // updateFeeWallet,
          // updateFeeQuota,
          // updateTokenPrice,
          // updateUsersModules,
          // updateUsersBoxes,
        },
      }}>
      {children}
    </GlobalStore.Provider>
  );
};

export const useStore = () => {
  return useContext(GlobalStore);
};

/* eslint-disable no-nested-ternary */
import { MintedScientistModal } from '@components';
import MinOptionModal from '@components/Modals/MintOptionModal';
import { environment } from '@environment/index';
import { useScientistStatus } from '@hooks/scientist/useScientistStatus';
import { useDebounce } from '@hooks/useDebounce';
import useGetInfoScientist from '@hooks/useGetInfoScientist';
import useUserScientist from '@hooks/useUserScientist';
import { Grid, useMediaQuery } from '@mui/material';
import { useContracts } from '@providers/index';
import {
  getScientistIdProof,
  resetFormField,
  saveFormDataMintScientist,
} from '@providers/ScientistProvider';
import { useWert } from '@providers/WertProvider';
import { getErrorMessage } from '@utils/getErrorMessage';
import { formatMintedScientistData, handleCheckValue } from '@utils/index';
import request from '@utils/request';
import { useWeb3React } from '@web3-react/core';
import { formatEther } from 'ethers/lib/utils';
import { debounce, delay, isEmpty } from 'lodash';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useChain, useSpring, useSpringRef } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import {
  AwakeNavigateButton,
  Contact,
  LightingWrapper,
  SocialContacts,
  WakeUpScientistWrapper,
} from '../styles';
import { socialContacts } from '../__mocks/scientistMintMockValues';
import { AwakeScientistButton } from './AwakeScientistButton';
import { CollectedScientistModal } from './CollectedScientistModal';
import { FireItUpButton } from './FireItUpButton';
import { ScientificField } from './ScientificField';
import { ScientistMintAnimation } from './ScientistBg';
import { ScientistMintStatus } from './ScientistMintStatus';
import { FireItUpItem, FireItUpTypography } from './styled';
import { useSpringStyles } from './useSpringStyles';

export const COMPLETED_PAYMENT_EVENT_TYPES = [
  'order_complete',
  'order_failed',
  'order_canceled',
  'tx_smart_contract_failed',
];
const wakeUpFormValues = {
  scientist: '',
  personality: '',
  psychological: {
    obedienceToAuthor: -1,
    obedienceToConventions: -1,
    violenceTendency: -1,
  },
  vices: '',
  magnitude: {
    fracture: -1,
  },
};

// TODO: Main Component - Scientist Mint Page Control

export const ScientistAwake = ({ wrapperRef }) => {
  const unlockMintSound = useMemo(() => new Audio('/sounds/openMintSound.mp3'), []);
  const crazyLaughingSound = useMemo(() => new Audio('/sounds/crazyLaughing.mp3'), []);
  const wakeUpCoverRef = useRef(null);
  const { openFiatPaymentModal, closePopupPayment } = useWert();
  const isSmallDevice = useMediaQuery('( max-width: 1024px )');

  const dispatch = useDispatch();
  const { Scientist } = useContracts();
  const { account, chainId, library } = useWeb3React();
  const { proofs = [] } = useSelector((state) => state.scientist);
  const { claimSignatureIdx, isLoading: isScientistStatusLoading } = useScientistStatus();

  const { walletText } = useSelector((state) => state.wallet);

  const [openingSound] = useState(new Audio('/sounds/cinematicBackground.mp3'));
  const [introView, setIntroView] = useState(false);
  const [isMinted, setIsMinted] = useState(false);
  const [isLockForm, setIsLockForm] = useState(true);
  const [wakeUpValues, setWakeUpValues] = useState(() => wakeUpFormValues);
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showMintOptionModal, setShowMintOptionModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isInAnimating, setIsInAnimating] = useState(false);
  const [triggerFireWakeUp, setTriggerFireWakeUp] = useState(false);
  const [isOpenModalMintedScientist, setIsOpenModalMintedScientist] = useState(false);
  const [mintedScientist, setMintedScientist] = useState({ isShow: false });
  const [distanceFromWakeUpCoverToEndPage, setDistanceFromWakeUpCoverToEndPage] = useState(0);
  const [isWert, setIsWert] = useState(false);
  const clickId = useRef();
  const intervalId = useRef();

  const { scientistId, getUserScientistId, loading: userScientistLoading } = useUserScientist(
    account
  );
  const info = useGetInfoScientist(account, mintedScientist);
  const isMintAbleScientist = useDebounce(Boolean(1 + claimSignatureIdx), 0);
  const inScientistWhiteList = useDebounce(!isEmpty(proofs), 500, null);

  const isOwnerScientist = useMemo(() => !userScientistLoading && Boolean(scientistId), [
    scientistId,
    userScientistLoading,
  ]);

  const checkFormStatus = useMemo(() => {
    const listValues = Object.values(wakeUpValues);
    const hasAnyValue = handleCheckValue('some', listValues);
    const hasAllValue = handleCheckValue('every', listValues);
    return {
      hasAnyValue,
      hasAllValue,
    };
  }, [wakeUpValues]);

  const {
    stylesMintedScientistCover,
    stylesSkillPickerCover,
    stylesSkillPicker,
    stylesIntro3,
    stylesSmokeLeft,
    stylesSmokeRight,
    stylesSmokeSmallScreen,
  } = useSpringStyles(introView);

  const wakeUpCrazyBgApi = useSpringRef();
  const wakeUpBgApi = useSpringRef();
  const flashLightApi = useSpringRef();
  const openEyesApi = useSpringRef();
  const onOpenPopupOption = () => {
    setShowMintOptionModal(true);
  };
  useEffect(() => {
    openingSound.play();
  }, [openingSound]);

  useEffect(() => {
    const distance =
      window.innerHeight - wakeUpCoverRef.current?.offsetTop + wakeUpCoverRef.current?.offsetHeight;
    setDistanceFromWakeUpCoverToEndPage(distance);
  }, []);

  useEffect(() => {
    if (isScientistStatusLoading) return;
    if (userScientistLoading) return;
    const handleLoadData = debounce(() => {
      const handleGetOwnerOf = async (scientistId) => {
        try {
          if (!scientistId) return;

          const address = await Scientist.ownerOf(scientistId);
          if (address?.toLowerCase() === account?.toLowerCase()) {
            setIsMinted(true);
            const txMinted = await Scientist.scientistTraits(scientistId);
            const formattedTxMinted = formatMintedScientistData(txMinted);
            setMintedScientist((mintedScientist) => ({
              ...mintedScientist,
              ...formattedTxMinted,
              tokenId: scientistId,
              isShow: true,
            }));

            setWakeUpValues({
              scientist: formattedTxMinted.scientificField.toString(),
              personality: formattedTxMinted.personalityDiorders.toString(),
              psychological: {
                obedienceToAuthor: formattedTxMinted.obedienceToAuthority,
                obedienceToConventions: formattedTxMinted.obedienceToConventions,
                violenceTendency: formattedTxMinted.tendencyTowardViolence,
              },
              vices: formattedTxMinted.vice.toString(),
              magnitude: {
                fracture: formattedTxMinted.magnitudeOfStress,
              },
            });
          } else setIsMinted(false);
        } catch (error) {
          const msg = getErrorMessage(error);
          setErrorMessage(msg);
        }
      };

      if (isMintAbleScientist) {
        setIsMinted(false);
      } else if (isOwnerScientist) {
        handleGetOwnerOf(scientistId);
      }
    }, 500);

    handleLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMintAbleScientist, isOwnerScientist, isScientistStatusLoading, userScientistLoading]);

  useEffect(() => {
    setShowMintOptionModal(false);
    setShowErrorModal(false);
    setMintedScientist((mintedScientist) => ({
      ...mintedScientist,
      isShow: false,
    }));
    setIsOpenModalMintedScientist(false);
    setIsMinted(false);
    setWakeUpValues(() => wakeUpFormValues);
    dispatch(resetFormField());
  }, [account, dispatch]);

  const handleSetFormStatus = useCallback(() => {
    if (isLockForm) {
      setIsLockForm(false);
      unlockMintSound.play();
    } else {
      setIsLockForm(true);
      setWakeUpValues(() => wakeUpFormValues);
    }
  }, [isLockForm, unlockMintSound]);

  const handleGetScientist = () => {
    setIsOpenModalMintedScientist(false);
    if (!scientistId) return;
    setIsMinted(true);
    setIsLockForm(true);
    setMintedScientist((mintedScientist) => ({ ...mintedScientist, isShow: true }));
    wrapperRef.current.style.overflowY = 'auto';
  };

  const onHandleMintingFailed = useCallback((error) => {
    setShowConfirmModal(false);
    clearInterval(intervalId.current);
    if (!error) {
      setErrorMessage('Payment failed');
      setShowErrorModal(true);
    }
  }, []);

  const onHandleMintingSuccess = useCallback(() => {
    // dispatch(resetState());
    clearInterval(intervalId.current);
    setShowConfirmModal(false);
    setShowErrorModal(false);
    setIsInAnimating(true);
    setTriggerFireWakeUp(true);
    closePopupPayment();
    crazyLaughingSound.play();
    delay(async () => {
      setIsInAnimating(false);
      setTriggerFireWakeUp(false);
      setIsOpenModalMintedScientist(true);
      const list = await getUserScientistId(account);
      const scientistIdMinted = [...list].at(-1);
      if (!scientistIdMinted) return;
      const txMinted = await Scientist.scientistTraits(scientistIdMinted);
      const formattedTxMinted = formatMintedScientistData(txMinted);
      setMintedScientist((mintedScientist) => ({
        ...mintedScientist,
        ...formattedTxMinted,
        tokenId: scientistIdMinted,
      }));
    }, 6000);
  }, [Scientist, account, closePopupPayment, crazyLaughingSound, getUserScientistId]);

  const onHandleMintingStart = useCallback(() => {
    setShowErrorModal(false);
    console.log(clickId.current);
    setErrorMessage('Minting Scientist...');
    setShowConfirmModal(true);
    const intervalCheckMinting = setInterval(async () => {
      const data = { chainId };
      const checking = await request(
        `${environment.BE_SERVER_URL}/api/payment-event/click/${clickId.current}`,
        data,
        {
          method: 'GET',
        }
      );
      console.log({ checking }, checking?.data?.events);
      const lstEvents = checking?.data?.events;
      if (lstEvents[lstEvents?.length - 1]?.type === COMPLETED_PAYMENT_EVENT_TYPES[0]) {
        console.log('mint with api');
        onHandleMintingSuccess();
      } else if (lstEvents[lstEvents?.length - 1]?.type === COMPLETED_PAYMENT_EVENT_TYPES[1]) {
        onHandleMintingFailed();
      }
    }, 30000);
    intervalId.current = intervalCheckMinting;
  }, [chainId, onHandleMintingFailed, onHandleMintingSuccess]);

  const onHandleFireWakeUp = useCallback(async () => {
    setIsWert(false);
    dispatch(saveFormDataMintScientist({ name: '', value: -1 }));
    setShowMintOptionModal(false);
    if (inScientistWhiteList) {
      setErrorMessage(`Please confirm transaction in your ${walletText}`);
      setShowConfirmModal(true);
      setIsInAnimating(true);
      wrapperRef.current.style.overflowY = 'hidden';
      const wakeUpValuesUpdated = {
        ...wakeUpValues,
        magnitude: wakeUpValues.magnitude.fracture,
      };
      const result = [];
      let flag = 0;
      let subResult = [];
      const tuplesConvert = (obj) => {
        const values = Object.values(obj);
        values.forEach((value) => {
          if (value.constructor.name === 'Object') {
            flag = 1;
            tuplesConvert(value);
          } else if (!flag) {
            result.push(parseInt(value));
          } else subResult.push(parseInt(value));
        });
        if (subResult.length) {
          result.push(subResult);
          flag = 0;
          subResult = [];
        }
      };
      tuplesConvert(wakeUpValuesUpdated);
      const price = await Scientist.price();
      try {
        const options = { value: price };

        const tx = await Scientist.mintForGift(
          account,
          proofs[claimSignatureIdx],
          claimSignatureIdx,
          result,
          options
        );

        setErrorMessage('Minting Scientist...');
        await tx.wait(1);

        // on Handle Minting Scientist
        setShowConfirmModal(false);
        setIsInAnimating(true);
        setTriggerFireWakeUp(true);
        crazyLaughingSound.play();
        delay(async () => {
          setIsInAnimating(false);
          setTriggerFireWakeUp(false);
          setIsOpenModalMintedScientist(true);
          const list = await getUserScientistId(account);
          const scientistIdMinted = [...list].at(-1);
          if (!scientistIdMinted) return;
          const txMinted = await Scientist.scientistTraits(scientistIdMinted);
          const formattedTxMinted = formatMintedScientistData(txMinted);
          setMintedScientist((mintedScientist) => ({
            ...mintedScientist,
            ...formattedTxMinted,
            tokenId: scientistIdMinted,
          }));
        }, 6000);
      } catch (error) {
        console.log({ error });
        if (error?.receipt?.status) {
          setShowConfirmModal(false);
          setIsInAnimating(true);
          setTriggerFireWakeUp(true);
          crazyLaughingSound.play();
          delay(async () => {
            setIsInAnimating(false);
            setTriggerFireWakeUp(false);
            setIsOpenModalMintedScientist(true);
            const list = await getUserScientistId(account);
            const scientistIdMinted = [...list].at(-1);
            if (!scientistIdMinted) return;
            const txMinted = await Scientist.scientistTraits(scientistIdMinted);
            const formattedTxMinted = formatMintedScientistData(txMinted);
            setMintedScientist((mintedScientist) => ({
              ...mintedScientist,
              ...formattedTxMinted,
              tokenId: scientistIdMinted,
            }));
          }, 6000);
          return;
        }
        const msg = getErrorMessage(error);
        setErrorMessage(msg);
        setShowErrorModal(true);
        setShowConfirmModal(false);
        setTriggerFireWakeUp(false);
        setIsInAnimating(false);
        wrapperRef.current.style.overflowY = 'auto';
      }
    } else {
      setErrorMessage('You are not in the whitelist');
      setShowErrorModal(true);
    }
  }, [
    dispatch,
    inScientistWhiteList,
    walletText,
    wrapperRef,
    wakeUpValues,
    Scientist,
    account,
    proofs,
    claimSignatureIdx,
    getUserScientistId,
    crazyLaughingSound,
  ]);

  const onHandleFireWithWert = async () => {
    setIsLockForm(true);
    setIsWert(true);
    dispatch(saveFormDataMintScientist({ name: '', value: -1 }));
    setShowMintOptionModal(false);
    if (!account) return;
    if (inScientistWhiteList) {
      const price = await Scientist.price();
      try {
        const data = {
          chainId: String(chainId),
          address: account,
          commodity: 'ETH',
          commodity_amount: Number(formatEther(price)),
          to: account,
          sign: proofs[claimSignatureIdx],
          index: claimSignatureIdx,
          traits: {
            ...wakeUpValues,
            magnitude: wakeUpValues.magnitude.fracture,
            scientist: Number(wakeUpValues.scientist),
            personality: Number(wakeUpValues.personality),
            vices: Number(wakeUpValues.vices),
          },
        };
        const result = await request(
          `${environment.BE_SERVER_URL}/api/payment/sign/scientist-mint-for`,
          data,
          {
            method: 'POST',
          }
        );
        if (result) {
          clickId.current = result?.data?.click_id;
          openFiatPaymentModal(
            result.data,
            onHandleMintingFailed,
            onHandleMintingSuccess,
            onHandleMintingStart
          );
        } else {
          setErrorMessage('Signed Data Error');
          setShowErrorModal(true);
        }
      } catch (error) {
        const msg = getErrorMessage(error);
        setErrorMessage(msg);
        setShowErrorModal(true);
      }
    } else {
      setErrorMessage('You are not in the whitelist');
      setShowErrorModal(true);
    }
    setIsLockForm(false);
  };

  useEffect(() => {
    if (isEmpty(proofs)) setIsLockForm(true);
  }, [proofs]);

  const stylesAnimationBackground = useSpring({
    ref: wakeUpBgApi,
    to: { opacity: triggerFireWakeUp ? 0.95 : isInAnimating ? 0.7 : 0 },
    from: { opacity: triggerFireWakeUp ? 0.7 : 0 },
    config: { duration: 700 },
  });

  const stylesFlashLight = useSpring({
    ref: flashLightApi,
    to: [{ opacity: triggerFireWakeUp ? 1 : 0 }, { opacity: triggerFireWakeUp ? 0 : 0 }],
    from: { opacity: 0 },
    config: { duration: 50 },
  });

  const stylesOpenEyes = useSpring({
    ref: openEyesApi,
    to: { opacity: triggerFireWakeUp ? 1 : 0 },
    from: { opacity: 0 },
    config: { duration: triggerFireWakeUp ? 1000 : 10 },
  });

  const stylesAnimationWhiteLight = useSpring({
    to: [
      { opacity: isOpenModalMintedScientist ? 1 : 0 },
      { opacity: isOpenModalMintedScientist ? 0 : 0 },
    ],
    from: { opacity: 0 },
    config: { duration: 80 },
  });

  useChain(
    triggerFireWakeUp
      ? [wakeUpBgApi, flashLightApi, openEyesApi, wakeUpCrazyBgApi]
      : [wakeUpCrazyBgApi, openEyesApi, wakeUpBgApi],
    triggerFireWakeUp ? [0, 1.1, 1.2, 2.72] : [0, 0, 0]
  );

  // #endregion
  const information = (
    <FireItUpTypography>
      <FireItUpItem>
        Remaining:
        <div className="value">
          {info.remainingAmount ? `${info.remainingAmount} Scientists` : 'SOLD OUT'}
        </div>
      </FireItUpItem>
      <FireItUpItem>
        Price:
        <div className="value">
          {` ${
            info.price
              ? info.price < 0.0001 && info.price > 0
                ? `${numeral(info.price).format('0e+0')} ETH`
                : info.price === '0.0' || info.price === 0
                ? 'Free'
                : `${info.price} ETH`
              : '0 ETH'
          } `}
        </div>
      </FireItUpItem>
    </FireItUpTypography>
  );

  return (
    <>
      <Waypoint onEnter={() => setIntroView(true)} onLeave={() => setIntroView(false)}>
        <animated.div style={stylesIntro3}>
          <WakeUpScientistWrapper>
            <div className="smokes">
              <animated.img
                style={isSmallDevice ? stylesSmokeSmallScreen : stylesSmokeLeft}
                src="images/SmokePink.webp"
                alt="smoke-pink"
                className="ag-smoke_img ag-smoke_img__left"
              />
              <animated.img
                style={isSmallDevice ? stylesSmokeSmallScreen : stylesSmokeRight}
                src="images/SmokeBlue.webp"
                alt="smoke-blue"
                className="ag-smoke_img ag-smoke_img__right"
              />
            </div>

            <Grid container spacing={0.5} className="wake_up__section" alignItems="center">
              <Grid
                item
                xs={12}
                sm={5}
                md={3}
                key="wakeUpForm"
                container
                direction="column"
                className="wake_up__form">
                <animated.div className="skill_picker__wrapper" style={stylesSkillPicker}>
                  <AwakeScientistButton
                    {...{
                      scientistId,
                      account,
                      inScientistWhiteList,
                      handleSetFormStatus,
                      isMinted,
                      isLockForm,
                      information,
                      isInAnimating,
                    }}
                  />
                  <ScientificField
                    {...{
                      isMinted,
                      isLockForm,
                      wakeUpValues,
                      setWakeUpValues,
                      wakeUpCoverRef,
                      wrapperRef,
                    }}
                  />

                  <FireItUpButton
                    isMinted={isMinted}
                    onHandleFireWakeUp={onOpenPopupOption}
                    account={account}
                    checkFormStatus={checkFormStatus}
                    isInAnimating={isInAnimating}
                  />
                </animated.div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={7}
                md={9}
                key="wakeUpCover"
                container
                direction="column"
                className="wake_up__cover_section">
                <animated.div style={stylesSkillPickerCover} className="wake_up_cover__section">
                  <AwakeNavigateButton afterlogin="afterLogin">
                    <Grid container alignItems="center">
                      <img
                        className="wake_up__button"
                        src="/icons/activate-awake-icon.svg"
                        alt="wake up button"
                      />
                      <span className="wake_up__amount">GENESIS MINT 555 NFTs</span>
                    </Grid>
                    {/* <span className="mad_unit">MAD Metascientists</span> */}
                  </AwakeNavigateButton>
                  <Grid
                    container
                    direction="column"
                    className={`wake_up__cover__section ${
                      isMinted ? 'wake_up__cover__section--minted' : ''
                    }`}>
                    <img
                      src="/images/SimbiotesRockAbyss.webp"
                      alt="Wake Up Scientist Cover"
                      style={{
                        visibility: 'hidden',
                        pointerEvents: 'none',
                        width: isSmallDevice ? '100%' : 'auto',
                        height: isSmallDevice ? 'auto' : '55vh',
                      }}
                    />
                    <ScientistMintAnimation
                      {...{
                        wakeUpCoverRef,
                      }}
                    />

                    <ScientistMintStatus
                      {...{
                        isMinted,
                        mintedScientist,
                        isOpenModalMintedScientist,
                        checkFormStatus,
                        distanceFromWakeUpCoverToEndPage,
                        introView,
                        inScientistWhiteList,
                        scientistId,
                        account,
                        isInAnimating,
                      }}
                    />
                  </Grid>
                </animated.div>
              </Grid>
            </Grid>

            <div className="wake_up_animations__wrapper">
              <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <animated.div className="animate__bg" style={stylesAnimationBackground} />
                <animated.div className="animate_flash_light" style={stylesFlashLight} />
                {triggerFireWakeUp ? (
                  <animated.div className="animate_open_eyes" style={stylesOpenEyes} />
                ) : null}
                <animated.div className="animate_white_light" style={stylesAnimationWhiteLight} />
                {/* )} */}
                {triggerFireWakeUp ? (
                  <LightingWrapper>
                    <div className="ag-lightning" />
                  </LightingWrapper>
                ) : null}
              </div>
            </div>

            <CollectedScientistModal
              {...{
                isOpenModalMintedScientist,
                setIsOpenModalMintedScientist,
                stylesMintedScientistCover,
                handleGetScientist,
              }}
            />
            <MintedScientistModal
              open={showConfirmModal || showErrorModal}
              showConfirmModal={showConfirmModal}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              message={errorMessage}
              mining={false}
              isWert={isWert}
            />
            <MinOptionModal
              open={showMintOptionModal}
              onCloseClick={() => setShowMintOptionModal(false)}
              onHandleFireWakeUp={onHandleFireWakeUp}
              onHandleFireWithWert={onHandleFireWithWert}
              isMintFree={info.price === '0.0' || info.price === 0}
            />
            {/* Absolute Content */}
            <SocialContacts leftalign="leftAlign">
              {socialContacts.map((item) => (
                <Contact key={item.icon}>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <img src={`/icons/${item.icon}`} alt={item.link} className="social__icon" />
                    <img src="/icons/folder-icon.svg" alt={item.link} />
                  </a>
                </Contact>
              ))}
            </SocialContacts>
          </WakeUpScientistWrapper>
        </animated.div>
      </Waypoint>
    </>
  );
};

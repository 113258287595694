import React from 'react';
import { Modal, Grid, Typography, CircularProgress, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';

const GlobalLoadingModal = ({ open, text }) => {
  const location = useLocation();
  const mobile = useMediaQuery('(max-width:440px)');
  return (
    <Modal
      open={open}
      BackdropProps={{
        sx: { background: location.pathname === '/lab' ? 'none' : 'rgba(0,0,0,0.7)' },
      }}
      sx={{
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{
          maxWidth: mobile ? '350px' : '544px',
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(20px)',
          borderRadius: '14px',
          p: '51px 83px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
        }}>
        <CircularProgress thickness={3} size={52} sx={{ color: '#09EBF6' }} />
        <Typography
          variant="modalTitle"
          sx={{
            color: '#09EBF6',
            mt: '33px',
            whiteSpace: 'normal',
            width: '100%',
            textAlign: 'center',
            breakWord: 'break-all',
            overflow: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}>
          {text}
        </Typography>
      </Grid>
    </Modal>
  );
};

export default GlobalLoadingModal;

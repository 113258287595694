const Wallet = ({ width }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width || '24'}
    height={width || '24'}
    viewBox="0 0 64 64"
    preserveAspectRatio="xMidYMid meet">
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="#fefefe"
      stroke="none">
      <path
        d="M132 548 c-18 -18 -15 -36 7 -48 27 -14 341 -14 341 0 0 6 -11 22
-25 35 -23 24 -29 25 -168 25 -98 0 -147 -4 -155 -12z"
      />
      <path
        d="M80 302 c0 -239 -18 -222 241 -222 191 0 196 0 217 23 12 13 22 35
22 50 0 27 0 27 -74 27 -64 0 -79 3 -102 24 -37 31 -38 84 -4 121 21 23 31 25
102 25 l78 0 0 30 c0 17 -9 39 -20 50 -19 19 -33 20 -218 20 -163 0 -201 3
-220 16 l-22 15 0 -179z"
      />
      <path
        d="M400 289 c-9 -16 -8 -26 5 -45 14 -22 22 -24 85 -24 l70 0 0 45 0 45
-75 0 c-65 0 -76 -3 -85 -21z m75 -24 c0 -30 -39 -27 -43 3 -3 20 0 23 20 20
15 -2 23 -10 23 -23z"
      />
    </g>
  </svg>
);

export default Wallet;

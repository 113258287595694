/* eslint-disable no-nested-ternary */
import { Divider, useMediaQuery } from '@mui/material';

export function ScientistDefaultBg() {
  const isSmallDevice = useMediaQuery('( max-width: 1024px )');

  return (
    <>
      <img
        src="/images/SimbiotesRockAbyss.webp"
        alt="Wake Up Scientist Cover"
        style={{
          width: isSmallDevice ? '100%' : '80%',
          height: isSmallDevice ? 'auto' : '55vh',
        }}
      />
      <Divider
        sx={{
          zIndex: 15,

          borderColor: 'rgba(255, 255, 255, 0.24)',
          width: isSmallDevice ? '100%' : '80%',
          position: 'absolute',
          top: '25%',
        }}
        light
      />
      <Divider
        sx={{
          zIndex: 15,

          borderColor: 'rgba(255, 255, 255, 0.24)',
          width: isSmallDevice ? '100%' : '80%',
          position: 'absolute',
          top: '50%',
        }}
        light
      />
      <Divider
        sx={{
          zIndex: 15,

          borderColor: 'rgba(255, 255, 255, 0.24)',
          width: isSmallDevice ? '100%' : '80%',
          position: 'absolute',
          top: '75%',
        }}
        light
      />
      <Divider
        orientation="vertical"
        sx={{
          zIndex: 15,
          borderColor: 'rgba(255, 255, 255, 0.24)',
          height: '100%',
          position: 'absolute',
          left: '20%',
        }}
        light
      />
      <Divider
        orientation="vertical"
        sx={{
          zIndex: 15,
          borderColor: 'rgba(255, 255, 255, 0.24)',
          height: '100%',
          position: 'absolute',
          left: '40%',
        }}
        light
      />
      <Divider
        orientation="vertical"
        sx={{
          zIndex: 15,
          borderColor: 'rgba(255, 255, 255, 0.24)',
          height: '100%',
          position: 'absolute',
          left: '60%',
        }}
        light
      />
      <Divider
        orientation="vertical"
        sx={{
          zIndex: 15,
          borderColor: 'rgba(255, 255, 255, 0.24)',
          height: '100%',
          position: 'absolute',
          left: '80%',
        }}
        light
      />
      <div className="glitch__item" />
      <div className="glitch__item" />
      <div className="glitch__item" />
      <div className="glitch__item" />
      <div className="glitch__item" />
    </>
  );
}

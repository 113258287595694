import { useState, useEffect } from 'react';

const maxDaysToEvolution = Math.floor(3562000 / (60 * 60 * 24));

const useTimer = (timestamp, boostTime = 0) => {
  const [timeToEvolution, setTimeToEvolution] = useState((timestamp - Date.now()) / 1000);

  let seconds = 0;
  let minutes = 0;
  let hours = 0;
  let days = 0;

  if (timeToEvolution > 0) {
    seconds = Math.floor((timeToEvolution - boostTime) % 60);
    minutes = Math.floor(((timeToEvolution - boostTime) / 60) % 60);
    hours = Math.floor(((timeToEvolution - boostTime) / (60 * 60)) % 24);
    days = Math.floor((timeToEvolution - boostTime) / (60 * 60 * 24));
  }

  useEffect(() => {
    let timer;
    if (timeToEvolution > 0) {
      timer = setInterval(() => {
        setTimeToEvolution((t) => {
          if (t - 1 < 1) clearInterval(timer);
          return t - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, []);

  return { seconds, minutes, hours, days, maxDaysToEvolution };
};

export default useTimer;

import { useMediaQuery } from '@mui/material';
import { LightButton } from './styles';

function NeonLightButton({ handleClick = () => {}, text }) {
  const isMbScreen = useMediaQuery('(max-width: 576px)');

  return (
    <LightButton onClick={handleClick}>
      {isMbScreen ? 'CONNECT' : text || 'CLICK TO CONNECT TO MAD METAVERSE'}
    </LightButton>
  );
}

export default NeonLightButton;

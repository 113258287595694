/* eslint-disable prettier/prettier */
import { Select, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomSelectWithStyle = styled(Select)`
  height: 40px;
  background: #54626f;
  border-radius: 20px;
  min-width: 187px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  .MuiSelect-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    &.MuiSelect-iconOpen {
      transform: translateY(-50%) rotate(90deg);
    }
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
    color: #b9b9b9;
  }
  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
  &.MuiPaper-root {
  }
`;

export const CustomMenuWithStyle = styled(Menu)``;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveFormDataMintScientist } from '@providers/ScientistProvider';
import { useMediaQuery } from '@mui/material';
import SelectFormItem from '../SelectForm/SelectFormItem';
import { specsAvailableValues } from '../__mocks/scientistMintMockValues';

import { SelectFormItemsWrapper } from '../styles';

export const ScientificField = ({
  isMinted,
  isLockForm,
  wakeUpValues,
  setWakeUpValues,
  wakeUpCoverRef,
  wrapperRef,
}) => {
  const isMobile = useMediaQuery('(max-width:1024px)');
  const [distanceFromLeftToWakeUpCover, setDistanceFromLeftToWakeUpCover] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const distance = wakeUpCoverRef.current?.getBoundingClientRect().left;
    setDistanceFromLeftToWakeUpCover(distance);
  }, [wakeUpCoverRef]);

  const { scientist, personality, psychological, vices, magnitude } = wakeUpValues;

  const onSelectValue = useCallback(
    (name, value) => {
      if (isMobile) {
        wakeUpCoverRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
      setWakeUpValues((wakeUpValues) => {
        let updatedWakeUpValues;
        if (wakeUpValues[name] === value) {
          updatedWakeUpValues = { ...wakeUpValues, [name]: '' };
        } else {
          updatedWakeUpValues = { ...wakeUpValues, [name]: value };
        }
        return updatedWakeUpValues;
      });

      dispatch(saveFormDataMintScientist({ name, value }));
    },
    [isMobile, wakeUpCoverRef, setWakeUpValues, dispatch]
  );

  const onChangeRangeValue = useCallback(
    (name, rangeName, value) => {
      const { innerWidth: width, innerHeight: height } = window;
      if (width <= 1024) {
        wakeUpCoverRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
      setWakeUpValues((wakeUpValues) => {
        return {
          ...wakeUpValues,
          [name]: {
            ...wakeUpValues[name],
            [rangeName]: value,
          },
        };
      });
      dispatch(saveFormDataMintScientist({ name: `${name}.${rangeName}`, value }));
    },
    [dispatch, setWakeUpValues, wakeUpCoverRef]
  );

  return (
    <>
      <SelectFormItemsWrapper>
        <SelectFormItem
          isMinted={isMinted || isLockForm}
          modalLeftDistance={distanceFromLeftToWakeUpCover}
          label="Scientific Fields"
          name="scientist"
          value={scientist}
          selectableValues={specsAvailableValues.scientist}
          onSelectValue={onSelectValue}
          ref={{ wakeUpCoverRef, wrapperRef }}
        />
        <SelectFormItem
          isMinted={isMinted || isLockForm}
          modalLeftDistance={distanceFromLeftToWakeUpCover}
          label="Personality Disorders"
          name="personality"
          value={personality}
          selectableValues={specsAvailableValues.personality}
          onSelectValue={onSelectValue}
          ref={{ wakeUpCoverRef, wrapperRef }}
        />
        <SelectFormItem
          isMinted={isMinted || isLockForm}
          modalLeftDistance={distanceFromLeftToWakeUpCover}
          label="Psychological Forces"
          name="psychological"
          value={psychological}
          selectableValues={specsAvailableValues.psychological}
          onChangeRangeValue={onChangeRangeValue}
          isSelectRange
          ref={{ wakeUpCoverRef, wrapperRef }}
        />
        <SelectFormItem
          isMinted={isMinted || isLockForm}
          modalLeftDistance={distanceFromLeftToWakeUpCover}
          label="Vices"
          name="vices"
          value={vices}
          selectableValues={specsAvailableValues.vices}
          onSelectValue={onSelectValue}
          ref={{ wakeUpCoverRef, wrapperRef }}
        />
        <SelectFormItem
          isMinted={isMinted || isLockForm}
          modalLeftDistance={distanceFromLeftToWakeUpCover}
          label="Magnitude Of Stress"
          name="magnitude"
          value={magnitude}
          selectableValues={specsAvailableValues.magnitude}
          onChangeRangeValue={onChangeRangeValue}
          isSelectRange
          ref={{ wakeUpCoverRef, wrapperRef }}
        />
      </SelectFormItemsWrapper>
    </>
  );
};

import { marketPlaceWatcher } from '@providers/MarketPlaceProvider/saga';
import { NFTWatcher } from '@providers/NFTProvider/saga';
import { CellModuleWatcher } from '@providers/CellModuleProvider/saga';
import { ScientistWatcher } from '@providers/ScientistProvider/saga';
import { MetaCellWatcher } from '@providers/MetaCellProvider/saga';
import { takeEvery, all } from 'redux-saga/effects';
import { transactionWatcher } from '@providers/Transaction/saga';

export default function* rootSaga() {
  yield all([
    marketPlaceWatcher(),
    NFTWatcher(),
    CellModuleWatcher(),
    ScientistWatcher(),
    MetaCellWatcher(),
    transactionWatcher(),
  ]);
  // code after all-effect
}

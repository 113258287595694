import { MintedScientistModal } from '@components/index';
import { useMintMetacellContext } from './MintMetacellWrapper';

export const MessageNotifyModal = () => {
  const {
    modal: { messageNotify, setMessageNotify },
  } = useMintMetacellContext();

  const setShowErrorModal = (e) => {
    setMessageNotify((o) => ({ ...o, show: e }));
  };

  return (
    <>
      <MintedScientistModal
        open={messageNotify?.show}
        showConfirmModal={messageNotify?.type === 'confirm'}
        showErrorModal={messageNotify?.type === 'error'}
        setShowErrorModal={setShowErrorModal}
        message={messageNotify?.message}
        mining={false}
      />
    </>
  );
};

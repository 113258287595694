import { Modal, Box } from '@mui/material';
import LabError from '@components/Modals/LabModal/LabError';
import LoadingModal from '@components/Modals/LoadingModal';

const MintedScientistModal = ({
  open,
  showConfirmModal,
  showErrorModal,
  setShowErrorModal,
  message,
  mining,
  isWert = false,
}) => {
  return (
    <Modal
      open={open}
      BackdropProps={{ sx: { background: 'rgba(0,0,0,0.7)' } }}
      sx={{
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        outline: 'none',
        '&:focus': {
          outline: 'none',
        },
      }}
      onClose={() => {
        setShowErrorModal(false);
      }}>
      <Box sx={{ outline: 'none' }}>
        {showConfirmModal ? (
          <LoadingModal open={showConfirmModal} text={message} mining={mining} isWert={isWert} />
        ) : showErrorModal ? (
          <LabError setShowErrorModal={setShowErrorModal} message={message} />
        ) : null}
      </Box>
    </Modal>
  );
};

export default MintedScientistModal;

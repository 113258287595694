/* eslint-disable no-console */
import { useEffectOnMount } from '@pages/MetacellMint/useEffectOnMount';
import { useContracts } from '@providers/ContractsProvider';
import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useMetacellStatus = ({ account, chainId, updated }) => {
  const { MetaCell, handleAggregateCall } = useContracts();
  const { proofs = [], metaCellAccountId = 'noAccount' } = useSelector((state) => state.metaCell);

  const [listAccountMetacell, setListAccountMetacell] = useState([]);
  const [maxClaimed, setMaxClaimed] = useState();
  const [claimProofIdx, setClaimProofIdx] = useState(null);
  const [remainingAmount, setRemainingAmount] = useState(null);

  const getOwnerMetacell = useCallback(async () => {
    try {
      if (!MetaCell) return null;
      if (!account) return null;
      const list = await MetaCell.getUserMetaCellsIndexes(account);
      const res = list?.map((o) => Number(o));
      return res;
    } catch (error) {
      return null;
    }
  }, [MetaCell, account]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setListAccountMetacell([]);
        if (!MetaCell) return;
        if (!account) return;
        const list = await MetaCell.getUserMetaCellsIndexes(account);
        const res = list?.map((o) => Number(o));
        setListAccountMetacell(res);
      } catch (error) {
        console.log('[ERROR] useListOwnMetacell.js ~ line 34: ', error);
      }
    };

    loadData();
  }, [MetaCell, account, updated]);

  useEffectOnMount(() => {
    setClaimProofIdx(null);
    return () => {
      setClaimProofIdx(null);
    };
  }, [account, chainId]);

  useEffect(() => {
    const getMaxClaimed = async () => {
      try {
        setMaxClaimed(null);
        if (!MetaCell) return;
        const claimAvailable = await MetaCell.maxClaimed();
        setMaxClaimed(Number(claimAvailable));
      } catch (error) {
        console.log('[ERROR]: useListOwnMetacell.js ~ line 48', error);
      }
    };
    // getMaxClaimed();
  }, [chainId, MetaCell, account]);

  useEffect(() => {
    const handleMultiCallForClaimable = async (signatureList = [], metaCellAccountId) => {
      const proofExpireTuple = signatureList.map((signature, idx) => ({
        target: MetaCell.address,
        callData: MetaCell.interface.encodeFunctionData('claimable', [
          metaCellAccountId,
          signature,
          idx,
        ]),
      }));

      const data = [...(await handleAggregateCall(proofExpireTuple))].map(
        (o) => MetaCell.interface.decodeFunctionResult('claimable', o)[0]
      );

      return data.findIndex((o) => o) + 1;
    };

    const checkClaimable = async () => {
      try {
        if (updated) return;
        setClaimProofIdx(null);
        if (!MetaCell) return;
        if (proofs === []) {
          return;
        }
        if (!proofs) {
          setClaimProofIdx(0);
          return;
        }
        if (metaCellAccountId !== account) return;

        const claimableStatus = await handleMultiCallForClaimable(proofs, metaCellAccountId);

        // const claimableStatus = await MetaCell.claimable(proofs, metaCellAccountId);

        setClaimProofIdx(claimableStatus);
      } catch (error) {
        console.log('[ERROR]: useMetacellStatus.js ~ line 61', error);
      }
    };
    checkClaimable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MetaCell, proofs, updated, account]);
  useEffect(() => {
    const getRemainingAmount = async () => {
      try {
        setRemainingAmount(null);
        if (!MetaCell) return;
        const res = await MetaCell.remainingAmount();
        setRemainingAmount(res);
      } catch (error) {
        console.log('[ERROR]: useMetacellStatus.js ~ line 101', error);
      }
    };
    getRemainingAmount();
  }, [chainId, MetaCell, account]);
  return {
    listAccountMetacell,
    getOwnerMetacell,
    maxClaimed,
    setListAccountMetacell,
    claimProofIdx,
    remainingAmount,
  };
};

/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import { specsAvailableValues } from '@pages/ScientistMint/__mocks/scientistMintMockValues';
import { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';

export const ScientistAnimation = ({ wakeUpCoverRef, fieldName, fieldValue }) => {
  const isSmallDevice = useMediaQuery('( max-width: 1024px )');
  const [ready, setReady] = useState(true);

  const videoUrl = useMemo(() => {
    const [f1, f2] = `${fieldName}`.split('.');
    const video = specsAvailableValues[f1]?.[fieldValue]?.videoUrl;
    if (typeof video === 'string') return video;
    const videos = specsAvailableValues[f1]?.find((o) => o?.name === f2)?.videoUrl?.[fieldValue];
    return videos;
  }, [fieldName, fieldValue]);

  useEffect(() => {
    if (videoUrl) setReady(true);
  }, [videoUrl]);

  return (
    <>
      <Box
        sx={{
          width: isSmallDevice ? '100%' : 'auto',
          height: isSmallDevice ? 'auto' : '65vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {Boolean(fieldName) && (
          <>
            <ReactPlayer
              playing
              onBuffer={() => setReady(true)}
              onBufferEnd={() => {
                setReady(false);
              }}
              className="bg_player"
              url={videoUrl}
              width="100%"
              height="100%"
              playsinline
              style={{ display: ready ? 'none' : 'block' }}
            />
            <Box sx={{ display: !ready ? 'none' : 'flex' }}>
              <CircularProgress />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

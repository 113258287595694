import { createSlice } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

const initialState = {
  list: [],
  paginate: 1,
  isLoading: false,
  total: null,
  isSendingTransaction: false,
};

export const MarketPlaceSlice = createSlice({
  name: 'marketPlace',
  initialState,
  reducers: {
    setPaginate: (state, action) => {
      state.paginate = _get(action, 'payload', 1);
    },
    getList: (state, action) => {
      state.isLoading = true;
      state.list = [];
    },
    getListSuccess: (state, action) => {
      state.list = _get(action, 'payload.data');
      state.total = _get(action, 'payload.total');
      state.isLoading = false;
    },
    getListFailure: (state, action) => {
      state.list = [];
      state.isLoading = false;
    },
    sendTransactionInfo: (state, action) => {
      state.isSendingTransaction = true;
    },
    sendTransactionInfoSuccess: (state, action) => {
      state.isSendingTransaction = false;
    },
    sendTransactionInfoFailed: (state, action) => {
      state.isSendingTransaction = false;
    },
    resetState: (state) => {
      state.list = [];
      state.total = null;
    },
  },
});

export const {
  getList,
  getListSuccess,
  getListFailure,
  resetState,
  sendTransactionInfo,
  sendTransactionInfoSuccess,
  sendTransactionInfoFailed,
  setPaginate,
} = MarketPlaceSlice.actions;

export const selectMarketPlace = (state) => state.marketPlace;

export default MarketPlaceSlice.reducer;

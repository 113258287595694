import { createSlice } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { useSelector } from 'react-redux';

const initialState = {
  proofs: [],
  scientistAccountId: null,
  isLoading: false,
  fieldValue: -1,
  fieldName: null,
  preWertOrders: [],
};

export const ScientistSlice = createSlice({
  name: 'scientist',
  initialState,
  reducers: {
    saveFormDataMintScientist: (state, action) => {
      const current = {
        value: _get(state, 'fieldValue'),
        name: _get(state, 'fieldName'),
      };

      const data = {
        value: _get(action, 'payload.value'),
        name: _get(action, 'payload.name'),
      };

      if (data.value === current.value && data.name === current.name) {
        state.fieldValue = -1;
        state.fieldName = null;
      } else {
        state.fieldValue = _get(action, 'payload.value');
        state.fieldName = _get(action, 'payload.name');
      }
    },
    resetFormField: (state) => {
      state.fieldValue = -1;
      state.fieldName = null;
    },
    getScientistIdProof: (state, _) => {
      state.isLoading = true;
      state.proofs = [];
      state.scientistAccountId = null;
    },
    getScientistIdProofSuccess: (state, action) => {
      const deadlineCountdown = Date.parse(_get(action, 'payload.data.deadline', new Date()));
      const mintingTime = Date.parse(_get(action, 'payload.data.timestamp', new Date()));
      state.deadline = deadlineCountdown;
      state.mintingTime = mintingTime;
      state.proofs = _get(action, 'payload.data.data', []);
      state.scientistAccountId = _get(action, 'payload.data.account', null);
      state.isLoading = false;
    },
    getScientistIdProofFailure: (state) => {
      state.proofs = [];
      state.isLoading = false;
      state.scientistAccountId = null;
    },
    getWertOrders: (state, _) => {
      state.preWertOrders = [];
      state.isLoading = true;
    },
    getWertOrderSuccess: (state, action) => {
      state.preWertOrders = _get(action, 'payload.data');
      state.isLoading = false;
    },
    getWertOrderFailure: (state) => {
      state.preWertOrders = [];
      state.isLoading = false;
    },
    resetState: () => initialState,
  },
});

export const {
  saveFormDataMintScientist,
  getScientistIdProof,
  getScientistIdProofSuccess,
  getScientistIdProofFailure,
  resetState,
  setPaginate,
  resetFormField,
} = ScientistSlice.actions;

export const useSelectScientist = () => useSelector((state) => state.scientist);

export default ScientistSlice.reducer;

import React from 'react';

const Metamask = ({ width }) => (
  <svg
    width={width || '52'}
    height={width || '52'}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.7369 5.7941L28.4971 17.8556L31.5002 10.7395L44.7369 5.7941Z"
      fill="#E2761B"
      stroke="#E2761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.24683 5.7941L23.3561 17.9699L20.4998 10.7395L7.24683 5.7941Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.894 33.7527L34.5688 40.3792L43.8231 42.9253L46.4835 33.8996L38.894 33.7527Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.53296 33.8996L8.17703 42.9253L17.4313 40.3792L13.1061 33.7527L5.53296 33.8996Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9091 22.5562L14.3303 26.457L23.5193 26.865L23.1928 16.9906L16.9091 22.5562Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.0746 22.5562L28.7092 16.8763L28.4971 26.8651L37.6697 26.457L35.0746 22.5562Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4314 40.3791L22.948 37.6861L18.1822 33.9648L17.4314 40.3791Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.0359 37.6861L34.5688 40.3791L33.8017 33.9648L29.0359 37.6861Z"
      fill="#E4761B"
      stroke="#E4761B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.5688 40.3792L29.0359 37.6861L29.4766 41.2932L29.4276 42.811L34.5688 40.3792Z"
      fill="#D7C1B3"
      stroke="#D7C1B3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4314 40.3792L22.5726 42.811L22.54 41.2932L22.948 37.6861L17.4314 40.3792Z"
      fill="#D7C1B3"
      stroke="#D7C1B3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6541 31.5819L18.0515 30.2272L21.2995 28.742L22.6541 31.5819Z"
      fill="#233447"
      stroke="#233447"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3293 31.5819L30.684 28.742L33.9483 30.2272L29.3293 31.5819Z"
      fill="#233447"
      stroke="#233447"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4314 40.3792L18.2148 33.7527L13.1062 33.8996L17.4314 40.3792Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7854 33.7527L34.5688 40.3792L38.894 33.8996L33.7854 33.7527Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.6699 26.457L28.4973 26.865L29.346 31.5819L30.7007 28.742L33.965 30.2272L37.6699 26.457Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0516 30.2272L21.3159 28.742L22.6542 31.5819L23.5193 26.865L14.3303 26.457L18.0516 30.2272Z"
      fill="#CD6116"
      stroke="#CD6116"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3303 26.457L18.1822 33.9648L18.0516 30.2272L14.3303 26.457Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.965 30.2272L33.8018 33.9648L37.6699 26.457L33.965 30.2272Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5193 26.8651L22.6543 31.5819L23.7315 37.1475L23.9763 29.8192L23.5193 26.8651Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.4973 26.8651L28.0566 29.8029L28.2525 37.1475L29.346 31.5819L28.4973 26.8651Z"
      fill="#E4751F"
      stroke="#E4751F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3457 31.5819L28.2522 37.1475L29.0356 37.6861L33.8015 33.9648L33.9647 30.2272L29.3457 31.5819Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0515 30.2272L18.1821 33.9648L22.9479 37.6861L23.7314 37.1475L22.6542 31.5819L18.0515 30.2272Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.4276 42.811L29.4766 41.2932L29.0686 40.9341H22.9154L22.54 41.2932L22.5726 42.811L17.4314 40.3792L19.2268 41.8481L22.8664 44.3779H29.1175L32.7735 41.8481L34.5689 40.3792L29.4276 42.811Z"
      fill="#C0AD9E"
      stroke="#C0AD9E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.0357 37.6861L28.2523 37.1475H23.7313L22.9478 37.6861L22.5398 41.2932L22.9152 40.9341H29.0684L29.4764 41.2932L29.0357 37.6861Z"
      fill="#161616"
      stroke="#161616"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.4223 18.639L46.8096 11.9799L44.7368 5.7941L29.0356 17.4476L35.0746 22.5562L43.6107 25.0534L45.5039 22.85L44.6879 22.2624L45.9936 21.0709L44.9816 20.2875L46.2874 19.2919L45.4223 18.639Z"
      fill="#763D16"
      stroke="#763D16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.19019 11.9799L6.5775 18.639L5.69615 19.2919L7.00186 20.2875L6.00626 21.0709L7.31197 22.2624L6.4959 22.85L8.37286 25.0534L16.909 22.5562L22.9479 17.4476L7.24668 5.7941L5.19019 11.9799Z"
      fill="#763D16"
      stroke="#763D16"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.6107 25.0534L35.0746 22.5562L37.6697 26.457L33.8015 33.9648L38.8938 33.8996H46.4832L43.6107 25.0534Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.909 22.5562L8.37288 25.0534L5.53296 33.8996H13.1061L18.182 33.9648L14.3302 26.457L16.909 22.5562Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.4972 26.865L29.0358 17.4476L31.5167 10.7395H20.4998L22.948 17.4476L23.5192 26.865L23.7151 29.8355L23.7314 37.1475H28.2524L28.2851 29.8355L28.4972 26.865Z"
      fill="#F6851B"
      stroke="#F6851B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Metamask;

import { ScientistAvaIcon } from '@components/Icons';
import { useDebounce } from '@hooks/useDebounce';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Tooltip } from '@mui/material';
import { resetFormField } from '@providers/ScientistProvider';

import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useGetInfoScientist from '@hooks/useGetInfoScientist';

export const AwakeScientistButton = ({
  scientistId,
  account,
  inScientistWhiteList,
  handleSetFormStatus,
  isMinted,
  isLockForm,
  information,
  isInAnimating,
}) => {
  const dispatch = useDispatch();
  const info = useGetInfoScientist(account, isMinted, isInAnimating);

  const onClickButton = useCallback(() => {
    handleSetFormStatus();
    dispatch(resetFormField());
  }, [dispatch, handleSetFormStatus]);

  const isDisableBtn = useDebounce(
    isMinted || !info?.remainingAmount || (account && !inScientistWhiteList),
    500,
    true
  );

  return (
    <Button
      disabled={isDisableBtn}
      onClick={onClickButton}
      sx={{ '@media (max-width: 1024px)': { marginTop: `${isMinted ? '180px' : '0px'}` } }}
      className={`wake_up_form__button__wrapper ${
        isDisableBtn ? 'disabled_activate__button' : ''
      } ${!isLockForm ? 'wake_up_form__button__wrapper_active' : ''}`}>
      <div
        className={`wake_up_form__icon_wrapper ${
          !isLockForm ? 'wake_up_form__icon_wrapper_active' : ''
        }`}>
        <div className="wake_up_form__icon_wrapper_bg">
          <ScientistAvaIcon
            className={`wake_up_form__button ${!isLockForm && 'wake_up_form__button--active'} ${
              isDisableBtn && 'isMinted'
            }`}
            lineColor={!isLockForm ? '#000' : '#fff'}
            glassesColor={!isLockForm ? '#000' : '#fff'}
            hairColor={!isLockForm ? '#000' : '#fff'}
          />
        </div>
      </div>
      <span>Wake Your Metascientist </span>
      <Tooltip title={information} arrow placement="top" disableHoverListener={isInAnimating}>
        <InfoOutlinedIcon fontSize="inherit" sx={{ marginRight: 1 }} className="icon_info" />
      </Tooltip>
      <div className="wake_up_form__icon_item" />
      <div className="wrapper_item_dot">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </Button>
  );
};

export const handleCheckValue = (funcType, values) => {
  const checkResult = values[funcType]((val) => {
    let result = false;
    if (val.constructor.name === 'String') {
      result = parseInt(val) >= 0;
    } else {
      const listSubValues = Object.values(val);
      if (listSubValues.length > 1 && funcType === 'some') {
        result = listSubValues.every((val) => {
          return parseInt(val) >= 0;
        });
      } else {
        result = listSubValues[funcType]((val) => {
          return parseInt(val) >= 0;
        });
      }
    }
    return result;
  });

  return checkResult;
};

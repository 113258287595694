import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { Web3ReactProvider } from '@web3-react/core';
import { Provider as ReduxProvider } from 'react-redux';
import { WertProvider } from '@providers/WertProvider';
import { store as reduxStore } from './store/rootReducer';
import Routes from './routes/Routes';

import { getLibrary } from './utils';
import { ContractsProvider } from './providers';
import { Store } from './store/index';
import './index.css';

require('dotenv').config();

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <ContractsProvider>
        <WertProvider>
          <ReduxProvider store={reduxStore}>
            <Store>
              <Routes />
              <Toaster />
            </Store>
          </ReduxProvider>
        </WertProvider>
      </ContractsProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const HeaderWrapper = styled('div')`
  width: auto;
  margin: 0 4%;
  padding-top: 1.35%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .w-40 {
    width: 40%;
  }
  .left-menu {
    width: 35%;
    justify-content: space-between;
  }
  .right-menu {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(4px);
    padding: 0px 20px;
    border-radius: 10px;
  }
`;

export const ColWrapper = styled('div')`
  display: flex;
`;

export const Logo = styled('img')`
  margin-right: 14px;
`;

export const LogoContain = styled('div')`
  width: 316px;
  img {
    width: 100%;
  }
`;

export const LogoWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 5%;
  cursor: pointer;
`;

export const ChooseCoinWrapper = styled('div')`
  display: flex;
`;

export const TextMenuStyled = styled('p')`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => (p.color ? '#fff' : '#ccc')};
`;

export const IconSwitchWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin: 0 15px;
  .theme-switch {
    display: inline-block;
    height: 15px;
    position: relative;
    width: 27px;
  }

  .theme-switch input {
    display: none;
  }

  .slider {
    background-color: transparent;
    border: #b9b9b9 0.5px solid;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
  }

  .slider:before {
    background-color: #09ebf6;
    bottom: 2px;
    content: '';
    height: 9px;
    left: 2px;
    position: absolute;
    transition: 0.4s;
    width: 9px;
  }

  input:checked + .slider {
  }

  input:checked + .slider:before {
    transform: translateX(10px);
  }

  .slider.round {
    border-radius: 18px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
export const ListMenuWrapper = styled('div')`
  justify-content: space-between;
  display: ${(p) => (p.istablet ? 'block' : 'flex')};
  width: 100%;
  .wallet__button {
    padding: 0;
    margin-right: 18px;
    min-width: unset;
    background-color: unset;
    box-shadow: none;
    &:hover {
      background-color: unset;
      box-shadow: none;
    }
    &:focus {
      background-color: unset;
    }
  }
  .popup {
    &__wrapper {
      position: absolute;
      width: 180px;
      top: 30px;
      right: 0;
      left: -75px;
      z-index: 1;
      padding: 1px;
      background-color: rgba(255, 255, 255, 0.04);
      pointer-events: auto;
      color: #fff;
      @media (max-width: 1024px) {
        left: -5px;
      }
    }
    &__button {
      width: 100%;
      color: #fff;
      border-radius: 0;
      &:hover {
        background-color: rgba(255, 255, 255, 0.06);
      }
      .MuiButton-startIcon {
        margin-right: 0;
        margin-left: 0;
      }
      &:nth-of-type(1) {
        justify-content: space-evenly;
      }
    }
  }
`;

export const MenuText = styled('p')`
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
  white-space: nowrap;
`;

export const MenuItemWrapper = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0 8px;
  cursor: pointer;
  color: ${(p) => (p.pathactive === 'true' ? '#fff' : '#b9b9b9')};
  text-decoration: none;
  img {
    width: 20px;
  }
  :hover {
    filter: brightness(1.75);
  }
`;

export const IconUser = styled(Link)`
  height: 22px;
`;

export const IconMenu = styled('img')`
  ${(p) => (p.pathActive === 'true' ? 'filter: brightness(1.75)' : '')};
  :hover {
    filter: brightness(1.75);
  }
`;

export const HeaderMobileStyled = styled('div')`
  background: #0d1216eb;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  height: 100vh;
  padding: 4rem 20px;
  transform: translateX(${(p) => (p.show ? '100%' : '0%')});
  transition: 0.6s ease;
  .menu-item {
    :hover {
      background: #ccc;
    }
  }
`;

export const IconClose = styled('img')`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
`;

export const LogoMobile = styled('div')`
  text-align: center;
`;

export const HeaderBar = styled('div')`
  height: 54px;
  .open-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
  }
`;

export const HeaderMobileWrapper = styled('div')`
  overflow: hidden;
`;

export const ConsoleIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const IconAdmin = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
`;

export const IconWallet = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  min-width: unset;
`;

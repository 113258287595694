import { environment } from '@environment/index';
import { openSwitchNetworkModal } from '@providers/ModalsProvider';
import { resetState } from '@providers/ScientistProvider';
import { setWalletName } from '@providers/WalletProvider';
import { ITEM_NAME_STORAGE } from '@shared';
import { ConnectorNames, connectorsByName, resetWalletConnector } from '@utils/connectors';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

export const defaultConnectedWalletStatus = {
  Injected: false,
  WalletConnect: false,
  WalletLink: false,
  Bitkeep: false,
};

const WalletProps = {
  [ConnectorNames.Injected]: 'isMetaMask',
  [ConnectorNames.WalletLink]: 'isCoinbaseWallet',
  [ConnectorNames.Bitkeep]: 'isBitKeep',
};

function useConnectors(handleSetErrorMessage = () => {}, setOpenModal = () => {}) {
  const connectError = useRef(null);
  const [activatingConnector, setActivatingConnector] = useState();
  // const [providerList, setProviderList] = useState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { providerList } = window;
  const dispatch = useDispatch();
  const { deactivate, activate, connector } = useWeb3React();
  const noMetamaskInstalled = useMemo(() => {
    if (!window?.ethereum) return true;
    if (!providerList)
      return Boolean(
        typeof window?.ethereum !== 'undefined' &&
          !window.web3?.currentProvider[WalletProps[ConnectorNames.Injected]] &&
          !window.ethereum[WalletProps[ConnectorNames.Injected]]
      );

    return ![...providerList].find((o) => o?.[WalletProps[ConnectorNames.Injected]]);
  }, [providerList]);

  const isBitKeepInstalled = window.isBitKeep && window.bitkeep.ethereum;

  useEffect(() => {
    if (providerList) return;
    if (!window?.ethereum) return;
    let list = [];
    const { providers } = window?.ethereum;

    if (providers) {
      const coinbase = providers.find((pro) => pro[WalletProps[ConnectorNames.WalletLink]]);
      const bitkeep = providers.find((pro) => pro[WalletProps[ConnectorNames.Bitkeep]]);
      if (bitkeep) delete bitkeep[WalletProps[ConnectorNames.Injected]];
      const metamask = providers.find(
        (pro) =>
          pro[WalletProps[ConnectorNames.Injected]] && !pro[WalletProps[ConnectorNames.Bitkeep]]
      );

      list = [metamask, bitkeep, coinbase];
    } else {
      const { bitkeep } = window;
      if (bitkeep) {
        const bitkeepWallet = bitkeep.ethereum;
        delete bitkeepWallet[WalletProps[ConnectorNames.Injected]];
        list = [bitkeep?.otherEthereum || window?.ethereum, bitkeepWallet];
      } else {
        list = [window?.ethereum];
      }
    }

    // setProviderList(list);
    window.providerList = list;
  }, [providerList]);

  const setCurrentProvider = (walletName) => {
    if (window.ethereum?.providers && window.ethereum.providerMap.get(walletName)) {
      window.ethereum.setSelectedProvider(window.ethereum.providerMap.get(walletName));
    }
  };

  const getEthereumProvider = useCallback(
    (wallet) => {
      try {
        if (!window?.ethereum || isEmpty(providerList)) return null;

        const listProvider = [...providerList];

        if (listProvider?.length) {
          const result = listProvider?.find((o) => o?.[wallet]);

          return result;
        }
        return null;
      } catch (error) {
        console.log('Error Connect Wallet', error);
        return null;
      }
    },
    [providerList]
  );

  const disconnect = async () => {
    try {
      await deactivate();
      localStorage.setItem(
        ITEM_NAME_STORAGE.connectedWalletStatus,
        JSON.stringify(defaultConnectedWalletStatus)
      );
      dispatch(resetState());
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log(`${ex?.message}`);
    }
  };

  const connectMetamaskHandler = async () => {
    let errorMsg = '';
    try {
      // const walletProvider = getEthereumProvider(WalletProps[ConnectorNames.Injected]);
      // if (walletProvider) window.ethereum = walletProvider;
      await onConnectWallet(connectorsByName.Injected, 'Injected');
    } catch (ex) {
      if (ex instanceof UnsupportedChainIdError) {
        errorMsg = `You are connected to the wrong network.\nPlease connect to network with ID ${environment.NETWORK_ID}`;
      } else {
        errorMsg = 'Unknown error while connecting wallet';
      }
      handleSetErrorMessage(errorMsg);
    }
  };
  const connectBitkeepHandler = async () => {
    let errorMsg = '';
    try {
      // const walletProvider = getEthereumProvider(WalletProps[ConnectorNames.Bitkeep]);

      // if (walletProvider) window.ethereum = walletProvider;
      await onConnectWallet(connectorsByName.Bitkeep, 'Bitkeep');
    } catch (ex) {
      if (ex instanceof UnsupportedChainIdError) {
        errorMsg = `You are connected to the wrong network.\nPlease connect to network with ID ${environment.NETWORK_ID}`;
      } else {
        errorMsg = 'Unknown error while connecting wallet';
      }
      handleSetErrorMessage(errorMsg);
    }
  };
  const onConnectMetamaskWallet = () => {
    return connectMetamaskHandler();
  };
  const onConnectBitkeep = async () => {
    if (!isBitKeepInstalled) {
      window.open('https://bitkeep.com/download');
    } else {
      connectBitkeepHandler();
    }
  };

  const onConnectWallet = async (connector, connectorName) => {
    if (connectorName === ConnectorNames.Injected) {
      if (noMetamaskInstalled) {
        window.open('https://metamask.io/');
        return;
      }
    }
    if (connectorName === ConnectorNames.Bitkeep) {
      if (!isBitKeepInstalled) {
        window.open('https://bitkeep.com/download');
        return;
      }
    }

    if ([ConnectorNames.Injected, ConnectorNames.Bitkeep].includes(connectorName)) {
      const walletProvider = getEthereumProvider(WalletProps[connectorName]);

      setCurrentProvider(connectorName);

      if (walletProvider) window.ethereum = walletProvider;
      else return;
    }
    dispatch(setWalletName(connectorName));
    await activate(
      connector,
      async (error) => {
        localStorage.setItem(ITEM_NAME_STORAGE.isLoadingConnect, undefined);

        if (error instanceof UnsupportedChainIdError) {
          dispatch(openSwitchNetworkModal({ connector, connectorName }));
          disconnect();
        } else if (error.message === 'accounts received is empty')
          handleSetErrorMessage('The user rejected the request.');
        else {
          handleSetErrorMessage(error.message);
        }
        connectError.current = true;
      },
      false
    );
    if (!connectError.current) {
      const connectedWalletStatus = {
        ...defaultConnectedWalletStatus,
      };
      connectedWalletStatus[connectorName] = true;
      localStorage.setItem(
        ITEM_NAME_STORAGE.connectedWalletStatus,
        JSON.stringify(connectedWalletStatus)
      );
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    if (connector !== connectorsByName.WalletConnect) {
      resetWalletConnector(connectorsByName.WalletConnect);
    }
  }, [connector]);

  return {
    onConnectMetamaskWallet,
    onConnectBitkeep,
    disconnect,
    noMetamaskInstalled,
    activatingConnector,
    onConnectWallet,
    providerList,
  };
}

export default useConnectors;

import { CircularProgress, Grid } from '@mui/material';

export const LoadingFullScreen = () => {
  return (
    <Grid
      container
      item
      lg={12}
      sx={{
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <CircularProgress thickness={3} size={52} sx={{ color: '#09EBF6' }} />
    </Grid>
  );
};

export const LoadingFullScreenCompound = ({ isLoading, children }) => {
  return isLoading ? <LoadingFullScreen /> : children;
};

/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import BoostController from '@components/BoostController';
import { MaxUint256 } from '@ethersproject/constants';
import { useWrapDetect } from '@hooks';
import { useGetLabStateContract } from '@hooks/contracts/useLabContract';
import { Box, Grid, LinearProgress, Typography, useMediaQuery } from '@mui/material';
import { keyframes } from '@mui/system';
import { getListNFTs, resetState } from '@providers/NFTProvider';
import { asyncTransactionHash as sendTransactionInfo } from '@providers/Transaction';
import { formatMetacellData, formatNanocellData } from '@utils';
import { MARKET_PLACE_TYPE_EVENT } from '@utils/constants';
import transformNFT from '@utils/transformNFT';
import { useCountdown } from '@utils/useCountdown';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { get } from 'lodash';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import {
  AltTabBoard,
  CellDetailsModal,
  ConfirmButton,
  LabBoostTimer,
  LabCard,
  LabModal,
  LabOutcomeCard,
  LabSlot,
  LabTab,
  PetriDish,
} from '../../components';
import { environment } from '../../environment';
import { useContracts } from '../../providers';
import { useStore } from '../../store';
import { LabContainerStyled } from './styles';

const appear = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

const slideIn = keyframes`
    from {
      transform: translateX(-50%);
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

const slideInAndFade = keyframes`
  50% {
    transform: translate(-50%, -70px);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -70px);
    opacity: 0;
  }
`;

const LabFeature = {
  EVOLVE: 'Evolve Cell',
  MUTATE: 'Mutate Cell',
  BOOST: 'Boost Cell',
};

const LabSlotText = {
  evolveCellSlot: 'evolveCellSlot',
  evolveEnhancerSlot: 'evolveEnhancerSlot',
  mergeCellSlot1: 'mergeCellSlot1',
  mergeCellSlot2: 'mergeCellSlot2',
  boostCellSlot: 'boostCellSlot',
};

// const labTabs = [LabFeature.EVOLVE, 'Mutate Cell',  LabFeature.BOOST];
const labTabs = [LabFeature.EVOLVE];
const nftListFilters = ['All NFT', 'Metacells ', 'External NFT', 'Enhancers', 'All'];

const tokensPositionsReducer = (state, { id, list, index }) => ({
  ...state,
  [id]: { ...state[id], [list]: index },
});

const Lab = () => {
  const {
    usersMetacells,
    usersEnhancers,
    boostPerBlockPrice,
    usersBalance,

    methodContract: { updateUsersAssets },
  } = useStore();
  const { PolygonMarketplace, Laboratory, NanoCell, MDMA } = useContracts();
  const { library, account, chainId } = useWeb3React();

  const dispatch = useDispatch();

  const [activeLabTab, setActiveLabTab] = useState(labTabs[0]);
  const [activeLabButton, setActiveLabButton] = useState(labTabs[0].slice(0, -5));
  const [activeFilter, setActiveFilter] = useState(nftListFilters[0]);
  const [allNFT, setAllNFT] = useState([]);
  const [cellNFT, setCellNFT] = useState([]);
  const [externalNFT, setExternalNFT] = useState([]);
  const [enhancers, setEnhancers] = useState([]);
  const [evolveCellSlot, setEvolveCellSlot] = useState([]);
  const [evolveEnhancerSlot, setEvolveEnhancerSlot] = useState([]);
  const [mergeCellSlot1, setMergeCellSlot1] = useState([]);
  const [mergeCellSlot2, setMergeCellSlot2] = useState([]);
  const [boostCellSlot, setBoostCellSlot] = useState([]);
  const [tokensPositions, setTokensPositions] = useReducer(tokensPositionsReducer, {});
  const [cellSlotDisabled, setCellSlotDisabled] = useState(false);
  const [enhancerSlotDisabled, setEnhancerSlotDisabled] = useState(false);
  const [externalNFTSlotDisabled, setExternalNFTSlotDisabled] = useState(false);
  const [isDropDisabled, setIsDropDisabled] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [boostPrice, setBoostPrice] = useState('');
  const [maxBoostPrice, setMaxBoostPrice] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [currentToken, setCurrentToken] = useState();
  const [outcome, setOutcome] = useState();
  const [split, setSplit] = useState();
  const [showSplit, setShowSplit] = useState(false);
  const [message, setMessage] = useState();
  const [isSafeNumber, setIsSafeNumber] = useState(true);
  const [mining, setMining] = useState(false);
  const [labEventName, setLabEventName] = useState('Minted');

  const removeSvg = useMediaQuery('(max-width:555px)');

  const ref = useRef(null);
  const isWraped = useWrapDetect(ref, ref, 660);
  const mainRef = useRef(null);
  const isMainWraped = useWrapDetect(mainRef, mainRef, 1320);
  const { list: listNFT = [], isLoading, total } = useSelector((state) => state.NFT);
  const { walletText } = useSelector((state) => state.wallet);

  useGetLabStateContract();

  const [days, hours, minutes, seconds] = useCountdown(evolveCellSlot[0]?.evolutionTimestamp);

  const cantEvolve = useMemo(() => {
    const renderNextEvolutionTime = () => {
      if (evolveCellSlot[0]?.isMaxEvolution) return 'MAX EVOLUTION';
      return !days && !hours && !minutes && !seconds
        ? '(00 : 00 : 00 : 00)'
        : `(${days > 0 ? (days < 10 ? `0${days} ` : `${days} `) : '00 '}:${
            hours > 0 ? (hours < 10 ? ` 0${hours} ` : ` ${hours} `) : ' 00 '
          }: ${minutes > 0 ? (minutes < 10 ? `0${minutes}` : ` ${minutes} `) : ' 00 '}:${
            seconds > 0 ? (seconds < 10 ? ` 0${seconds}` : ` ${seconds}`) : ' 00'
          })`;
    };

    return [
      evolveCellSlot[0]?.evolutionTimestamp - Date.now(),
      days,
      hours,
      minutes,
      seconds,
    ].filter((o) => o < 0).length === 0 ? (
      <>
        {`Can't evolve! There is some time left!`}
        <br />
        {renderNextEvolutionTime()}
      </>
    ) : evolveCellSlot[0]?.stage === 100 ? (
      "Can't evolve! Max level reached!"
    ) : (
      false
    );
  }, [days, evolveCellSlot, hours, minutes, seconds]);

  const cantMerge =
    !mergeCellSlot1.length && !mergeCellSlot2.length
      ? "Can't merge! Must have a cell and a external NFT!"
      : false;

  useEffect(() => {
    document.title = 'Mad Metaverse - 🔬 The Lab';
  }, []);

  const cantBoost =
    boostCellSlot[0]?.evolutionTimestamp - Date.now() <= 0
      ? "Can't boost! Cell can already evolve!"
      : false;

  const boostTime = boostPrice
    ? ethers.utils.parseEther(boostPrice).div(boostPerBlockPrice).mul(13).toString()
    : 0;

  useEffect(() => {
    dispatch(getListNFTs({ page: 1, isUsed: false, sort: 'createdAt' }));
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      !evolveCellSlot.length &&
      !evolveEnhancerSlot.length &&
      !mergeCellSlot1.length &&
      !mergeCellSlot2.length &&
      !boostCellSlot.length
    ) {
      const usersMetacellsNotOnSale = usersMetacells.filter((cell) => !cell.onSale);

      const reducedEnhancers = [];
      usersEnhancers.forEach((enhancer) => {
        // eslint-disable-next-line no-plusplus
        for (let i = enhancer.amount; i > 0; i--) {
          const enhancerCopy = { ...enhancer };
          enhancerCopy.nanoId = `${enhancerCopy.nanoId}-${i}`;
          reducedEnhancers.push(enhancerCopy);
        }
      });

      setAllNFT(usersMetacellsNotOnSale.concat([]));
      setCellNFT(usersMetacellsNotOnSale);
      setEnhancers(reducedEnhancers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersMetacells, usersEnhancers]);

  useEffect(() => {
    if (listNFT.length) {
      const transformedNFTs = listNFT.map((token) => transformNFT(token));
      setExternalNFT(transformedNFTs);
    }
  }, [listNFT]);

  const listsIds = useMemo(() => {
    return {
      'All NFT': [...cellNFT, ...externalNFT].filter((cell) => cell?.id !== outcome?.cell?.id),
      'Metacells ': cellNFT.filter((cell) => cell?.id !== outcome?.cell?.id),
      'External NFT': externalNFT,
      Enhancers: enhancers,
      All: [...cellNFT, ...enhancers, ...externalNFT],
    };
  }, [cellNFT, enhancers, externalNFT, outcome?.cell?.id]);

  const listsSetters = {
    'All NFT': setAllNFT,
    'Metacells ': setCellNFT,
    'External NFT': setExternalNFT,
    Enhancers: setEnhancers,
  };

  const slotsIds = useMemo(
    () => ({
      evolveCellSlot,
      evolveEnhancerSlot,
      mergeCellSlot1,
      mergeCellSlot2,
      boostCellSlot,
    }),
    [boostCellSlot, evolveCellSlot, evolveEnhancerSlot, mergeCellSlot1, mergeCellSlot2]
  );

  const reorder = (listId, sourceIndex, destinationIndex) => {
    const listClone = listsIds[listId].slice();
    const [dragged] = listClone.splice(sourceIndex, 1);
    listClone.splice(destinationIndex, 0, dragged);

    setCellNFT(listClone);
  };

  const move = (sourceId, sourceIndex, destinationId, destinationIndex) => {
    if (Object.keys(LabSlotText).includes(sourceId)) {
      const sourceClone = slotsIds[sourceId].slice();
      const destinationClone = listsIds[destinationId].slice();
      const [dragged] = sourceClone.splice(sourceIndex, 1);
      destinationClone.splice(destinationIndex, 0, dragged);

      setEvolveCellSlot([]);
      setBoostCellSlot([]);
      if (destinationClone[0]?.type.toLowerCase() === 'cell') {
        setCellNFT(destinationClone);
      }
    } else if (Object.keys(LabSlotText).includes(destinationId)) {
      const sourceClone = listsIds[sourceId].slice();
      const destinationClone = slotsIds[destinationId].slice();
      const [dragged] = sourceClone.splice(sourceIndex, 1);
      destinationClone.splice(destinationIndex, 0, dragged);

      if (destinationClone[0]) {
        if (destinationClone[0]?.type.toLowerCase() === 'cell') {
          setCellNFT(sourceClone);
        }
        switch (destinationId) {
          case LabSlotText.evolveCellSlot:
            setEvolveCellSlot(destinationClone);
            break;

          default:
            setBoostCellSlot(destinationClone);
            break;
        }
      }
    }

    // listsSetters[activeFilter](!sourceId.includes('Slot') ? sourceClone : destinationClone);
    // slotsSetter[destinationId]
    //   ? slotsSetter[destinationId](destinationClone)
    //   : slotsSetter[sourceId](sourceClone);
  };

  const swap = (sourceId, sourceIndex, destinationId, destinationIndex) => {
    const sourceClone = listsIds[sourceId].slice();
    const destinationClone = slotsIds[destinationId].slice();
    const [dragged] = sourceClone.splice(sourceIndex, 1, slotsIds[destinationId][0]);
    destinationClone.splice(0, 1, dragged);

    setCellNFT(sourceClone);

    switch (destinationId) {
      case LabSlotText.evolveCellSlot:
        setEvolveCellSlot(destinationClone);
        break;

      default:
        setBoostCellSlot(destinationClone);
        break;
    }
  };

  const onDragStart = (result) => {
    setCellSlotDisabled(
      result.draggableId.includes('enhancer') || result.draggableId.includes('external')
    );
    setEnhancerSlotDisabled(
      result.draggableId.includes('cell') || result.draggableId.includes('external')
    );
    setExternalNFTSlotDisabled(
      result.draggableId.includes('cell') || result.draggableId.includes('enhancer')
    );

    let disabled;

    switch (activeFilter) {
      case 'All NFT':
        disabled = result.draggableId.includes('enhancer');
        break;
      case 'Metacells ':
        disabled =
          result.draggableId.includes('enhancer') || result.draggableId.includes('external');
        break;
      case 'External NFT':
        disabled = result.draggableId.includes('enhancer') || result.draggableId.includes('cell');
        break;
      case 'Enhancers':
        disabled = result.draggableId.includes('external') || result.draggableId.includes('cell');
        break;
      default:
        disabled = false;
    }
    setIsDropDisabled(disabled);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;

    if (destination.droppableId === source.droppableId) {
      if (destination.index === source.index) return;
      reorder(source.droppableId, source.index, destination.index);
    } else if (listsIds[source.droppableId]) {
      for (const listId in listsIds) {
        const index = listsIds[listId].findIndex((token) => draggableId.includes(token.nanoId));

        if (index > -1) {
          setTokensPositions({ id: draggableId, list: listId, index });
          if (listId !== source.droppableId) {
            listsIds[listId].splice(index, 1);
          }
        }
      }
      if (slotsIds[destination.droppableId].length) {
        const tokenPositions =
          tokensPositions[
            `${slotsIds[destination.droppableId][0].type}-${
              slotsIds[destination.droppableId][0].nanoId
            }`
          ];
        for (const listId in tokenPositions) {
          if (listId !== activeFilter) {
            const insertIndex =
              tokenPositions[listId] > source.index
                ? tokenPositions[listId] - 1
                : tokenPositions[listId];
            listsIds[listId].splice(insertIndex, 0, slotsIds[destination.droppableId][0]);
          }
        }
        swap(source.droppableId, source.index, destination.droppableId, destination.index);
      } else {
        move(source.droppableId, source.index, destination.droppableId, destination.index);
      }
    } else if (slotsIds[source.droppableId]) {
      for (const listId in tokensPositions[draggableId]) {
        if (listId !== destination.droppableId) {
          listsIds[listId].splice(
            tokensPositions[draggableId][listId],
            0,
            slotsIds[source.droppableId][0]
          );
        }
      }
      move(source.droppableId, source.index, destination.droppableId, destination.index);
    }
  };

  const labButtonHandler = async () => {
    setErrorMessage(`Please confirm transaction in your ${walletText}`);
    setShowConfirmModal(true);
    try {
      let tx;

      if (activeLabTab === LabFeature.EVOLVE) {
        const gas = await Laboratory.estimateGas.evolve(
          evolveCellSlot[0].id,
          evolveEnhancerSlot[0] ? evolveEnhancerSlot[0].id : 0,
          0
        );

        tx = await Laboratory.evolve(
          evolveCellSlot[0].id,
          evolveEnhancerSlot[0] ? evolveEnhancerSlot[0].id : 0,
          0,
          { gasLimit: Math.round(1.5 * gas.toNumber()) }
        );
      }

      if (activeLabTab === 'Mutate Cell') {
        tx = await Laboratory.mutate(mergeCellSlot1[0].id, mergeCellSlot2[0].id);
      }

      if (activeLabTab === LabFeature.BOOST) {
        const allowance = await MDMA.allowance(account, environment.LABORATORY_ADDRESS);
        const boostPriceUpdated = isSafeNumber
          ? ethers.utils.parseUnits(boostPrice)
          : ethers.utils.parseEther(boostPrice);
        if (!allowance.gt(boostPriceUpdated)) {
          setErrorMessage(`Please approve token use in your ${walletText}`);
          const atx = await MDMA.approve(environment.LABORATORY_ADDRESS, MaxUint256);
          setMining(true);
          setErrorMessage('Waiting for transaction to be mined');
          await atx.wait();
        }
        setMining(false);
        setErrorMessage(`Please confirm transaction in your ${walletText}`);
        tx = await Laboratory.boostCell(boostCellSlot[0].id, boostPriceUpdated);
      }
      setShowLoadingModal(true);
      setShowConfirmModal(false);
      await tx.wait(1);
      if (activeLabTab === LabFeature.EVOLVE) {
        if (tx?.hash) {
          dispatch(
            sendTransactionInfo({
              transactionHash: tx?.hash,
              event: MARKET_PLACE_TYPE_EVENT.lab.evolve,
              contract: MARKET_PLACE_TYPE_EVENT.lab.contract,
              chainId: chainId.toString(),
            })
          );
        }
      }
      setEvolveCellSlot([]);
      setEvolveEnhancerSlot([]);
      setMergeCellSlot1([]);
      setMergeCellSlot2([]);
      setBoostCellSlot([]);
    } catch (error) {
      if (error.code !== 4001) {
        console.log('error', error.error);
        if (error?.error?.code === 3) {
          setErrorMessage(error.error.message);
        } else if (error.code === -32603) {
          setErrorMessage('User rejected the transaction');
        } else {
          setErrorMessage(
            error?.data?.message
              ? error?.data?.message.match(/reason string '(.*?)'$/)[1]
              : error.message
          );
        }
      } else {
        setErrorMessage(error.message);
      }
      setShowErrorModal(true);
      setShowLoadingModal(false);
    } finally {
      setShowConfirmModal(false);
      setShowLoadingModal(false);
    }
  };

  const dragClickHandler = (token, index, activeFilter) => {
    onDragEnd({
      destination: {
        droppableId:
          activeLabTab === LabFeature.EVOLVE
            ? 'evolveCellSlot'
            : activeLabTab === 'Mutate Cell'
            ? 'mergeCellSlot1'
            : 'boostCellSlot',
        index: 0,
      },
      source: {
        index,
        droppableId: activeFilter,
      },
      draggableId: `${token.type}-${token.nanoId}`,
    });
  };

  const cellClickHandler = (droppableId, slot, activeFilter) => {
    onDragEnd({
      destination: {
        droppableId: activeFilter,
        index: 0,
      },
      source: {
        index: 0,
        droppableId,
      },
      draggableId: `${slot[0]?.type}-${slot[0]?.nanoId}`,
    });
  };

  const detailsButtonHandler = (token) => {
    setCurrentToken({
      ...token,
      image: `${process.env.REACT_APP_GIF_URL}/MetaCell_${numeral(Number(token?.stage) + 1).format(
        '000'
      )}.gif`,
    });
    setShowDetails(true);
  };

  const formatOutcome = useCallback(
    async (...args) => {
      setLabEventName(args[0]);
      if (args[0] !== 'Minted') {
        setShowSplit(false);
        const { blockNumber } = library;

        const formattedMetacell = await formatMetacellData(args[1], blockNumber);

        setShowLoadingModal(false);
        setOutcome({ event: args[0], cell: formattedMetacell });
        setMessage(args[0] === 'Evolve' ? 'Evolved!' : 'Boosted!');
      }
    },
    [library]
  );

  const formatSplitNanoCell = async (...args) => {
    const userAddress = `${get(args, ['0'], '')}`.toLowerCase();
    if (userAddress !== account.toLowerCase()) return;
    const nanoCell = await formatNanocellData(args[1], PolygonMarketplace, NanoCell);
    setSplit(nanoCell);
  };
  const formatSplitMDMAToken = async (...args) => {
    const userAddress = `${get(args, ['0'], '')}`.toLowerCase();
    if (userAddress !== account.toLowerCase()) return;
    setSplit(ethers.utils.formatEther(args[1]));
  };

  useEffect(() => {
    if (account) {
      setOutcome(null);
      setShowSplit(false);
      setSplit(null);

      setEvolveCellSlot([]);
      setMergeCellSlot1([]);
      setBoostCellSlot([]);
    }
  }, [account]);
  useEffect(() => {
    if (labEventName === 'Evolve' && split) {
      setShowSplit(true);
      setMessage('Splitted!');
    }
  }, [labEventName, split]);

  const renderSplit = () => {
    if (showSplit && split?.id) {
      return (
        <Grid
          container
          gap="13px"
          sx={{
            mt: '30px',
            p: '14px',
            background: '#17181C',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: '#09EBF6',
            borderRadius: '14px',
            width: '100%',
            maxWidth: '279px',
            flexWrap: 'nowrap',
            cursor: 'pointer',
            opacity: 0,
            animation: `${slideIn} 1s forwards`,
          }}
          onClick={(e) => {
            if (e.target.id.includes('details')) {
              detailsButtonHandler(split);
            } else {
              setShowSplit(false);
              setSplit(null);
            }
          }}>
          <Box sx={{ width: '74px', height: '74px' }}>
            <img
              src={split?.image || '/images/Blue Nanocell.png'}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                borderRadius: '14px',
              }}
              alt="split"
            />
          </Box>
          <Grid container direction="column" sx={{ width: 'auto', flexGrow: 1 }}>
            <Grid container justifyContent="space-between" sx={{ overflow: 'hidden' }}>
              <Grid container alignItems="center" gap="8px" sx={{ width: 'auto' }}>
                <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
                  Token ID
                </Typography>
                <Typography
                  variant="tokenId"
                  sx={{
                    background: 'rgba(255, 255, 255, 0.08)',
                    borderRadius: '4px',
                    height: '19px',
                    p: '0 4px',
                  }}>
                  {`# ${split.id}`}
                </Typography>
              </Grid>
              <svg
                style={{ cursor: 'pointer' }}
                id="details"
                width="15"
                height="20"
                viewBox="0 0 9 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="1.5" cy="1.5" r="1.5" fill="#B9B9B9" />
                <circle cx="1.5" cy="6.5" r="1.5" fill="#B9B9B9" />
                <circle cx="1.5" cy="11.5" r="1.5" fill="#B9B9B9" />
                <circle cx="7.5" cy="1.5" r="1.5" fill="#B9B9B9" />
                <circle cx="7.5" cy="6.5" r="1.5" fill="#B9B9B9" />
                <circle cx="7.5" cy="11.5" r="1.5" fill="#B9B9B9" />
              </svg>
            </Grid>
            <Typography
              variant="cardName"
              sx={{ height: '19px', mt: '3px', justifyContent: 'flex-start' }}>
              {split.name}
            </Typography>
            <Box sx={{ width: '100%', mt: '12px' }}>
              <Grid container gap="4px" sx={{ width: 'auto' }}>
                <Typography variant="cardAttribute" sx={{ opacity: 0.4, height: '14px' }}>
                  Level:
                </Typography>
                <Typography variant="cardAttribute">1</Typography>
              </Grid>
              <LinearProgress
                variant="determinate"
                value={1}
                sx={{
                  borderRadius: '7px',
                  backgroundColor: '#242424',
                  mt: '3px',
                  '.MuiLinearProgress-bar': {
                    background:
                      'linear-gradient(90deg, #00ABE2 0%, #45A0EA 12%, #9493F3 26%, #CE89F9 38%, #F183FD 47%, #FF81FF 51%, #F982FE 56%, #E785FC 62%, #CA8AF9 69%, #A190F4 77%, #6D99EE 85%, #2FA3E7 94%, #00ABE2 99%)',
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      );
    }
    if (showSplit && split) {
      return (
        <Typography
          variant="ERC20forLab"
          sx={{
            mt: '70px',
            zIndex: 1,
            opacity: 0,
            p: '10px 15px',
            background: '#AB6EFF',
            borderRadius: '14px',
            cursor: 'pointer',
            animation: `${slideIn} 1s forwards`,
          }}
          onClick={() => {
            setShowSplit(false);
            setSplit(null);
          }}>
          {`+ ${split}`}
          <img
            src="/icons/mdma-token-icon.svg"
            alt="mdmaIcon"
            width={23}
            height={23}
            className="mad_token__icon"
          />
        </Typography>
      );
    }
    return null;
  };

  useEffect(() => {
    if (Laboratory) {
      Laboratory.on('NewEvolutionCompleted', (...e) => {
        const userAddress = `${get(e, ['1', '1'], '')}`.toLowerCase();
        if (userAddress !== account.toLowerCase()) return;
        setEvolveCellSlot([]);
        updateUsersAssets();
        formatOutcome(...e);
      });
      // Laboratory.on('EvolutionTimeReduced', (...e) => {
      //   const userAddress = `${get(e, ['1', '1'], '')}`.toLowerCase();
      //   if (userAddress !== account.toLowerCase()) return;
      //   updateUsersAssets();
      //   formatOutcome(...e);
      // });
      // Laboratory.on('SplitNanoCell', formatSplitNanoCell);
      // Laboratory.on('SplitBiometaToken', formatSplitMDMAToken);
      // Laboratory.on('NewMetaCellCreated', formatSplit);
    }
    return () => {
      if (Laboratory) Laboratory.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Laboratory]);

  return (
    <LabContainerStyled>
      <Typography className="the-lab__title" variant="pageTitle" component="h1">
        The Lab
      </Typography>
      <Grid container justifyContent="center" alignItems="center" className="the-lab__tabs">
        {labTabs.map((tab, index) => (
          <LabTab
            key={`labTab[${tab}]`}
            tabName={tab}
            active={tab === activeLabTab}
            setActiveLabTab={setActiveLabTab}
            setActiveLabButton={setActiveLabButton}
          />
        ))}
      </Grid>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Grid className="the-lab__dnd-container" ref={mainRef} container gap="20px">
          <Grid
            container
            direction="column"
            alignItems="center"
            className={`the-lab__dnd-tabs ${isWraped && !isMainWraped && 'wraped'}`}>
            <AltTabBoard
              tabs={nftListFilters}
              activeTab={activeFilter}
              setActiveTab={setActiveFilter}
              sx={{ m: '25px 20px 0', zIndex: 1 }}
            />
            <Box className="the-lab__dnd-wrapper">
              {listsIds[activeFilter]?.length > 0 ? (
                <Droppable droppableId={activeFilter} isDropDisabled={isDropDisabled}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="the-lab__dnd-card-list">
                      {listsIds[activeFilter].map((token, index) => (
                        <LabCard
                          key={`${activeFilter}-cell-${token.nanoId}`}
                          index={index}
                          token={token}
                          detailsButtonHandler={detailsButtonHandler}
                          dragClickHandler={() => {
                            dragClickHandler(token, index, activeFilter);
                          }}
                        />
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              ) : (
                <Typography
                  variant="cardName"
                  sx={{
                    height: '19px',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%,-50%)',
                    justifyContent: 'center',
                    alightItem: 'center',
                    color: 'rgba(255, 255, 255, 0.2)',
                  }}>
                  {`There are no ${activeFilter} here yet!`}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid ref={ref} container justifyContent="center" className="the-lab__dnd-main">
            <Grid
              container
              justifyContent="center"
              className={`dnd-dropping__container ${isWraped && 'warped'}`}>
              {removeSvg ? null : (
                <img
                  className="dnd-dropping__bracket dnd-dropping__bracket-left"
                  src="\icons\lab-bracket-left.svg"
                  alt="lab-stick"
                />
              )}
              <Grid
                container
                direction="column"
                alignItems="center"
                className="dnd-dropping__first-slot">
                {(activeLabTab === LabFeature.EVOLVE && evolveCellSlot.length && cantEvolve) ||
                (activeLabTab === 'Mutate Cell' &&
                  mergeCellSlot1.length &&
                  mergeCellSlot2.length &&
                  cantMerge) ||
                (activeLabTab === LabFeature.BOOST && cantBoost) ? (
                  <Typography
                    variant="rx18"
                    className="dnd-dropping__warning"
                    sx={{
                      animation: `${appear} 0.3s`,
                      wordBreak: 'break-word',
                      display: 'block',
                      whiteSpace: 'none',
                    }}>
                    {activeLabTab === LabFeature.EVOLVE
                      ? cantEvolve
                      : activeLabTab === 'Mutate Cell'
                      ? cantMerge
                      : cantBoost}
                  </Typography>
                ) : null}
                <LabSlot
                  droppableId={
                    activeLabTab === LabFeature.EVOLVE
                      ? 'evolveCellSlot'
                      : activeLabTab === 'Mutate Cell'
                      ? 'mergeCellSlot1'
                      : 'boostCellSlot'
                  }
                  isDropDisabled={cellSlotDisabled}
                  slot={
                    activeLabTab === LabFeature.EVOLVE
                      ? evolveCellSlot
                      : activeLabTab === 'Mutate Cell'
                      ? mergeCellSlot1
                      : boostCellSlot
                  }
                  activeFilter={activeFilter}
                  detailsButtonHandler={detailsButtonHandler}
                  cellClickHandler={cellClickHandler}
                />
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  gap="19px"
                  sx={{
                    order: activeLabTab !== LabFeature.BOOST ? '0' : '-1',
                    mt: activeLabTab !== LabFeature.BOOST ? null : '70px',
                  }}>
                  {activeLabTab === LabFeature.BOOST ? (
                    <LabBoostTimer
                      isSlotFilled={boostCellSlot.length}
                      cantBoost={cantBoost}
                      timestamp={boostCellSlot[0]?.evolutionTimestamp}
                    />
                  ) : null}
                  <PetriDish
                    slot1={
                      activeLabTab === LabFeature.EVOLVE
                        ? evolveCellSlot.length
                        : activeLabTab === 'Mutate Cell'
                        ? mergeCellSlot1.length
                        : boostCellSlot.length
                    }
                    slot2={
                      activeLabTab === LabFeature.EVOLVE
                        ? evolveEnhancerSlot.length
                        : activeLabTab === 'Mutate Cell'
                        ? mergeCellSlot2.length
                        : null
                    }
                    setIsButtonDisabled={setIsButtonDisabled}
                    cantEvolve={cantEvolve}
                    cantMerge={cantMerge}
                    cantBoost={cantBoost}
                    activeLabTab={activeLabTab}
                    boostPrice={boostPrice}
                    usersBalance={usersBalance}
                    isMax={
                      boostPrice && maxBoostPrice
                        ? isSafeNumber
                          ? ethers.utils
                              .parseUnits(boostPrice)
                              .gte(ethers.utils.parseUnits(maxBoostPrice.toString(), 'wei'))
                          : boostPrice >= maxBoostPrice
                        : false
                    }
                  />
                  {activeLabTab === LabFeature.BOOST ? (
                    <LabBoostTimer
                      isSlotFilled={boostCellSlot.length}
                      cantBoost={cantBoost}
                      timestamp={boostCellSlot[0]?.evolutionTimestamp}
                      boostTime={boostTime}
                    />
                  ) : null}
                </Grid>
                {activeLabTab !== LabFeature.BOOST ? (
                  <LabSlot
                    droppableId={
                      activeLabTab === LabFeature.EVOLVE ? 'evolveEnhancerSlot' : 'mergeCellSlot2'
                    }
                    isDropDisabled={
                      activeLabTab === LabFeature.EVOLVE
                        ? enhancerSlotDisabled
                        : activeLabTab === 'Mutate Cell'
                        ? externalNFTSlotDisabled
                        : cellSlotDisabled
                    }
                    slot={activeLabTab === LabFeature.EVOLVE ? evolveEnhancerSlot : mergeCellSlot2}
                    detailsButtonHandler={detailsButtonHandler}
                  />
                ) : (
                  <BoostController
                    boostPrice={boostPrice}
                    setBoostPrice={setBoostPrice}
                    maxBoostPrice={maxBoostPrice}
                    setMaxBoostPrice={setMaxBoostPrice}
                    boostPerBlockPrice={boostPerBlockPrice}
                    usersBalance={usersBalance}
                    disabled={!boostCellSlot.length || Boolean(cantBoost)}
                    timestamp={boostCellSlot[0]?.evolutionTimestamp}
                    isSafeNumber={isSafeNumber}
                    setIsSafeNumber={setIsSafeNumber}
                  />
                )}
                <ConfirmButton
                  confirmButtonHandler={labButtonHandler}
                  text={activeLabButton}
                  disabled={isButtonDisabled}
                  sx={{
                    width: '140px',
                    height: '36px',
                    mb: isWraped ? '20px' : '30px',
                  }}
                />
              </Grid>
              {removeSvg ? null : (
                <img
                  className="dnd-dropping__bracket dnd-dropping__bracket-right"
                  src="/icons/lab-bracket-right.svg"
                  alt="lab-stick"
                />
              )}
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              sx={{
                flex: '0 1 373px',
                minHeight: '650px',
              }}>
              <Box sx={{ position: 'relative', width: '279px', height: '354px', mt: '102px' }}>
                {message ? (
                  <Typography
                    key={message}
                    variant="cardPrice"
                    sx={{
                      color: '#23D453',
                      position: 'absolute',
                      left: '50%',
                      top: '20px',
                      opacity: 0,
                      transform: 'translateX(-50%)',
                      animation: `${slideInAndFade} 1.5s 1.5s forwards`,
                    }}>
                    {message}
                  </Typography>
                ) : null}
                {outcome ? (
                  <LabOutcomeCard
                    key={outcome.cell.nanoId}
                    cell={outcome.cell}
                    detailsButtonHandler={detailsButtonHandler}
                    setOutcome={setOutcome}
                  />
                ) : (
                  <Typography
                    variant="emptyMessage"
                    sx={{
                      mt: '182px',
                      opacity: 0.4,
                      height: '20px',
                    }}>
                    Outcome cell will appear here
                  </Typography>
                )}
              </Box>
              {renderSplit()}
            </Grid>
          </Grid>
        </Grid>
      </DragDropContext>
      <LabModal
        open={showConfirmModal || showLoadingModal || showErrorModal}
        showConfirmModal={showConfirmModal}
        showLoadingModal={showLoadingModal}
        showErrorModal={showErrorModal}
        setShowErrorModal={setShowErrorModal}
        message={errorMessage}
        activeLabTab={activeLabTab}
        mining={mining}
      />
      {showDetails ? (
        <CellDetailsModal
          open
          detailsButtonHandler={detailsButtonHandler}
          handleClose={() => setShowDetails(false)}
          cell={currentToken}
        />
      ) : null}
    </LabContainerStyled>
  );
};

export default Lab;

export const getErrorMessage = (
  error,
  customMsg = 'Something went wrong. Please go back and try again'
) => {
  switch (error?.code || error?.statusCode) {
    case 'INSUFFICIENT_FUNDS':
      return 'You don’t have enough token in balance';
    case 'INVALID_ARGUMENT':
    case 4001:
    case -32603:
      return 'User denied transaction signature';
    case 400:
      return 'Signed Data Error';
    default:
      return customMsg;
  }
};
